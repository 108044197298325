/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
const isLang = {
  student: 'nemandi',
  id_check_alert: 'Viðskiptavinur: {{customer}}. Vinsamlegast athugaðu eftirfarandi auðkenni viðskiptavina: {{id}}.',
  id_check: 'Verifica dell\'identità',
  cart: 'Karfa',
  item: 'Vara',
  items: 'Vörur',
  subtotal: 'Millisamtala',
  discount: 'Afsláttur',
  total: 'Samtals',
  pay: 'Borga',
  card: 'Kort',
  cash: 'Reiðufé',
  invoice: 'Reikningur',
  giftcard: 'Gjafakort',
  seqr: 'SEQR',
  bitpay: 'Bitpay',
  payment_method: 'Greiðslumáti',
  cash_received: 'Upphæð móttekin',
  or_enter_amount: 'Eða sláðu inn upphæð',
  payment_succeeded: 'Greiðsla tókst',
  change_due: 'Til baka',
  total_paid: 'Heildargreiðsla',
  send_receipt: 'Senda kvittun',
  send_invoice: 'Senda reikning',
  send: 'Senda',
  delete: 'Eyða',
  new_sale: 'Ný sala',
  custom_price: 'Sérsniðið verð',
  item_description: 'Vörulýsing',
  products: 'Vörur',
  transactions: 'Viðskipti',
  reports: 'Yfirlit',
  report: 'Yfirlit',
  settings: 'Stillingar',
  sign_out: 'Útskráning',
  receipt: 'Kvittun',
  refund: 'Endurgreiða ',
  home: 'Heim',
  clear: 'Hreinsa',
  add: 'Bæta',
  sign_up: 'Stofna aðgang',
  sign_up_email: 'Stofna aðgang með netfangi',
  sign_in: 'Innskráning',
  company_name: 'Nafn fyrirtækis',
  merchant_name: 'Tengiliður',
  email_address: 'Netfang',
  phone_number: 'Símanúmer',
  password: 'Lykilorð',
  confirm_password: 'Staðfesta lykilorð',
  forgot_password_question: 'Gleymt lykilorð?',
  next: 'Áfram',
  demo: 'Kynning',
  skip: 'Sleppa',
  skip_registration: 'Sleppa skráningu',
  take_a_tour: 'Skoðunarferð',
  items_returned: 'Vörum skilað',
  wrong_billing: 'Röng innheimta',
  order_cancelled: 'Pöntun aflýst',
  other: 'Annað',
  enter_valid_amount: 'Sláðu inn gilda upphæð',
  invalid_merchant_name: 'Tengiliður ógildur / auður ',
  invalid_business_name: 'Fyrirtækjanafn ógilt / autt',
  invalid_email_address: 'Netfang ógilt / autt',
  invalid_phone_number: 'Símanúmer ógilt / autt',
  invalid_password: 'Lykilorð ógilt / autt',
  password_mismatch: 'Lykilorð misræmi',
  coming_soon: 'Væntanlegt...',
  to_use_signup: 'Vinsamlegast stofnaðu reikning til að nota þennan möguleika',
  login_error: 'Villa í innskráningu',
  signup_error: 'Villa í stofnun aðgangs',
  transaction_history_empty: 'Viðskiptayfirlit er autt',
  categories: 'Flokkar',
  all_products: 'Allar vörur',
  search_product: 'Leita að vöru',
  reissue_receipt: 'Endurútgefa kvittun',
  print_receipt: 'Prenta kvittun',
  or_send_receipt: 'Eða sendu kvittun',
  save: 'Vista',
  saved: 'Vistað',
  search: 'Leita',
  custom_item: 'Sérsniðin vara',
  submit: 'Skila inn',
  submitted: 'Skilað inn',
  note: 'Miði',
  message: 'Skilaboð',
  amount: 'Upphæð',
  number: 'Fjöldi',
  taxes: 'Skattar',
  tax: 'Skattur',
  enter_valid_number: 'Vinsamlegast sláðu inn gilt númer',
  enter_valid_text: 'Vinsamlegast sláðu inn gildan texta',
  enter_valid_phone: 'Vinsamlegast sláðu inn gilt símanúmer',
  enter_valid_email: 'Vinsamlegast sláðu inn gilt netfang',
  feature_not_in_demo: 'Þessi virkni er ekki í boði í kynningarumhverfi',
  provide_refund_reason: 'Þú verður að velja ástæðu endurgreiðslu',
  provide_valid_refund_amount: 'Þú verður að gefa upp gilda endurgreiðsluupphæð',
  refund_exceed_refundable: 'Upphæð endurgreiðslu hærri en hámarksendurgreiðsla fyrir þessa kvittun',
  refund_saved: 'Endurgreiðsla vistuð',
  or_customize_discount: 'Eða sérsniðinn afsláttur',
  enter_percentage: 'Sláðu inn prósentu',
  manual_card_payment: 'Sérsniðin kortagreiðsla',
  did_card_payment_succeed: 'Heppnaðist kortagreiðsla?',
  manual_card_refund: 'Handvirkt endurgreiðsla á korti',
  did_card_refund_succeed: 'Tókst endurgreiðsla korta?',
  giftcard_refund: 'Gjafakort endurgreiðsla',
  did_giftcard_refund_succeed: 'Nýtt gjafakort gefið út til viðskiptavinar?',
  yes: 'Já ',
  no: 'Nei',
  general: 'Almennt',
  printer: 'Prentari ',
  barcode_scanner: 'Strikamerkjaskanni',
  payment: 'Greiðsla ',
  profile: 'Prófíl',
  currency: 'Gjaldmiðill',
  printing_receipt: 'Prentun kvittunar í gangi...',
  enter_amount: 'Sláðu inn upphæð',
  expected_change: 'Væntanlegar breytingar',
  still_remaining: 'Enn eftir',
  issue_invoice: 'Gefa út reikning',
  URL_pricing: 'http://www.countrhq.com/#pricing',
  address: 'Heimilisfang',
  address_continued: 'Heimilisfang (framh.)',
  city: 'Staður',
  zip: 'Póstnúmer',
  country: 'Land',
  country_iso: 'Land á ISO 3166-2 sniði (t.d. "nl", "de", "gb")',
  invalid_address: 'Heimilisfang Ógílt / Autt ',
  invalid_city: 'Staður Ógildur / Auður',
  invalid_country: 'Land Ógilt / Autt',
  invalid_ecommerce: 'Enginn netviðskiptavettvangur (eCommerce platform) valinn',
  signup_error_contact_support: 'Villa í stofnun aðgangs, vinsamlegast hafðu samband við support@countrhq.comð',
  open_orders: 'Opnar pantanir',
  order: 'Pöntun',
  enter_weight_g: 'Sláðu inn þyngd (g)',
  search_scanner: 'Leita að skanna Socket CHS',
  order_settings: 'Pöntun stillingar',
  order_amount: 'Fjöldi opinna reikninga',
  receipts: 'Kvittanir',
  print_receipts: 'Prenta sjálfkrafa kvittun',
  open_cash_drawer: 'Opna sjálfkrafa peningaskúffu',
  backup: 'Öryggisafrit',
  email_backup: 'Öryggisafrit með tölvupósti',
  server_sync: 'Samstilla við vefþjón',
  external_payment: 'External',
  search_printer: 'Leita að prentara',
  sms_successfully_sent: 'Sending smákilaboðs tókst',
  error_sending_sms: 'Villa í sendingu smáskilaboðs, reyndu aftur síðað',
  email_successfully_sent: 'Sending tölvupósts tókst',
  error_sending_email: 'Villa í sendingu tölvupósts, reyndu aftur síðað',
  transaction: 'Viðskipti',
  logging_in: 'Innskráning í gangi...',
  downloading_products: 'Niðurhala vörum...',
  add_to_cart: 'Bæta í körfu',
  invalid_price: 'Ógilt Verð',
  invalid_discount: 'Ógildur Afsláttur',
  invalid_new_price: 'Ógilt nýtt verð - verður að vera lægra en upphaflegt verð',
  invalid_amount: 'Ógild Upphæð',
  edit_price: 'Breyta Verði',
  discount_perc: 'Afsláttur (%)',
  quantity: 'Magn ',
  remove: 'Fjarlægja ',
  sales_info: 'Söluupplýsingar',
  summary: 'Samantekt',
  refunded_information: 'Upplýsingar endurgreiðslu',
  total_sale: 'Heildarsala',
  total_amount_refunded: 'Heildarupphæð endurgreidd',
  remaining: 'Eftirstöðvar',
  sold_by: 'Selt af ',
  source_receipt_number: 'Upprunalegt númer kvittunar',
  issued_date: 'Dagsetning útgáfu',
  issued_time: 'Tími útgáfu ',
  refunded_by: 'Endurgreitt af',
  reason: 'Ástæða',
  receipt_number: 'Númer kvittunar',
  refunded_date: 'Dagsetning endurgreiðslu',
  refunded_time: 'Tími endurgreiðslu',
  first_name: 'Fyrsta nafn',
  last_name: 'Eftirnafn',
  shop_category: 'Verslunarflokkur',
  choose_country: 'Veldu land ...',
  you_will_receive_email: 'Til hamingju, skráning þín er lokið.',
  wait_email: 'Þú færð nú tölvupóst með frekari leiðbeiningum um hvernig tengja má netpóstinn við Countr POS.',
  open_dashboard: 'Þú getur nú stjórnað reikningnum þínum og búið til vörur í persónulegu mælaborðinu þínu.',
  by_signing_up: 'Med því að stofna aðgang samþykki ég',
  terms_and_conditions: 'Skilmálar og skilyrði',
  and: 'og',
  privacy_policy: 'Persónuverndarstefna',
  refund_items: 'Endurgreiddar vörur',
  forgot_password: 'Gleymt lykilorð?',
  register_device: 'Skráðu tæki',
  device_not_registered: 'Þetta tæki er ekki skráð',
  want_to_add_to_account: 'Skráðu það núna?',
  if_ios_previously_registered: 'Ef þú hefur nýlega sett upp Countr aftur þarftu að skrá tækið aftur.',
  new_device_info: 'Þú verður beðinn um það á næsta skjá að tengja tækið þitt við eina af verslunum þínum',
  not_now: 'Ekki núna',
  proceed: 'Haltu áfram',
  more_info_click: 'Meiri upplýsingar? Ýttu hér',
  your_detail: 'Upplýsingar þínar',
  vat_no: 'VSK númer',
  billing_details: 'Upplýsingar um innheimtu',
  add_store: 'Bæta við verslun',
  or_add_to_existing_store: 'Eða bæta við núverandi verslun',
  add_new_store: 'Bættu við nýrri verslun',
  store_information: 'Geymdu upplýsingar',
  store_name: 'Heiti verslunar',
  go_to_web_dashboard: 'Farðu í stjórnborðið á vefnum',
  error_create_account: 'Villa við stofnun reiknings',
  error_creation_error: 'Villa kom upp við skráningu.',
  error_wrong_phone_number: 'Vinsamlegast sláðu inn gilt símanúmer.',
  error_try_again: 'Tengingarvandamál. Vinsamlegast reyndu aftur',
  error_from_the_server: 'Ekki er hægt að koma á tengingu við netþjóninn',
  error_update_cc_info: 'Get ekki uppfært kreditkortaupplýsingar',
  error_add_device: 'Ekki er hægt að bæta tæki við núverandi verslun',
  error_add_store: 'Get ekki bætt við nýrri verslun',
  are_not_valid: 'eru ekki gild',
  is_not_valid: 'er ekki gild',
  are_empty: 'eru tóm',
  is_empty: 'er tómur',
  are_not_selected: 'eru ekki valdir',
  is_not_selected: 'er ekki valinn',
  bill: 'Bill',
  URL_dashboard: 'https://backoffice.countrhq.com/',
  URL_support: 'mailto:support@countrhq.com',
  URL_forgot_password: 'https://backoffice.countrhq.com/#/auth/forgot',
  close: 'Loka',
  no_product_found: 'Engar vörur fundnar',
  please_go_to_dashboard: 'Vinsamlegast heimsæktu https://backoffice.countrhq.com',
  select_interface: 'Veldu tengi',
  refund_all: 'Endurgreiða allt',
  purchased_items: 'Keyptar vörur',
  refundable: 'Endurgreiðanlegt',
  to_be_refunded: 'Til endurgreiðslu',
  out_of: 'af',
  enter_a_printer_name: 'Sláðu inn nafn prentara',
  cart_is_empty: 'Karfan er tóm',
  searching_printers: 'Leitar að prenturum ...',
  no_printers_found: 'Engir prentarar fundust. Ef þú ert að leita að Bluetooth prentara skaltu ganga úr skugga um að kveikt sé á Bluetooth í stillingum tækisins',
  total_due: 'Heildarverð',
  payment_not_successful: 'Greiðsla tókst ekki.',
  error_processing_payment: 'Villa við vinnslu greiðslu.',
  purchase: 'Kaup',
  transaction_aborted: 'Viðskiptum var hætt',
  authorization_error: 'Heimildarvilla',
  authorization_ok: 'Heimild í lagi',
  transaction_cancelled: 'Hætt við viðskipti',
  clearance_error: 'Úthreinsunarvilla eftir örgjörva',
  clearance_ok: 'Úthreinsun staðfest í lagi með vinnsluaðila',
  transaction_declined: 'Viðskipti hafnað',
  transaction_complete: 'Viðskiptum lokið',
  initiating: 'Hefja',
  application_selected: 'Umsókn valin',
  scheduled_for_clearance: 'Áætlað fyrir úthreinsun án nettengingar með örgjörva',
  terminal_error: 'Skekkjavilla',
  unknown_status: 'Óþekkt staða',
  waiting_for_clearance: 'Bíð eftir staðfestingu utan vinnslumarka af vinnsluaðila',
  amount_exceeded: 'Hámarksfjárhæð yfir',
  authorization_timeout: 'Tímamörk heimildar',
  batch_processing_failed: 'Lotuvinnsla mistókst',
  cancelled: 'Hætt við',
  card_blocked: 'Korti lokað',
  card_invalid: 'Kort ógilt',
  card_declined: 'Korti hafnað',
  transaction_failed: 'Færsla mistókst',
  insufficient_funds: 'Ófullnægjandi fjármunir',
  terminal_not_paired: 'Flugstöð ekki parað - reyndu að endurræsa flugstöðina',
  pin_incorrect_last_retry: 'PIN rangt - lokatilraun',
  pin_incorrect_retry: 'PIN rangt - reyndu aftur',
  printer_busy: 'Prentari upptekinn',
  printer_error: 'Prentvilla',
  printer_not_present: 'Prentari ekki til staðar',
  printer_out_of_paper: 'Prentari úr pappír',
  success: 'Árangur',
  system_error: 'Kerfisvilla',
  transaction_not_found: 'Færsla fannst ekki',
  unknown_device: 'Óþekkt tæki',
  apply_discount: 'Sækja um afslátt',
  seqr_id: 'SEQR auðkenni kaupmanns',
  seqr_password: 'SEQR lykilorð söluaðila',
  bitpay_api_token: 'Bitpay API auðkenni',
  registration_successful: 'Vel tókst að skrá flugstöðina',
  unregistration_successful: 'Tókst afskráning flugstöðvarinnar',
  payment_init_not_successful: 'Greiðsla ekki frumstillt - athugaðu nettengingu.',
  always_show_addons: 'Sýndu alltaf viðbót',
  show_product_image: 'Sýna vörumynd',
  settings_connected_printers: 'Tengdir prentarar',
  settings_add_new_printer: 'Bættu við nýjum prentara',
  wifi_lan: 'WiFi eða LAN',
  bluetooth: 'blátönn',
  usb: 'USB',
  paired_barcode_scanners: 'Pöruð strikamerkjaskannar',
  account: 'Reikningur',
  refresh_products: 'Hressa vörur',
  server_synchronisation: 'Samstilling netþjóna',
  sales: 'Sala',
  today: 'Í dag',
  refunds: 'Endurgreiðslur',
  product: 'Vara',
  product_by_category: 'Vörur eftir flokkum',
  see_categories_details: 'Sjá flokksupplýsingar',
  open_orders_finish_payment_or_empty: 'Það eru opnar kerrur. Ljúktu við greiðsluna eða tæma vagninn / körurnar.',
  name: 'Nafn',
  loading_products: 'Hleður vörur',
  you_should_select_at_least_one_item: 'Þú verður að velja að minnsta kosti einn hlut',
  select_card_provider: 'Veldu einn kortaaðila',
  user_cancelled: 'Greiðsla felld niður af viðskiptavini',
  card_payment: 'Greiðsla með korti',
  manage_products: 'Hafa umsjón með vörum',
  edit_mode: 'Breyta háttur',
  lock: 'læsa',
  loyalty: 'Hollusta',
  account_card: 'Reikningskort',
  account_lookup: 'Athugun á reikningi',
  Activate: 'Virkja',
  add_anonymous_customer: 'Bæta við nafnlausum viðskiptavini',
  add_customer: 'Bæta við viðskiptavini',
  add_customer_to_cart: 'Veldu viðskiptavin',
  add_new_account: 'Bættu við nýjum reikningi',
  add_new_customer: 'Bættu við nýjum viðskiptavini',
  add_note_hint: 'Bæta við athugasemd',
  add_tax_to_price: 'Verð er án skatts',
  add_tip: 'Bættu við þóknun',
  add_to_store: 'Bæta við verslun',
  addons: 'Viðbót',
  admin_pin: 'PIN stjórnanda',
  admin_pin_saved: 'Nýtt PIN PIN stjórnanda vistað',
  admin_pin_should_be: 'PIN-númer stjórnanda ætti að vera tala á milli 4 og 6 tölustafir.',
  aid: 'AIDS',
  aidCard: 'aidCard',
  allow_cart_discounts: 'Leyfa afslætti á körfu',
  allow_custom_cart_discounts: 'Leyfa afslætti á körfu',
  allow_custom_items: 'Leyfa sérsniðna hluti',
  allow_product_discounts: 'Leyfa afslátt afurða',
  allow_custom_product_discounts: 'Leyfa afslátt afurða',
  amount_hint: '999',
  app_version: 'Útgáfa',
  applicationLabel: 'applicationLabel',
  applicationName: 'applicationName',
  applicationPreferredName: 'applicationPreferredName',
  appName: 'appName',
  ask_tips: 'Virkaðu þóknanir',
  authorizationCode: 'heimildarkóða',
  available_points: 'Laus stig',
  available_rewards: 'Verðlaun í boði',
  back: 'Aftur',
  barcode_scanner_disconnected: 'Strikamerkið þitt er tengt en það gæti verið slökkt á því. Reyndu að kveikja á því.',
  barcode_scanner_not_found: 'Enginn strikamerkjaskanni fannst',
  barcode_scanner_not_found_desc: 'Tengdu nýjan strikamerkjaskanna - kveiktu á Bluetooth í stillingum tækisins og leitaðu að skanni hér að neðan',
  bunq: 'bunq',
  bunq_admin_approval: 'Bunq stjórnandi mun fara yfir og samþykkja sjóðvélina þína áður en hægt er að nota hana.',
  bunq_instructions: '1) Opnaðu bunq forritið og sláðu inn PIN-númer (ef þess er krafist) \n2) Veldu \'Skanna QR kóða\' eða \'QR\' af aðalskjánum \n3) Skannaðu skjánúmerið \n4) Bættu við þóknun ef þess er óskað \n5) Staðfestu greiðslu',
  can_only_refund_entire_amount: 'Get aðeins endurgreitt alla kvittunarupphæðina í gegnum {{payment_method}}',
  cancel: 'Hætta við',
  cancel_payment: 'Hætta við greiðslu',
  cannot_pay_later_more_than_remaining_value: 'Þú getur ekki greitt meira en eftirstöðvarnar ({{upphæð}}) með aðferðagreiðslum seinna.',
  cannot_refund_transaction_with_partial_postponed_payments: 'Því miður, þessi viðskipti innihalda eina eða fleiri frestaðar greiðslur, borgaðu viðskiptin fyrst eða endurgreiddu allt',
  cant_find_products: 'Finnurðu ekki allar vörur þínar í POS? Notaðu leitarreitinn hér að ofan eða strikamerkjaskannann til að finna þá.',
  card_scheme: 'Kortakerfi',
  cardBrand: 'kortVörumerki',
  cardExpiryMonth: 'Fyrningartími mánaðar MM',
  cardExpiryYear: 'Gildistími korts YY',
  cardType: 'cardType',
  cart_is_frozen_finish_payment: 'Vagninn er frosinn. Ljúktu við greiðsluna áður en þú gerir einhverjar aðgerðir.',
  cash_difference: 'Mismunur',
  cash_drawer: 'Sjóðskúffa',
  cash_in: 'Handbært fé',
  cash_in_out: 'Reiðufé inn / út',
  cash_out: 'Reiðufé',
  change: 'Breyting',
  change_currency: 'Skipta um gjaldmiðil',
  change_given: 'breyting gefin',
  change_open_orders_amount: 'Skiptu um opna körfuupphæð',
  check_location_settings: 'Athugaðu staðsetningarstillingar',
  check_network: 'Athugaðu nettengingu',
  choose_category: 'Veldu einn viðskiptaflokk ...',
  choose_mobile_provider: 'Margar veitendur virktar - vinsamlegast veldu eina',
  clear_settings: 'Hreinsa stillingar',
  clear_settings_successful: 'Stillingum hefur verið hreinsað',
  clear_settings_warning: 'Að hreinsa þessar stillingar endurstillir tengingarupplýsingar þínar. Smelltu á staðfesta til að halda áfram.',
  client_ticket: 'clientTicket',
  close_shift: 'Loka skrá',
  company_address: 'Heimilisfang fyrirtækis',
  company_registration_no: 'Skráningarnúmer fyrirtækis',
  completed_payments: 'Loknar greiðslur',
  confirm: 'Staðfesta',
  confirm_cash_payment: 'Staðfestu greiðsluupphæð',
  confirm_new_currency: 'Staðfestu gjaldmiðilsbreytingu',
  confirm_open_orders_amount_change: 'Staðfesta breytingu á útistandandi kerruupphæð?',
  confirm_payment: 'Staðfestu greiðslu',
  confirm_with_change: 'Staðfestu með breytingu',
  confirm_with_tip: 'Staðfestu með þakklæti',
  connect_barcode: 'Tengdu strikamerkjaskannann (Socket CHS)',
  connect_to_webshop: 'Tengdu vefverslunina þína?',
  connected_printer: 'Tengdir prentarar',
  contact_information: 'Hafðu upplýsingar',
  contact_us: 'support@countrhq.com',
  create_product: 'Búðu til vöru',
  currently_trial_mode_transaction_wiped_visit_dashboard: 'POS er sem stendur í prófunarham. Viðskipti þín þurrkast út á fjögurra klukkustunda fresti.',
  currently_trial_mode_visit_dashboard: 'POS er nú í prófunarham. Þú getur skipt yfir í lifandi stillingu með því að fara á mælaborðið þitt á netinu.',
  customer: 'Viðskiptavinur',
  customer_card: 'Viðskiptavinakort',
  customer_info: 'Upplýsingar um viðskiptavin',
  customer_instructions: 'Leiðbeiningar viðskiptavina',
  customer_lookup: 'Viðskiptavinur leit',
  customer_lookup_error: 'Villa á netþjóni við leit viðskiptavina',
  customer_name: 'Viðskiptavinur',
  customer_not_valid: 'Viðskiptavinur er ekki gildur',
  customer_receipt: 'Kvittun viðskiptavinar',
  customer_since: 'Viðskiptavinur síðan',
  Date: 'Dagsetning',
  deregister_terminal: 'Afskrá tækið',
  description_mandatory: 'Lýsing er lögboðin fyrir inn- og úttektarviðskipti',
  device_locked: 'Tæki læst',
  device_locked_enter_pin: 'Þetta tæki er læst. \nVinsamlegast sláðu inn PIN-númer stjórnanda til að opna það',
  did_cheque_refund_succeed: 'Athugun skilað til viðskiptavinar?',
  did_mobile_payment_succeed: 'Tókst farsímagreiðsla?',
  did_mobile_refund_succeed: 'Tókst endurgreiðsla farsíma?',
  direct_connection: 'Beint tengt',
  disabled_product: 'Vörur fyrir fatlaða',
  discount_currency: 'Afsláttur ({{currency}})',
  do_you_want_remove_payment: 'Viltu fjarlægja greiðsluna?',
  download_adyen_app: 'Sækja Adyen App',
  download_izettle_app: 'Sæktu iZettle App',
  download_payleven_app: 'Sæktu Payleven App',
  download_sumup_app: 'Sækja Sumup App',
  download_yodo_app: 'Sæktu Yodo Rocket app',
  downloading_categories: 'Sækir flokka ...',
  downloading_taxes: 'Sækir skatta ...',
  downloading_transactions: 'Sækir viðskipti ...',
  ecommerce_none: 'Ég bý til vörur sjálfur',
  ecrId: 'ecrId',
  eft_transaction_code: 'EFTTxnID',
  email_invoice: 'Reikningur tölvupósts',
  employee_name: 'Starfsmaður',
  employee_pin: 'PIN starfsmanns',
  empty_cart: 'Tóm kerra',
  enable_staff_pin: 'Virkja PIN innskráningu starfsfólks',
  enter_a_valid_search_value: 'Sláðu inn gilt leitargildi',
  enter_api_key: 'Sláðu inn virkjunarlykilinn þinn',
  enter_base_url: 'Sláðu inn vefslóð (þ.m.t. „http: //“)',
  enter_merchant_auth_code_payconiq: 'Sláðu inn haus fyrir Payconiq heimild',
  enter_merchant_iban_payconiq: 'Sláðu inn þitt Payconiq-skráða IBAN',
  enter_merchant_id_payconiq: 'Sláðu inn Payconiq auðkenniskaupa þinn',
  enter_order_name: 'Sláðu inn nafn körfu',
  enter_reseller_id: 'Sláðu inn auðkenni sölumanns þíns',
  enter_user_number: 'Sláðu inn notandanúmerið þitt',
  enter_user_password: 'Sláðu inn lykilorð notanda',
  error_adding_device: 'Villa kom upp við skráningu tækisins',
  error_refreshing_merchant: 'Villa við að sækja reikningsupplýsingar.',
  error_retrieve_categories: 'Villa við að sækja flokka. \nVinsamlegast athugaðu nettenginguna og reyndu aftur',
  error_retrieve_products: 'Villa við að sækja vörur. \nVinsamlegast athugaðu nettenginguna og reyndu aftur',
  error_retrieve_taxes: 'Villa við að sækja skatta. \nVinsamlegast athugaðu nettenginguna og reyndu aftur',
  error_while_retrieving_results: 'Villa við að sækja niðurstöður',
  errorCode: 'villumelding',
  errorMessage: 'villu skilaboð',
  ersReference: 'ers Tilvísun',
  excess_amount: 'Umfram magn',
  expected_amount: 'Áætluð upphæð',
  expected_change_gratuity: 'Væntanleg breyting eða þakklæti',
  external_url: 'Ytri slóð',
  failed_cancel: 'Mistókst að hætta við viðskipti',
  feature_only_android: 'Þessi aðgerð er aðeins í boði fyrir Android',
  find_terminal: 'Leitaðu að flugstöðvum',
  for_security_enter_password: 'Af öryggisástæðum, vinsamlegast sláðu aftur lykilorðið þitt',
  for_this_device: 'Aðeins fyrir þetta tæki',
  for_this_store: 'Aðeins fyrir þessa verslun',
  four_inch: '4 tommu',
  generate_keys: 'Búðu til lykla',
  generating_keypair: 'Býr til lyklapör ... \n Þetta getur tekið nokkrar mínútur',
  generating_reports: 'Býr til skýrslu ...',
  generic_usb: 'Almennt USB',
  get_your_free_pos: 'Fáðu POS þinn á www.countrhq.com',
  gift_code: 'Gjafakóði',
  gratuity_amount: 'Þóknunarupphæð',
  gratuity_percentage: 'Þóknunarprósenta',
  group_items_in_cart: 'Flokkaðu sömu hluti saman í körfu',
  shared_secret: 'SharedSecret',
  impossible_refresh_you_have_transactions: 'Þú hefur {{transaction_number}} viðskipti ekki samstillt við netþjóninn. \n Nú verður reynt að samstilla þessar færslur í bakgrunni ...',
  impossible_signout_you_have_transactions: 'Ómögulegt að skrá þig út. \nÞú ert með {{transaction_number}} viðskipti / samskipti ekki samstillt við netþjóninn. \n Nú verður reynt að samstilla þessar færslur í bakgrunni ...',
  insert_printer_name: 'Vinsamlegast settu inn (3 stafir eða fleiri) nafn fyrir prentarann ​​þinn.',
  inserted_login_not_valid: 'Innskráning röng',
  inserted_value_not_valid: 'Gildi sem sett er inn er ekki gilt',
  installmentAmount: 'afborgunarmagn',
  invalid_credit_card_number: 'Kreditkortanúmer verður að vera 16 stafa',
  invoiceReference: 'reikningur Tilvísun',
  invoiceStatus: 'reikningurStöðus',
  ip_address: 'IP tölu',
  is_business: 'Upplýsingar um viðskipti',
  is_correct_amount_tendered: 'Er {{upphæð}} rétta upphæð?',
  isDone: 'Ekki meira að hlaða ...',
  isError: 'Pikkaðu til að reyna aftur ...',
  isTap: 'Pikkaðu til að hlaða meira ...',
  join_thousands_of_merchants: 'Þúsundir kaupmanna hafa gengið til liðs við Countr. Í dag er komið að þér. Skráðu þig Frítt',
  language: 'Tungumál',
  last_visit: 'Síðasta heimsókn',
  leave_tour: 'Skildu ferðina',
  leaving_tour: 'Fer af stað ...',
  loyal_customer: 'Dyggur viðskiptavinur',
  mac_address: 'MAC heimilisfang',
  mailing_list_permission: 'Bæta við póstlista',
  manual_mobile_payment: 'Farsímagreiðsla',
  manual_mobile_refund: 'Endurgreiðsla fyrir farsíma',
  max_amount_exceeded: 'Upphæð er hærri en leyfilegt hámark fyrir þennan greiðslumáta',
  menu_item_12char: 'Heiti valmyndaratriða má ekki vera meira en 12 stafir',
  menu_item_name: 'Heiti valmyndaratriðis (hámark 12 stafir)',
  merchant_receipt: 'Kaupmannakvittun',
  merchantDisplayName: 'merchantDisplayName',
  merchantId: 'söluaðili',
  mid: 'MIDDI',
  mobile_number: 'Farsímanúmer',
  mobile_payment: 'Farsími',
  mobile_payments: 'Farsímagreiðslur',
  new_price: 'Nýtt verð ({{currency}})',
  no_available_addons_for_product: 'Engin viðbót í boði fyrir valda vöru.',
  no_connected_printer: 'Enginn tengdur prentari',
  no_connected_terminal: 'Engin tengd flugstöð',
  no_customer_found: 'Enginn viðskiptavinur fannst',
  no_customer_selected: 'Enginn viðskiptavinur valinn',
  no_printer_configured: 'Enginn prentari stilltur',
  no_printer_found: 'Enginn prentari fannst \n Smelltu hér ef þú ert með USB-tengdan Star prentara',
  no_product_in_cart: 'Engin vara í körfunni',
  no_terminals_found: 'Engar flugstöðvar fundust',
  no_url_specified: 'Villa, engin vefslóð tilgreind.',
  none: 'Enginn',
  note_mandatory_cash_difference: 'Skýring er lögboðin ef munur er á væntum og raunverulegum fjárhæðum',
  note_optional: 'Athugasemd (valfrjálst)',
  numberOfInstallments: 'numberOfInstallments',
  numpad: 'Numpad',
  offline: 'Ótengdur',
  ok: 'allt í lagi',
  one_or_more_carts_are_frozen_finish_payment: 'Ein eða fleiri kerrur eru frosnar. Ljúktu við greiðsluna áður en þú gerir einhverjar aðgerðir.',
  online: 'Á netinu',
  open_market: 'Viltu opna markaðinn?',
  open_register_before_transaction: 'Þú verður að opna skrána áður en þú getur gert viðskipti',
  open_shift: 'Opna skrá',
  order_printer: 'Karfa prentari',
  orderId: 'orderID',
  orderRef: 'röðRef',
  other_text: 'Annar texti',
  paid: 'Greitt',
  paid_so_far: 'Greitt hingað til',
  paid_with_method: 'Greitt með {{aðferð}}',
  pan: 'PANNA',
  panLastDigits: 'Pan síðustu tölustafir',
  panMasked: 'Pan Masked',
  PANSeq: 'PANSeq',
  partial_payment: 'Hlutagreiðsla',
  partial_payment_not_allowed_for: 'Ekki er heimilt að greiða fyrir hlutagreiðslur',
  partial_payment_paid_successfully: 'Hlutagreiðsla hefur verið greidd með góðum árangri',
  pay_later: 'Borgaðu seinna',
  pay_later_allows_only_refund_all: 'Með greiðslu seinna er aðeins hægt að endurgreiða allt. Fyrir endurgreiðslur að hluta skaltu klára greiðsluna.',
  pay_later_refund: 'Hætta við að borga seinna',
  pay_on_account: 'Borgaðu fyrir reikninginn',
  payconiq: 'Payconiq',
  paying_now: 'Borgar núna',
  payment_cancelled: 'Greiðslu hætt',
  payment_error: 'Greiðsluvilla',
  payment_info: 'greiðsluupplýsingar',
  payment_insert_card: 'Settu kort',
  payment_method_not_configured: '{{payment_method}} ekki rétt stillt í þessu tæki',
  payment_number: 'Greiðslunúmer',
  payment_processor_options: 'Valkostir greiðslumiðlunar',
  payment_provider: 'Greiðsluaðili',
  payment_success: 'Greiðsla tókst',
  payment_terms_in_net_days: 'Greiðsluskilmálar (nettó dagar)',
  personal_information: 'Persónuupplýsingar',
  pickup_rewards: 'Taktu verðlaun',
  pin: 'PIN-númer',
  please_insert_a_valid_amount: 'Vinsamlegast settu inn gilda upphæð',
  posEntryMode: 'posEntryMode',
  prefName: 'prefName',
  print: 'Prenta',
  print_also_product_details: 'Viltu líka prenta upplýsingar um vörur?',
  print_error: 'Prentvilla',
  print_product_details: 'Prentaðu upplýsingar um vörur',
  print_successful: 'Prentun tókst',
  printer_connect_desc: 'WiFi prentarar þurfa að vera tengdir við sama net og þetta tæki. \n Bluetooth-prentarar þurfa að vera paraðir í gegnum kerfisstillingarnar áður en þeir eru fáanlegir hér',
  printer_removed: 'Prentari fjarlægður',
  printer_test: 'Prentarapróf',
  printing_report: 'Býr til og prentar skýrslu ...',
  proceed_with_next_payment: 'Haltu áfram með næstu greiðslu.',
  processorName: 'örgjörvunafn',
  product_is_disable_continue_anyway: 'Varan sem þú valdir er ekki fáanleg (hugsanlega ekki á lager). Viltu bæta því við körfuna?',
  product_sync: 'Vörusamstilling',
  profile_average: 'Meðalkaup',
  protected_sections: 'Verndaðir hlutar',
  receipt_printer: 'Kvittunarprentari',
  redeem_reward: 'Innleysa umbun',
  referenceNumber: 'Tilvísunarnúmer',
  refresh_customers: 'Hressa viðskiptavinalistann',
  refresh_merchant: 'Hressa reikningsupplýsingar',
  refresh_transactions: 'Hressa viðskipti',
  refreshing_merchant: 'Hressir reikningsupplýsingar ...',
  refund_number: 'Endurgreiðslunúmer',
  refunds_not_available_for_provider: 'Endurgreiðslur ekki í boði fyrir veitanda: {{payment_provider}}',
  register_terminal: 'Skráðu tæki',
  reissue: 'Endurútgáfa',
  remaining_payment: 'Eftirstöðvar greiðslu',
  remove_customer_from_cart: 'Fjarlægðu úr kerrunni',
  remove_payment: 'Fjarlægðu greiðslu',
  remove_printer: 'Fjarlægðu prentara',
  removing_products: 'Fjarlægir gamlar vörur ...',
  reopen_shift: 'Opnaðu aftur skrána',
  replace_customer: 'Skipta um viðskiptavin',
  report_by_shift: 'Virkja daglegt opið / lokað',
  report_printer: 'Tilkynna prentara',
  requested_amount: 'Umbeðin upphæð',
  reward: 'Verðlaun',
  round_cash_transactions: 'Umferð peningagreiðslna næst 0,05',
  save_printer: 'Vista prentara',
  scan: 'Skannaðu',
  scan_barcode_instruction_one: 'Skannaðu strikamerkið til að setja Bluetooth skanna í parunarham',
  scan_barcode_instruction_two: 'Gakktu úr skugga um að kveikt sé á Bluetooth í stillingum tækisins og paraðu síðan Socket CHS skannann',
  scan_code_not_exist: 'Skannaður kóði {{strikamerki}} ekki viðurkenndur',
  scan_qr_code: 'Skannaðu QR kóða',
  scanner: 'Skanni',
  scanner_connected: 'Strikamerkjaskanni tengdur',
  scanner_disconnected: 'Strikamerkjaskanni aftengdur',
  scanner_pair_completed: 'Pörun strikamerkjaskannar lokið',
  search_for_customer: 'Leitaðu að viðskiptavini',
  search_report: 'Leitarskýrslur',
  search_transaction: 'Leitarviðskipti',
  searching_terminals: 'Leitar að flugstöðvum ...',
  security_and_permissions: 'Öryggi og heimildir',
  select_one_payment_method: 'Virkja þarf að minnsta kosti einn greiðslumáta',
  select_payment_method: 'Vinsamlegast veldu kortahjálp af listanum hér að neðan',
  select_terminal: 'Veldu flugstöð',
  send_email: 'Senda tölvupóst',
  send_to_another_customer: 'Senda til annars viðskiptavinar',
  send_to_current_customer: 'Senda til núverandi viðskiptavinar',
  send_to_customer: 'Senda til viðskiptavinar',
  sending_receipt: 'Sendir kvittun ...',
  server_error: 'Villa á netþjóni',
  session: 'Þing',
  set_new_price: 'Nýtt verð',
  setting_saved: 'Stilling vistuð',
  settings_connected_terminals: 'Tengd flugstöð',
  settings_printer_available_printers: 'Í boði prentarar',
  settlementDate: 'uppgjörDags',
  setup_admin_pin_in_settings: 'Stilltu PIN-númer stjórnanda í Stillingar, undir flipanum Reikningur.',
  shift_closed: 'Skránni hefur verið lokað',
  shift_opened: 'Skráin hefur verið opnuð',
  shifts: 'Vaktir',
  shopper_reference: 'ShopperReference',
  show_customer: 'Sýna upplýsingar um viðskiptavini við móttöku',
  show_employee: 'Sýnið nafn starfsmanns við móttöku',
  show_customer_payconiq: 'Vinsamlegast veldu innritaðan viðskiptavin eða skannaðu strikamerki viðskiptavinarins / NFC táknið',
  show_new_product_tile: 'Sýnið „búa til vöru“ flísar',
  show_password: 'Sýna lykilorð',
  sign_out_header: 'Ertu viss?',
  sign_out_warning: 'Útskráning gæti endurstillt forritastillingar þínar, þar á meðal greiðsluaðilar og prentarar. Vinsamlegast notaðu læsingaraðgerðina (með PIN-númer stjórnanda eða starfsmanns) ef þú vilt ekki hreinsa stillingar þínar.',
  sign_up_to_print: 'Skráðu þig á ókeypis prófreikning til að prenta kvittun. Ekkert kreditkort þarf, hætta við hvenær sem er.',
  sign_up_to_send_email: 'Skráðu þig fyrir ókeypis prófreikningi til að senda kvittun í tölvupósti. Ekkert kreditkort þarf, hætta hvenær sem er.',
  signing_out: 'Skráir þig út ...',
  signup_for_mailing_list: 'Biðjið viðskiptavini að gerast áskrifandi að póstlistanum',
  sorry_report_not_available: 'Skýrsla ekki til.',
  start_using_pos: 'Byrjaðu að selja með Countr',
  state: 'Ríki / hérað',
  status: 'Staða',
  store_powered_by: 'Þessi verslun er knúin áfram af',
  street: 'Street',
  suggested_amount: 'Tillaga að upphæð',
  suggested_tip: 'Tillaga um þakklæti',
  support: 'Stuðningur',
  telephone: 'Sími',
  terminal_port: 'Gátt stillt fyrir flugstöð',
  terminal_selected: 'Flugstöð vistuð',
  terminalId: 'terminalId',
  test: 'Þetta er próf',
  three_inch: '3 tommu',
  tid: 'TID',
  Time: 'Tími',
  tip: 'Þakklæti',
  tip_added: 'Þóknun bætt við',
  to_pay: 'Að greiða',
  total_amount: 'Heildarupphæð',
  transaction_code: 'færslukóði',
  transaction_date: 'TransactionDate',
  transaction_finalized: 'Lokið fyrir viðskipti',
  transaction_pending: 'Viðskipti í bið',
  transaction_started: 'Viðskipti hófust',
  transaction_succeeded: 'Viðskipti tókst',
  transaction_time: 'Færslutími',
  transaction_timeout: 'Tímamörk viðskipta',
  transactionSeq: 'viðskiptiSeq',
  transactionType: 'viðskiptiType',
  try_it_now: 'Prófaðu núna',
  tsi: 'TSI',
  tvr: 'TVR',
  two_inch: '2 tommu',
  uncategorized: 'Enginn flokkur',
  valid_phone_number: 'Gilt símanúmer',
  validating_fields: 'Staðfestir reiti ...',
  validation_in_progress: 'Staðfesting í gangi',
  verification_code: 'Staðfestingarkóði',
  verification_method: 'Staðfestingaraðferð',
  waiting_for_customer: 'Bið eftir viðskiptavini',
  want_to_sync_products: 'Viltu samstilla vörur þínar?',
  yodopay: 'YodoPaY',
  you_must_select_a_store: 'Þú verður að velja verslun.',
  add_name_device: 'Bættu nafni við spjaldtölvuna / símann þinn til að auðkenna það síðar (skylda)',
  card_number: 'Kortanúmer',
  change_language: 'Skipta um tungumál',
  choose_currency: 'Veldu aðal gjaldmiðil',
  clear_cart_after_finish: 'Endurstilla nafn körfu / borðs eftir greiðslu',
  confirm_new_language: 'Staðfesta nýtt tungumál? \nÞú verður að loka alveg og endurræsa forritið til að beita þessari breytingu',
  customer_signature: 'Undirskrift viðskiptavinar',
  day: 'Dagur',
  device_name_mandatory: 'Nafn tækis er skylda',
  downloading_refunds: 'Sækir endurgreiðslur ...',
  email_report: 'Tölvupóstsskýrsla',
  email_successfully_sent_test: 'Prófpóstur sendur á reikninginn þinn',
  enter_table_name: 'Sláðu inn heiti töflu',
  error: 'Villa',
  error_retrieve_refunds: 'Villa við að sækja endurgreiðslur',
  error_retrieve_transactions: 'Villa við að sækja viðskipti',
  for_this_device_see_dashboard: 'Aðeins fyrir þetta tæki - sjá Stjórnborð fyrir fleiri skýrslur',
  hide_new_sale: 'Fela skjá \'Nýja sölu\' eftir greiðslu',
  last_transaction_result: 'Nýjasta niðurstaða viðskipta',
  merge_warning: 'Þetta sameinar innihald þessara kerra / borða',
  month: 'Mánuður',
  post_code: 'Póstnúmer',
  print_order: 'Sýna send til pöntunarprentara',
  products_added_removed_refresh: 'Vörum var bætt við eða þær fjarlægðar - hressa listann?',
  quarter: 'Fjórðungur',
  refresh: 'Hressa',
  refreshing_products: 'Hressandi vörur ...',
  removing_categories: 'Fjarlægir gamla flokka ...',
  removing_taxes: 'Fjarlægir gamla skatta ...',
  select_interface_mandatory: 'Að velja viðmót er skylda',
  sms_successfully_sent_test: 'Próf SMS sent á reikninginn þinn',
  splitOrderPrintByCategory: 'Skipt prentun af pöntunum eftir flokkum',
  stored_digitally: 'Geymt stafrænt',
  stripe_id: 'stripeID',
  sync_support_info: 'Samstilla upplýsingar um stuðning',
  syncing_support_info: 'Samstillir stuðningsupplýsingar ...',
  table: 'Tafla',
  table_is_empty: 'Borðið er autt',
  tables_or_carts: 'Sýnið kerrur sem töflur',
  uncertain_card_payment_success: 'Óákveðinn árangur með kortagreiðslu',
  unknown_category: 'Óþekktur flokkur',
  unsynced_transactions_report_incorrect: '{{transaction_number}} viðskipti (s) ekki samstillt - skýrslur ekki uppfærðar',
  update_terminal_firmware: 'Uppfærðu flugstöðvarbúnað',
  week: 'Vika',
  year: 'Ár',
  email_queued: 'Netfang í biðröð',
  order_received: 'Ný / uppfærð pöntun móttekin',
  go_to_orders: 'Skoða pantanir',
  open_carts: 'Opnar kerrur',
  auto_print_order: 'Prentaðu óprentaðar pantanir á laun',
  auto_print_order_cart: 'Prentaðu óprentaðar pantanir á körfu / borðsendingu',
  external: 'Annað',
  no_printer_found_tcp: 'Enginn prentari fannst \n Smelltu hér til að slá inn upplýsingar um prentara handvirkt',
  downloading_carts: 'Sækir kerrur ...',
  pay_later_hybrid: 'Borgaðu seinna',
  error_retrieve_carts: 'Villa við að sækja kerrur',
  no_ip_address: 'Ekkert heimilisfang',
  building_number: 'Byggingarnúmer',
  confirm_manual_payment: 'Staðfestu handvirka greiðslu',
  shipping_address: 'Sendingar heimilisfang',
  birth_date: 'Fæðingardagur',
  for_this_store_see_dashboard: 'Aðeins fyrir þessa verslun - sjá Stjórnborð fyrir fleiri skýrslur',
  customers: 'Viðskiptavinir',
  tickets_in: 'Miðar í',
  tickets_out: 'Miðar út',
  tickets_in_out: 'Miðar inn / út',
  unsynced_transaction_cant_refund: 'Viðskipti ekki enn samstillt við netþjón - endurgreiðsla ekki möguleg',
  show_stock_level: 'Sýna núverandi lager (ef það er í boði)',
  available: 'á lager',
  stock_not_available: 'birgðir ekki raktar',
  order_another_store: 'Pantaðu frá öðrum stað',
  order_products: 'Gerðu kleift að panta vörur frá öðrum stöðum',
  trial_expired: 'Reynslutímabilið þitt er útrunnið. POS virkni hefur verið óvirk.',
  left_menu: 'Vinstri flipi',
  right_menu: 'Hægri navbar',
  direct_pay_if_one_method: 'Sjálfvirk upphaf greiðslu ef ein aðferð er stillt',
  processing_vouchers: 'Vinnur tryggð körfu og fylgiskjöl ...',
  display_2_rows_of_categories: 'Sýna tvær raðir af flokkum',
  unknown_barcodes_as_vouchers: 'Meðhöndla óþekkt strikamerki sem fylgiseðla',
  validate_signature: 'Undirskrift viðskiptavina gild?',
  group_items_in_receipt_and_cart: 'Flokkaðu sömu hluti í körfu og kvittun',
  ask_customer_subscribe_email: 'Biddu viðskiptavininn um að gefa upp netfang',
  hide_categories: 'Fela flokka flísar',
  hide_all_products_category: 'Fela flísar á öllum vöruflokkum',
  provisional_receipt: '*** STÖÐULEGT ***',
  reprint: '*** REPRINT ***',
  block_reprints: 'Ekki leyfa endurprentun á hlutum',
  reports_at_store_level: 'Sýna skýrslur fyrir verslun, ekki tæki',
  max_addon_qty_exceeded: 'Hámark {{maxQty}} viðbætur leyfðar fyrir þennan hóp',
  min_addon_qty_unmet: 'Að lágmarki þarf {{minQty}} viðbætur frá {{groupName}}',
  press_button_to_wake_terminal: 'Vinsamlegast ýttu á græna hnappinn til að vekja PIN-stöðina',
  payments: 'Greiðslur',
  average_sale_duration_mins: 'Meðal sölu lengd (mín.)',
  save_order_move_next: 'Vista pöntun, haltu áfram að næsta tóma borði',
  max_orders_reached: 'Hámarksfjölda opinna pantana náð',
  show_open_orders: 'Sýna opnar pantanir fyrst í pöntunarlista',
  tender_created: 'Viðskipti hófust',
  invalid_request: 'Ógild beiðni',
  already_processed: 'Viðskipti þegar afgreidd',
  invalid_terminal: 'Ógild greiðslustöð',
  network_error: 'Net villa',
  bad_credentials: 'Ógild skilríki',
  server_issue: 'Get ekki haft samband við netþjóninn',
  pin_entered: 'PIN slegið inn',
  initial: 'Frumstillt',
  card_inserted: 'Kort sett í',
  card_swiped: 'Spil strjúkt',
  wait_app_selection: 'Beðið eftir vali á umsókn',
  wait_amount_adjustent: 'Beðið eftir aðlögun fjárhæðar',
  ask_gratuity: 'Biddu um þakklæti',
  gratuity_entered: 'Þakklæti inn',
  ask_dcc: 'Biddu um DCC',
  dcc_accepted: 'DCC samþykkt',
  dcc_rejected: 'DCC hafnað',
  processing_tender: 'Úrvinnsluútboð',
  wait_pin: 'Beðið PIN',
  provide_card_details: 'Gefðu upp kortaupplýsingar',
  card_details_provided: 'Kortaupplýsingar veittar',
  receipt_printed: 'Kvittun prentuð',
  referral: 'Tilvísun',
  referral_checked: 'Tilvísun athuguð',
  check_signature: 'Athugaðu undirskrift',
  signature_checked: 'Undirskrift athuguð',
  additional_data_available: 'Viðbótargögn tiltæk',
  transaction_acknowledged: 'Viðskipti viðurkennd',
  device_already_registered: 'Tæki þegar skráð',
  add_in_progress: 'Nú þegar að bæta við tæki',
  app_not_registered: 'Forrit ekki skráð',
  unknown_error: 'Óþekkt villa',
  timeout: 'Tíminn rann út',
  verify_failed: 'Gat ekki staðfest tækið',
  identify_failed: 'Gat ekki borið kennsl á tæki',
  registration_error: 'Skráningarvilla',
  sync_issue: 'Gat ekki samstillt tæki',
  show_select_customer: 'Sýna viðskiptavinaval',
  store_name_mandatory: 'Heiti verslunar er skylt',
  choose_store_dashboard_for_more: 'Veldu verslun - meira er hægt að bæta við í mælaborðinu fyrir skrifstofuna',
  recovering_incomplete_payment: 'Að endurheimta ófullkomna greiðslu - þetta getur tekið allt að 30 sekúndur',
  allow_negative_sale: 'Leyfa neikvæða söluupphæð',
  move_current_table_to: 'Færðu núverandi töflu ({{table_name}}) TIL',
  store: 'Geymið',
  print_specific_categories: 'Prentaðu ákveðna flokka',
  customer_display_attached: 'Viðskiptavinasýning fylgir',
  sale: 'Útsala',
  reps_and_waste_button_in_cart: 'Sýna fulltrúa og úrgangshnapp á körfu',
  small_product_tiles: 'Draga úr stærð vöruflísanna',
  large_product_tiles: 'Auka stærð flísar vöru',
  show_product_price_on_tile: 'Vöruverð á flísum',
  printer_roles: 'Hlutverk prentara',
  paper_size: 'Pappírsstærð',
  customer_display: 'Skoðun viðskiptavinar',
  enable_realtime_updates: 'Virkja rauntíma uppfærslur frá netþjóninum',
  show_open_orders_first: 'Sýna opnar pantanir fyrst í pöntunarlista',
  blank_lines_order_ticket: 'Auðar línur efst á miðanum',
  blank_lines_bottom_order_ticket: 'Auðar línur neðst í miða pöntunar',
  scan_card: 'Skanna kort',
  scan_nfc_or_barcode: 'Skannaðu NFC kort eða kóða',
  show_product_keypad: 'Sýna takkaborð á körfu',
  extra_payment_methods: 'Auka greiðslumáta',
  failed_recovery: 'Mistókst að endurheimta greiðslu',
  blank_line_between_order_items: 'Auð lína milli atriða miða',
  look_up_customers_using_barcodes: 'Bæta viðskiptavini við körfu með skanni',
  error_loading_configuration: 'Villa við að hlaða stillingar',
  error_verifying_payment_terminal: 'Villa við að staðfesta PIN-stöð',
  error_identifying_payment_terminal: 'Villa við að bera kennsl á PIN-stöðina',
  error_saving_configuration: 'Villa við að vista stillingar',
  conflicting_server_modes: 'Pin terminal og netþjónn eru misvísandi stillingar (TEST vs PRODUCTION)',
  nothing_to_print: 'Engin atriði til að prenta',
  large_item_name: 'Auka stærð prentaðs hlutar',
  print_ean_sku: 'Prentaðu EAN og SKU við móttöku',
  select_category_on_startup: 'Veldu flokk einu sinni við gangsetningu',
  split_tickets_item: 'Skipt prentun af pöntunum eftir hlut',
  split_tickets_amount: 'Skipt prentun af pöntunum eftir magni',
  split_print: 'Skipt prentun',
  refreshing_customers: 'Hressandi viðskiptavinir ...',
  downloading_customers: 'Sækir viðskiptavini ...',
  error_retrieve_customers: 'Villa við að sækja viðskiptavini. \nVinsamlegast athugaðu nettenginguna og reyndu aftur',
  server_error_please_retry: 'Villa á netþjóni. Reyndu aftur',
  vibrate_on_click: 'Viðbrögð við titringi við smell á hlut',
  keep_screen_on: 'Ekki leyfa skjánum að sofa',
  refund_same_method: 'Þvingaðu endurgreiðslur til að vera með upphaflegum greiðslumáta',
  cannot_refund_more_than_paid: 'Get aðeins endurgreitt að hámarki {{max_amount}} í gegnum {{payment_method}}',
  tablet_date_incorrect: 'Dagsetning tækisins er rangt stillt - möguleg öryggisáhætta',
  extra_non_revenue_info: 'Biddu um auka upplýsingar um hluti sem ekki eru tekjur',
  enter_extra_info: 'Sláðu inn upplýsingar um auka atriði',
  giftcard_or_reference_no: 'Gjafakort eða tilvísunarnúmer',
  inserted_code_not_valid: 'Kóði rangur',
  validate_user: 'Staðfesting notanda ({{user_address}})',
  enter_validation_code: 'Vinsamlegast sláðu inn staðfestingarkóðann sem þú fékkst með SMS eða tölvupósti',
  tax_rate: 'Skatthlutfall',
  pretax: 'Nettó',
  deposit: 'Innborgun',
  can_not_refund_with_different_provider: 'Get ekki endurgreitt {{txn_payment_method}} viðskipti með {{selected_payment_method}}',
  external_scanner_connected: 'Ytri skanni tengdur',
  cancelling: 'Hættir við',
  sumup: 'SumUp',
  ccv: 'CCV',
  payplaza: 'PayPlaza',
  worldline: 'Worldline',
  adyen: 'Adyen',
  rabosmartpin: 'Rabo SmartPin',
  exchange: 'Skipti',
  ask_exchange: 'Leyfa vöruskipti',
  exchange_error: 'Skiptavilla',
  exchange_items: 'Skipt um hluti',
  exchangeable: 'Skiptanlegt',
  to_be_exchanged: 'Til að skiptast á',
  exchange_all: 'Skipt um allt',
  negative_sale_amount: 'Upphæðin til að greiða er minna en 0',
  download_rabosmartpin_app: 'Sæktu Rabo SmartPin forritið',
  transaction_disabled: 'Greiðslur þriðja aðila eru ekki leyfðar með þessum samningi',
  transaction_in_progress: 'Önnur greiðsla er þegar í gangi',
  refund_offline: 'Endurgreiðslur eru ekki mögulegar í takmörkuðum ham',
  refund_not_allowed: 'Endurgreiðslur eru ekki leyfðar fyrir innskráðan notanda',
  cash_forbidden: 'Greiðslur í reiðufé ekki leyfðar með þessum samningi',
  notify_new_online_order: 'Sýna tilkynningu þegar ný netpöntun berst',
  print_new_online_order: 'Prentaðu sjálfkrafa nýjar netpantanir þegar þær berast',
  delivery_information: 'Upplýsingar um afhendingu',
  voucher_already_processed: 'Úttektarmiða þegar unnið',
  no_applicable_items_in_cart: 'Engir viðeigandi hlutir fundust í körfu',
  discount_applied: 'Afsláttur beitt',
  invalid_voucher: 'Ógilt skírteini',
  allow_voucher_scan: 'Leitaðu að fylgiskjölum með strikamerkjaskanni',
  enable_drop_shipping: 'Kveikja á sendingu',
  ask_no_covers_or_takeaway: 'Spyrðu alltaf töflustillingar',
  table_configuration: 'Tafla gerð',
  num_covers: 'Fjöldi gesta',
  takeaway: 'Taka í burtu',
  guests: 'Gestir',
  show_floorplan_after_sale: 'Sýnið grunnplan eftir hverja sölu',
  lock_after_x_minutes: 'Læsa eftir x mínútur af aðgerðaleysi',
  show_deposit_items: 'Sýna innborgunaratriði',
  pickup: 'Pallbíll',
  delivery: 'Afhending',
  transaction_fee: 'Viðskiptagjald',
  print_description: 'Prentaðu vörulýsingu við móttöku',
  only_cash_for_negative_amounts: 'Aðeins er hægt að velja reiðufé eða handvirka kortagreiðslu þegar upphæðin er minni en 0',
  phone_number_example: 'Sími - t.d. 31611111111',
  whatsapp_not_available_on_this_device: 'WhatsApp ekki sett upp í þessu tæki',
  receipt_not_yet_synchronised: 'Samstilling viðskipta við netþjóninn - reyndu aftur eftir nokkrar stundir',
  notify_online_orders_with_sound: 'Spilaðu hljóð þegar netpantanir hafa borist',
  restrict_online_orders_by_floor: 'Taktu aðeins við netpöntunum fyrir ákveðnar hæðir',
  remove_product: 'Fjarlægðu vöru',
  admin_access_needed: 'Þessi aðgerð er aðeins í boði fyrir notendur stjórnanda',
  customer_or_employee_name: 'Nafn viðskiptavinar eða starfsmanns',
  show_cart_on_left: 'Sýnið körfu vinstra megin',
  groupOrderPrintByCategory: 'Hópprentun pantana eftir flokkum',
  handpoint_shared_secret: 'SharedSecret',
  create_bundle: 'Búðu til búnt',
  select_items_to_bundle: 'Veldu hluti sem á að pakka saman',
  bundle_price: 'Knippaverð',
  create_bundle_items: 'Búðu til búnt af vörum á körfunni',
  bundle: 'Knippi',
  loss: 'Loss',
  stolen: 'Stolen',
  own_use: 'Own Use',
  giveaway: 'Giveaway',
  breakage: 'Breakage',
  student_check: 'athugun á nemendaauðkenni',
  deviceid_mandatory: 'Nafn tækis er skylda',
  send_order: 'Sendu pöntun',
  no_description: 'Þessi vara hefur enga lýsingu. Þú getur bætt við einum með því að ýta á „Breyta“ hnappinn eða heimsótt mælaborðið þitt.',
  change_description: 'Vinsamlegast sláðu inn lýsingu vörunnar.',
  main_cart_is_empty: 'Aðalvagninn er tómur',
  apply_to_all_carts: 'Sækja um allar kerrur',
  per_value_payment: '(Greitt fyrir virði)',
  select_a_seat: 'Veldu sæti',
  open_kds: 'KDS',
  save_table: 'Vista',
  save_cart: 'Vista',
  select_items: 'Veldu hluti sem á að',
  partial_payment_not_allowed_by_value: 'Ekki er heimilt að greiða fyrir hlutagreiðslur',
  store_insights: 'Innsýn í verslun',
  allow_ticket_scan: 'Leitaðu að miðar með strikamerkjaskanni',
  no_pin_employee: 'Starfsmaður án PIN',
  allow_ticket_scan_desc: 'Notaðu strikamerkjaskanna til að leita að miðum.',
  admin_only_custom_item: 'Virkja sérsniðið atriði aðeins fyrir stjórnanda',
  admin_only_custom_item_desc: 'Aðeins notendur með stjórnandaréttindi geta búið til sérsniðið atriði. Þú verður einnig að virkja PIN-númer starfsmanna.',
  mandatory_employee_pin: 'Skylt að virkja PIN-númer starfsmanna.',
  admin_only_refunds: 'Aðeins stjórnendur geta framkvæmt endurgreiðslur',
  admin_only_refunds_desc: 'Aðeins stjórnendur geta framkvæmt endurgreiðslur. Þú verður líka að virkja PIN-númer starfsmanna.',
  round_cash_transactions_desc: 'Námundaðu sjálfkrafa staðgreiðslur í næsta 0,05',
  unlimited_usage: 'Ótakmörkuð notkun',
  infinite: 'Ótakmarkað',
  disable_custom_discounts: 'Slökktu á sérsniðnum afslætti',
  disable_custom_discounts_desc: 'Slökktu á sérsniðnum afslætti en leyfðu fyrirfram skilgreinda.',
  guest: 'Gestir',
  select_method: 'Veldu aðferð',
  manual_insert: 'Handvirkt innlegg',
  enter_code: 'Sláðu inn kóða',
  existing_by_value_payment: '* Þú getur ekki blandað saman launum eftir verðmæti og borgum eftir sæti og/eða hlut',
  order_sent: 'Pöntun Send',
  select_multiple_seats: 'Veldu mörg sæti',
  change_seat_or_guest_num: 'Skiptu um sæti eða gestanúmer',
  change_guest_num: 'Breyta gestanúmeri',
  giftcard_low_value: '* Inneign gjafakorta er lægri en verðmæti valinna hluta. Prófaðu að borga eftir verðmæti í staðinn.',
  order_by_seat_desc: 'Virkjaðu röðun eftir sæti.',
  received_amount: 'Móttekin upphæð',
  order_products_action: 'Panta vörur',
  ordered_products: 'Pöntaðar vörur',
  select_table: 'Veldu borð',
  ordered: 'Pantaði',
  scan_or_type: 'Skannaðu eða sláðu inn',
  type_code: 'Sláðu inn kóða',
  number_extension: 'Númeraframlenging',
  move_processing_ticket_first: 'Færðu vinnslumiða fyrst',
  disable_adblocker: 'Vinsamlegast slökktu á AdBlocker þínum og endurnýjaðu síðuna',
  remove_partial_payment_by_item: 'Hlutagreiðsla eftir hlut fannst! Vinsamlegast fjarlægðu það til að halda áfram með þessa aðgerð!',
  add_nutrients: 'Bæta við næringarefnum',
  giftcard_warning_msg: 'Venjulegur gildistími gjafakorts er 2 ár. Ertu viss um að þú viljir minnka þetta?',
  edit_devicess_main_details: 'Breyttu helstu upplýsingum tækjanna.',
  create_custom_payment_methods_for_your_device: 'Búðu til sérsniðna greiðslumáta fyrir tækið þitt.',
  show_open_carts_from_device: 'Sýna opnar pantanir úr tækinu',
  create_discounts_for_your_stores: 'Búðu til afslátt fyrir staðsetningu þína.',
  create_reps_waste_for_your_stores: 'Búðu til reps og sóun fyrir staðsetningu þína.',
  show_cat_bar: 'Sýna flokkastiku',
  bulk_update: 'Magnuppfærsluatriði',
  show_category_product_info: 'Sýna upplýsingar um flokk í vöru',
  ready_items: 'Tilbúnir hlutir',
  disabled: 'Öryrkjar',
  ready_orders: 'Tilbúnar pantanir',
  changes_list: 'Breytingalisti',
  action_type: 'Gerð aðgerða',
  previous_change: 'Fyrri breyting',
  new_changes: 'Nýjar breytingar',
  removed_fields: 'Fjarlægðir reitir',
  updated_fields: 'Uppfærðir reitir',
  full_history: 'Full saga',
  changes_history: 'Breytingarsögu',
  product_history: 'Vörusaga',
  category_history: 'Flokkasaga',
  device_history: 'Saga tækisins',
  enable_mandatory_customer_address: 'Skylt heimilisfang viðskiptavinar',
  please_fill_all_fields: 'Vinsamlega fylltu út alla nauðsynlega reiti: byggingarnúmer, götu, borg, ríki/hérað, póstnúmer, land, fornafn og eftirnafn, netfang eða símanúmer',
  select_file: 'Veldu skrá',
  drop_file: 'Slepptu skrá',
  create_media: 'Búa til fjölmiðla fyrir',
  upload_file: 'Hladdu upp skrá',
  add_custom_img: 'Bættu við sérsniðnu myndinni þinni',
  select_media: 'Veldu gerð efnis',
  external_url_iframe: 'Ytri vefslóð iframe',
  select_ticket_print: 'Veldu gerð til að prenta',
  external_service_provider: 'Ytri þjónustuaðili',
  barcode_input: 'Strikamerki inntak',
  stock_load_msg: 'Athugaðu allar vörur fyrir lager. Þetta gæti tekið smá tíma. Vinsamlegast bíðið!',
  no_available_stores: 'Engar verslanir í boði.',
  buy_combination_get_deal_new_price: 'Sameina hluti fyrir samningsverð',
  discount_specific_products_categories: 'Afsláttur af ákveðnum vörum eða flokkum',
  discount_all_prodcucts: 'Afsláttur af öllum vörum',
  buy_x_items_get_cheapest_discount: 'Kauptu x hluti, fáðu ódýrasta á afslætti',
  spend_certain_amount_get_discount: 'Eyddu ákveðinni upphæð, fáðu afslátt',
  customer_group_item_discount: 'Afsláttur á tiltekna hópa viðskiptavina',
  item_surcharge: 'Álag á tilteknar vörur, flokka eða viðskiptavinahópa',
  cart_surcharge: 'Aukagjald á alla pöntunina',
  import: 'Flytja inn',
  export: 'Útflutningur',
  simple: 'Einfalt',
  advanced: 'Ítarlegri',
  delete_import: 'Eyða innflutningi á vörum',
  upload_csv: 'Hladdu upp CSV skránni þinni',
  open_file: 'Opnaðu skrána með því að nota',
  save_file: 'Vistaðu skrána sem .csv',
  value_remaining: 'Verðmæti eftir:',
  shared_shift: 'Deila vakt',
  shared_shift_desc: 'Að skipta um starfsmann mun ekki þurfa að opna skrána aftur',
  sample_check_msg_top: 'Vinsamlegast bíðið. Það ætti að fara í skoðun.',
  sample_check_msg_bottom: 'Starfsmaður kemur sem fyrst.',
  full_basket_check: 'Full körfuskoðun',
  missing_items_amount: 'Upphæð sem vantar hluti',
  extra_items_scanned: 'Upphæð aukahluta',
  finish_check: 'Ljúktu við athugun',
  original_items: 'Upprunaleg atriði',
  checked_items: 'Athuguð atriði',
  merging: 'Sameining í gangi',
  select_table_from_list: 'Veldu töflu af listanum til að sameina allar aðrar valdar töflur',
  cant_merge_same: 'Ekki er hægt að sameina sömu töflu, vinsamlega veldu aðra töflu til að sameina',
  remove_from_table: 'taka af borði',
  eat_in: 'Borða í',
  locked_pos: 'POS er læst',
  fiscal_store_employee: 'Skattverslanir verða að hafa skráningarstarfsmann til að reka POS',
  invalid_employee: 'Ógildur starfsmaður',
  update_employee_ssn: 'Vinsamlegast uppfærðu kennitölu starfsmanna',
  social_security_number: 'Kennitala',
  fiscal_one_employee: 'Þú verður að hafa að minnsta kosti einn starfsmann skráðan í skattverslun',
  fiscal_employee_ssn: 'Starfsmenn sem skrá sig í verslanir í skattamálum verða að hafa gilt kennitölu',
  employee_unable_delete: 'Ekki er hægt að eyða starfsmanni vegna þess að hann er tengdur í fjármálaverslun. Vinsamlegast fjarlægðu starfsmanninn úr versluninni fyrst.',
  vat_receipt: 'Þetta er virðisaukaskattskvittun',
  not_vat_receipt: 'Þetta er ekki VSK kvittun',
  deposit_error: 'Innborgunarvilla',
  deposit_error_message: 'Þú getur ekki haft neikvæða innborgun tengda vöru með jákvætt verð. Aðeins vörur með verð 0 eða minna geta haft neikvæða innborgun.',
  assign_printers_to_your_location: 'Úthlutaðu prenturum á staðsetningu þína',
  save_new_printer: 'Vista nýjan prentara',
  update_printer: 'Uppfærðu prentarann',
  location_no_saved_printers: 'Það eru engir vistaðir prentarar fyrir þessa staðsetningu',
  sort_items_by_action: 'Raða hlutum eftir aðgerðum (undirbúningur, nýr, tilbúinn)',
  print_x_report: 'Prentaðu X skýrslu',
  shox_x_report: 'Sýna X skýrslu',
  print_z_report: 'Prentaðu Z skýrslu',
  show_z_report: 'Sýna Z skýrslu',
  unfiscalised_transactions_report_incorrect: '{{transaction_number}} viðskipti (s) ekki samstillt - skýrslur ekki uppfærðar',
  refund_print: 'Endurgreiða ',
  live_orders: 'lifandi pantanir'
}

export default isLang
