/* eslint-disable no-unused-vars */
import { useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { translate } from 'react-internationalization'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import IconButton from '@material-ui/core/IconButton'

import { AppInstances } from '../../utils/CountrSdk'
import CountrResources from '../../utils/CountrResources'
import CartUtils from '../../utils/CartUtils'

import { withRouter } from '../../wrappers/routeWrappers'

import ProductMedia from '../Shared/ProductMedia'
import GroupNote from './GroupNote'
import GroupAddons from './GroupAddons'

import { setCurrentGroups } from '../../store/actions/app'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '100%'
  },
  media: {
    height: 0,
    width: '100%',
    paddingTop: '56.25%',
    marginBottom: 15
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  color: {
    color: '#707070'
  },
  title: {
    display: 'flex',
    width: '100%',
    textTransform: 'uppercase',
    fontSize: '1rem',
    fontWeight: 600
  },
  marginLeft10: {
    marginLeft: '10px'
  },
  textBold: {
    fontSize: '1.1rem',
    fontWeight: 700
  },
  textBoldSmall: {
    fontSize: '0.9rem !important'
  },
  addonContainer: {
    marginLeft: '25px',
    marginBottom: '0px !important'
  },
  addonQuantity: {
    marginRight: '10px'
  },
  addonsContainer: {
    borderBottom: 'none !important',
    paddingTop: '3px !important',
    marginBottom: '0px !important'
  },
  cardContent: {
    paddingBottom: '7px'
  }
}))

function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null
}

const mapStateToProps = state => {
  return {
    carts: state.carts,
    device: state.device.device,
    store: state.store.store
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCurrentGroups: groups => dispatch(setCurrentGroups(groups))
  }
}

/**
 * @param  {} {group
 * @param  {} carts
 */
const Group = props => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const updateCart = useCallback(
    cart => dispatch({ type: 'UPDATE_CART', payload: { cart } }),
    [dispatch]
  )

  const { show_product_image, show_product_media } = props.device.settings

  const { group } = props
  const { color } = props.group

  const rgb = hexToRgb(color)

  const reselectElements = useCallback(() => {
    return document.querySelectorAll(
      `div.product__${CSS.escape(group.id)}__printed, 
           div.product__${CSS.escape(group.id)}__pending,
           div.product__${CSS.escape(group.id)}__new, 
           div.product__${CSS.escape(group.id)}__preparing,
           div.product__${CSS.escape(group.id)}__ready`
    )
  }, [group.id])

  const changeGroupStatus = async (group, status) => {
    const cartsToBeUpdated = []
    const countr = await AppInstances.getCountrSdk()
    props.carts.showingList.forEach(cart => {
      cart.items.forEach(item => {
        if (countr.retrieveCartEntryId(item.product, true) === group.id) {
          if (status === 'ready') {
            printCart(cart, item.product)
          }

          cartsToBeUpdated.push(cart)
        }
      })
    })

    await CartUtils.computeGroupNextStatus(cartsToBeUpdated, group, status)

    if (cartsToBeUpdated.length) {
      for (const element of cartsToBeUpdated) {
        const currentCart = element
        currentCart.status = countr.calculateStatus(currentCart.items)
        updateCart(currentCart)
        CountrResources.updateTransaction(currentCart, props.device._id)
      }
    }
  }

  const printCart = async (cart, product) => {
    if (
      props.store?.options?.printDevice ||
      localStorage.getItem('localDesktop')
    ) {
      const type = `${String(cart.__t).toLowerCase()}s`
      const countr = await AppInstances.getCountrSdk()
      const getUniqueId = countr.retrieveCartEntryId(product, true)

      countr[type].print(cart._id, {
        device:
          localStorage.getItem('localDesktop') ||
          props.store?.options?.printDevice,
        status: 'ready',
        item: getUniqueId
      })
    }
  }

  const highlighItems = (className, event, isOut) => {
    const elementsToHighligh = reselectElements()

    for (const element of elementsToHighligh) {
      const left = element.querySelector('.left')

      if (element) {
        switch (event) {
          case 'hover':
            left.classList[isOut ? 'remove' : 'add'](className)
            break

          default:
            left.classList.add(className)
            const scaleInterval = setInterval(() => {
              left.classList.toggle(className)
            }, 800)

            setTimeout(() => {
              clearInterval(scaleInterval)
            }, 2400)
            break
        }
      }
    }
  }

  return (
    <Card
      key={`${group.id}`}
      id={`${group.id}`}
      className={`grouped-content ${classes.root}`}
      style={{
        backgroundColor: rgb ? `rgb(${rgb.r},${rgb.g},${rgb.b}, 0.5)` : '',
        border: rgb ? `solid 5px rgb(${rgb.r},${rgb.g},${rgb.b}, 0.5)` : ''
      }}>
      <CardContent className={classes.cardContent}>
        <div className={`${classes.color} ${classes.title}`}>
          <p className={classes.textBold}>{group.qty}</p>
          <p className={`${classes.textBold} ${classes.marginLeft10}`}>
            {group.name}
          </p>
        </div>
        {!!group.image?.length && !!show_product_image?.value && (
          <CardMedia
            className={classes.media}
            image={group.image}
            title={group.name}
          />
        )}

        {!!group.media?.length && !!show_product_media?.value && (
          <div className="product-media">
            <h2>
              <span className="icon-image-add"></span>
              {translate('product_media')}
            </h2>
            <ProductMedia medias={group.media} options={{ width: '100%' }} />
          </div>
        )}

        {!!group.notes.length && (
          <GroupNote classes color notes={group.notes} />
        )}

        {!!Object.keys(group.addons).length && (
          <GroupAddons classes={classes} color addons={group.addons} />
        )}
      </CardContent>

      <CardActions className="group-card-actions" disableSpacing>
        <IconButton
          className="countr-icon__preparing"
          onClick={() => changeGroupStatus(group, 'preparing')}
          aria-label={translate('start_preparing')}
          title={translate('start_preparing')}>
          <i className="m-icon icon-hourglass"></i>
        </IconButton>
        <IconButton
          className="countr-icon__ready"
          onClick={() => changeGroupStatus(group, 'ready')}
          title={translate('send_completed')}
          aria-label={translate('send_completed')}>
          <i className="m-icon icon-check"></i>
        </IconButton>
        <IconButton className="hidden">
          <i className="m-icon icon-alarm"></i>
        </IconButton>
        <IconButton
          className="countr-icon__highlight"
          onClick={() => highlighItems('highlighted')}
          title={translate('highlight_items')}
          aria-label={translate('highlight_items')}>
          <i className="material-icons">&#xe25f;</i>
        </IconButton>
      </CardActions>
    </Card>
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Group))
