import { memo, useEffect, useState } from 'react'
import { translate } from 'react-internationalization'

const OrderCounter = memo(function OrderCounter({ props }) {
  const [ordersStatuses, setOrdersStatuses] = useState({})

  useEffect(() => {
    const statuses = {}
    props.carts.showingList.forEach(element => {
      if (element.status !== 'printed') {
        if (statuses[element.status] === undefined) {
          statuses[element.status] = 1
        } else {
          statuses[element.status] += 1
        }
      }
      if (element.status === 'printed') {
        if (statuses['pending'] === undefined) {
          statuses['pending'] = 1
        } else {
          statuses['pending'] += 1
        }
      }
    })

    setOrdersStatuses(statuses)
  }, [props.carts.showingList])

  const handleStatusToNumber = state => {
    let result = 0
    switch (true) {
      case state === 'preparing':
        result = 1
        break
      case state === 'ready':
        result = 2
        break
    }
    return result
  }

  return (
    <div className="order-counter">
      {ordersStatuses &&
        Object.keys(ordersStatuses)
          .sort((a, b) =>
            handleStatusToNumber(a) > handleStatusToNumber(b) ? 1 : -1
          )
          .map(orderStatus => {
            return (
              <div className="status-container" key={orderStatus}>
                <span>{translate(orderStatus)}</span>
                <span className={`status-counter`}>
                  {ordersStatuses[orderStatus]}
                </span>
              </div>
            )
          })}
    </div>
  )
})

export default OrderCounter
