import { useState, useEffect, useRef, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { translate } from 'react-internationalization'

import CountrResources from '../../utils/CountrResources'

import { AppInstances } from '../../utils/CountrSdk'
import CartUtils from '../../utils/CartUtils'
import useInterval from '../../utils/useInterval'

import Tools from '../Tools/Tools'
import GeneralTools from '../Tools/GeneralTools'
import ActionButton from '../Shared/ActionButton'
import TimeElapsedCounter from '../Shared/TimeElapsedCounter'
import CustomerDetails from './CustomerDetails'
import CartItem from './CartItem'

import './../../styles/cartaspos.scss'

const TOOLS_WRAPPER = 'cart-tools-wrapper'

const DELAY_TIMER = 60500 // 60.000 is one minute + 0.5 ms

function Cart(props) {
  const container = useRef(null)
  const toolsRef = useRef(null)
  const [cart, setCart] = useState({})
  const [currentIndex, setCurrentIndex] = useState()
  const [item, setItem] = useState()
  const [tools, setTools] = useState()
  const [prevState, setPrevState] = useState('')
  const [generalTools, setGeneralTools] = useState()
  const [cartStatus, setCartStatus] = useState('printed')
  const [isCartOrItem, setIsCartOrItem] = useState()

  const stores = useSelector(state => state.store.allStoresAssigned)
  const showingList = useSelector(state => state.carts.showingList)

  const dispatch = useDispatch()
  const updateCart = useCallback(
    cart => dispatch({ type: 'UPDATE_CART', payload: { cart } }),
    [dispatch]
  )

  const useLateTicketTimeout = useInterval(() => {
    setCartStatus(
      CartUtils.checkOrderIsDelayed(
        props.cart,
        props.settings.warning_delay.value
      )
    )
  }, DELAY_TIMER)

  useEffect(() => {
    if (props.settings.warning_delay.value) {
      return useLateTicketTimeout
    }
  }, [props.settings.warning_delay, useLateTicketTimeout])

  // TODO this has to move and DRY
  useEffect(() => {
    const computedStatusByTime = CartUtils.checkOrderIsDelayed(
      props.cart,
      props.settings.warning_delay.value
    )

    setCartStatus(computedStatusByTime)
  }, [props.cart, props.settings.warning_delay.value, showingList])

  useEffect(() => {
    if (!stores?.length && stores.length > 1) {
      setHasTooltip(true)
    }
  }, [stores.length])

  useEffect(() => {
    let countNew = 0
    let countPrinted = 0
    let countPreparing = 0
    let countReady = 0
    let countCompleted = 0
    let totalPerStatus = 0
    let cancelledStatus = 0

    // TODO
    // !Study this case and refactor, very expensive now
    // !at least move to countr utils with test
    props.itemPerCategory.forEach(item => {
      // const autocreated = item.product?.options?.auto_created
      item.status.forEach(status => {
        if (status.amount > 0) {
          switch (status.state) {
            case 'new':
              ++countNew
              break
            case 'printed':
            case 'pending':
              ++countPrinted
              break
            case 'ready':
              ++countReady
              break
            case 'preparing':
              ++countPreparing
              break
            case 'completed':
              ++countCompleted
              break
            case 'cancelled':
              ++cancelledStatus
              break
            default:
              break
          }

          ++totalPerStatus
        }
      })
    })

    let calculatedStatus = 'printed'

    if (countPreparing && !countPrinted) {
      calculatedStatus = 'preparing'
    }

    if (countReady && !countPreparing && !countPrinted) {
      calculatedStatus = 'ready'
    }

    if (countCompleted + countNew === totalPerStatus) {
      calculatedStatus = 'completed'
    }

    if (cancelledStatus === totalPerStatus) {
      calculatedStatus = 'cancelled'
    }

    props.cart.status = calculatedStatus

    setCartStatus(props.cart.status)
    setCart(props.cart)
  }, [props.cart, props.itemPerCategory, showingList])

  const openTools = useCallback((item, i, prevStatusState) => {
    setIsCartOrItem({
      type: 'item',
      itemName: item.product.name,
      itemId: item.product._id,
      itemAmount: item.status[i].amount
    })
    setCurrentIndex(i)
    setPrevState(prevStatusState)
    setItem(item)
    changeBackdropToolsDisplay('block')
    setTools(true)
  }, [])

  const closeTools = event => {
    if (event.target.className === TOOLS_WRAPPER) {
      changeBackdropToolsDisplay('none')
      setTools(false)
    }
  }

  const openGeneralTools = () => {
    setIsCartOrItem({
      type: 'cart'
    })
    changeBackdropToolsDisplay('block')
    setGeneralTools(true)
  }

  const changeBackdropToolsDisplay = display => {
    if (toolsRef.current) {
      toolsRef.current.style = `display: ${display};`
    }
  }

  const closeGeneralTools = event => {
    if (event.target.className === TOOLS_WRAPPER) {
      changeBackdropToolsDisplay('false')
      setGeneralTools(false)
    }
  }

  const updateTransaction = async (cart, type, status) => {
    if (type !== 'general') {
      setTools(false)
    } else {
      setGeneralTools(false)
    }

    updateCart(cart)

    changeBackdropToolsDisplay('none')

    CountrResources.updateTransaction(cart, props.device._id, {
      ...isCartOrItem,
      itemStatus: status
    })
  }

  const payCart = async cart => {
    const countr = await AppInstances.getCountrSdk()
    await countr.carts.payTrigger(cart._id, {
      device: props.currentEmbeddedDevice
    })
  }

  const printCart = async cart => {
    const countr = await AppInstances.getCountrSdk()

    await countr[
      `${cart.__t.charAt(0).toLowerCase()}${cart.__t.slice(1)}s`
    ].print(cart._id, {
      device: props.currentEmbeddedDevice
    })
  }

  const checkCategorySeparator = (item, currentIndex) => {
    if (props.settings.show_categories_separator.value) {
      if (currentIndex === 0) {
        return true
      }

      const prevItem = cart.items[currentIndex - 1]
      const currentItemCategory = item.product.categories[0]._id
      const prevItemCategory = prevItem.product.categories[0]._id

      return currentItemCategory !== prevItemCategory
    } else {
      return false
    }
  }

  const updateStatus = async (status, item, e) => {
    e.stopPropagation()
    const isCart = item.__t
    const employee = {}

    if (props.cart?.employee) {
      employee._id = props.cart?.employee._id
      employee.name = props.cart?.employee.name
    }

    if (isCart) {
      item.items.map(item => {
        const nextOne = CartUtils.computeNextStatus(
          item,
          employee,
          status,
          props.settings.hide_completed_items
        )
        return nextOne
      })
    } else {
      CartUtils.computeNextStatus(item, employee, status)
    }

    props.cart.device = props.device._id

    if (isCart) {
      props.cart.status = status
    }

    updateTransaction(props.cart)

    if (
      (props.store?.options?.printDevice ||
        localStorage.getItem('localDesktop')) &&
      status === 'ready'
    ) {
      const countr = await AppInstances.getCountrSdk()
      const type = `${String(props.cart.__t).toLowerCase()}s`
      let getUniqueId = ''

      if (!isCart) {
        getUniqueId = countr.retrieveCartEntryId(item.product, true)
      }

      countr[type].print(props.cart._id, {
        device:
          localStorage.getItem('localDesktop') ||
          props.store?.options?.printDevice,
        status: status,
        item: isCart ? 'all' : getUniqueId
      })
    }
  }

  const handleStatusToNumber = state => {
    let result = 0
    switch (true) {
      case state === 'preparing':
        result = 1
        break
      case state === 'ready':
        result = 2
        break
      case state === 'completed':
        result = 3
        break
    }

    return result
  }

  const handleNumberToStatus = state => {
    let result = 0
    switch (true) {
      case state === 0:
        result = 'new'
        break
      case state === 1:
        result = 'preparing'
        break
      case state === 2:
        result = 'ready'
        break
      case state === 3:
        result = 'completed'
        break
    }

    return result
  }

  const handleShortList = (a, b) => {
    const aNumber = handleStatusToNumber(a.state)
    const bNumber = handleStatusToNumber(b.state)
    return aNumber > bNumber ? -1 : aNumber < bNumber ? 1 : 0
  }

  return (
    !!Object.keys(cart).length && (
      <div className="cart-tools-wrapper-main" ref={container}>
        {generalTools && (
          <div className="cart-tools-wrapper" onClick={closeGeneralTools}>
            <GeneralTools
              cart={cart}
              closeTools={closeGeneralTools}
              updateTransaction={updateTransaction}
            />
          </div>
        )}

        {tools && (
          <div className="cart-tools-wrapper" onClick={closeTools}>
            <Tools
              cart={cart}
              item={item}
              updateTransaction={updateTransaction}
              closeTools={closeTools}
              currentIndex={currentIndex}
              prevState={prevState}
            />
          </div>
        )}

        <div className="cart-grid-item">
          <div className="cart-tools-wrapper-backdrop" ref={toolsRef} />
          <article className={`cart-wrapper`}>
            <header
              className={`cart-wrapper`}
              onClick={() => openGeneralTools()}>
              <div className="header-container">
                <div>
                  <p className="receipt_number">
                    {CartUtils.getCartName(cart)}
                  </p>
                </div>
                <div>
                  <p>
                    {`${CartUtils.getDateOptions(
                      CartUtils.getPropertyTimeValue(cart),
                      {
                        day: 'numeric',
                        month: 'numeric'
                      }
                    )}
                    ${CartUtils.getTimeOptions(
                      CartUtils.getPropertyTimeValue(cart),
                      { hour: '2-digit', minute: '2-digit' }
                    )}`}
                  </p>
                  <p
                    className={`status_container radius-12 background__${cartStatus}`}>
                    {cart.status?.length ? translate(cart.status) : 'new'}
                  </p>
                </div>
              </div>
              <div className="footer">
                {cart.extras.buzzer && (
                  <p className="note">
                    {translate('buzzer')}: {cart.extras.buzzer || ''}
                  </p>
                )}
                {cart.extras.note && (
                  <p className="note">Note: {cart.extras.note || ''}</p>
                )}
              </div>
            </header>
            <div className={`divider__${cartStatus}`}></div>

            <section className={`cart-items`}>
              {props.itemPerCategory
                .filter(item => {
                  return !item.product?.options?.auto_created
                })
                .map((item, i) => {
                  const sorted = item.status.sort(handleShortList)
                  return sorted.map((status, j) => {
                    if (
                      status.state !== 'new' &&
                      status.state !== 'paid' &&
                      status.amount > 0
                    ) {
                      return (
                        <CartItem
                          color={props.color}
                          key={`${item.product._id}_${i}_${j}`}
                          item={item}
                          status={status}
                          index={j}
                          source={cart.order_source}
                          openTools={openTools}
                          showCategorySeparator={checkCategorySeparator(
                            item,
                            i
                          )}
                          showCompleted={
                            !props.settings.hide_completed_items.value
                          }
                          guests={cart.guest_info}
                          updateStatus={updateStatus}
                          cart={props.cart}
                        />
                      )
                    } else {
                      return null
                    }
                  })
                })}
            </section>

            <footer className={`background__${cartStatus}`}>
              <div className="footer-container">
                <TimeElapsedCounter cart={cart}></TimeElapsedCounter>
                <p
                  onClick={() => {
                    const newCart = {
                      ...cart,
                      status: handleNumberToStatus(
                        handleStatusToNumber(cartStatus) + 1
                      ),
                      device: props.device._id
                    }
                    newCart.items = newCart.items.map(item => {
                      CartUtils.moveGeneralCartStatus(
                        item,
                        handleNumberToStatus(
                          handleStatusToNumber(cartStatus) + 1
                        ),
                        {},
                        false,
                        []
                      )

                      return item
                    })
                    setCart(newCart)
                    updateTransaction(
                      newCart,
                      'general',
                      handleNumberToStatus(handleStatusToNumber(cartStatus) + 1)
                    )
                  }}
                  className={
                    `margin-left-25 status_container` +
                    ` bottom_status_container background__${handleNumberToStatus(
                      handleStatusToNumber(cartStatus) + 1
                    )}`
                  }>
                  {handleNumberToStatus(handleStatusToNumber(cartStatus) + 1) &&
                  handleNumberToStatus(handleStatusToNumber(cartStatus) + 1)
                    .length
                    ? translate(
                        handleNumberToStatus(
                          handleStatusToNumber(cartStatus) + 1
                        )
                      )
                    : 'new'}

                  {handleStatusToNumber(cartStatus) + 1 === 2 && (
                    <div className="item-right">
                      <span
                        className={`color__${status.state} icon-check status_icon`}></span>
                    </div>
                  )}
                </p>
              </div>
              {cart.customer && <CustomerDetails cart={cart} />}
              {props.currentEmbeddedDevice && (
                <div className="cartTools">
                  <div className="actions">
                    {cart.__t === 'Cart' && (
                      <ActionButton
                        label="payment"
                        action={() => payCart(cart)}
                      />
                    )}
                    <ActionButton
                      label="print"
                      action={() => printCart(cart)}
                    />
                  </div>
                </div>
              )}
            </footer>
          </article>
        </div>
      </div>
    )
  )
}

export default Cart
