import { memo, useState } from 'react'
import useInterval from './../../utils/useInterval'
import './../../styles/Header.scss'

const TimeElapsedCounter = memo(function TimeElapsedCounter({ cart }) {
  const [time, setTime] = useState(0)

  useInterval(() => {
    const now = new Date()
    let delta = Math.abs(
      (now.getTime() - new Date(cart.status_date).getTime()) / 1000
    )
    const hours = Math.floor(delta / 3600)
    delta -= hours * 3600
    const minutes = Math.floor((delta / 60) % 60)
    delta -= minutes * 60
    const seconds = Math.floor(delta % 60)

    const time = `${`${hours}:${minutes < 10 ? '0' + minutes : minutes}:${
      seconds < 10 ? '0' + seconds : seconds
    }`}`
    setTime(time)
  }, 1000)

  return (
    <div className="clock">
      <span>{time}</span>
    </div>
  )
})

export default TimeElapsedCounter
