import { memo, useRef, useEffect, useCallback, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { translate } from 'react-internationalization'
import useWebSocket from 'react-use-websocket'
import TicketSound from '../assets/sounds/definite.mp3'
import ReactAudioPlayer from 'react-audio-player'
// Wrappers
import { withRouter } from '../wrappers/routeWrappers'

// Utils
import { AppInstances } from '../utils/CountrSdk'
import CountrResources from '../utils/CountrResources'

// Components
import Cart from '../components/CartAspos/Cart'
import Grouped from '../components/Grouped'

// Actions
import {
  setCarts,
  setPlayTicketSound,
  setCompletedCarts,
  setPrintDelivery
} from '../store/actions/carts'
import { setCategories } from '../store/actions/categories'
import { setDevices } from '../store/actions/device'

import CartUtils from '../utils/CartUtils'

// Redux Props
const mapStateToProps = state => {
  return {
    app: state.app,
    settings: state.settings,
    carts: state.carts,
    device: state.device.device,
    store: state.store.store,
    list_horizontal: state.settings.list_horizontal
  }
}

// Redux Dispatch
const mapDispatchToProps = dispatch => {
  return {
    setCategories: categories => dispatch(setCategories(categories)),
    setDevices: devices => dispatch(setDevices(devices)),
    setCarts: carts => dispatch(setCarts(carts)),
    setCompletedCarts: carts => dispatch(setCompletedCarts(carts)),
    setPlayTicketSound: play => dispatch(setPlayTicketSound(play)),
    setPrintDelivery: info => dispatch(setPrintDelivery(info))
  }
}

const MainPage = memo(props => {
  const { setDevices, setCategories, playTicketSound, printDelivery, carts } =
    props
  const { setPlayTicketSound, setPrintDelivery } = carts

  const socketUrl = `${process.env.REACT_APP_AWS_WEBSOCKET_URL}`

  const [socketHasFailed, setSocketHasFailed] = useState(false)
  const [canScrollRight, setCanScrollRight] = useState(false)
  const [canScrollLeft, setCanScrollLeft] = useState(false)
  const [scrollLeftInterval, setScrollLeftInterval] = useState(false)
  const [scrollRightInterval, setScrollRightInterval] = useState(false)

  const resource = useRef(new CountrResources())
  const allStoresAssigned = useSelector(state => state.store.allStoresAssigned)

  const dispatch = useDispatch()
  const updateCart = useCallback(
    cart => dispatch({ type: 'UPDATE_CART', payload: { cart } }),
    [dispatch]
  )

  const setShowingCarts = useCallback(
    carts => dispatch({ type: 'SET_SHOWING_CARTS', payload: carts }),
    [dispatch]
  )

  const setEmployees = useCallback(
    employees => dispatch({ type: 'SET_EMPLOYEES', payload: employees }),
    [dispatch]
  )

  const setCompletedCarts = useCallback(
    carts => dispatch({ type: 'COMPLETED_CARTS', payload: carts }),
    [dispatch]
  )

  const setCartToTransaction = useCallback(
    cart => dispatch({ type: 'SET_CART_TO_TRANSACTION', payload: { cart } }),
    [dispatch]
  )

  const setLoading = useCallback(
    status => dispatch({ type: 'SET_LOADING', payload: status }),
    [dispatch]
  )

  const kitchenCategories =
    props.device?.settings?.web_settings?.kitchen_categories.length || 0

  const loadOrdersPast = props.settings.load_orders_past.value || 0

  const setScrollButtons = useCallback(() => {
    const orderContainer = document.getElementById('grid-wrapper-main')
    if (orderContainer) {
      if (orderContainer.scrollLeft === 0) {
        setCanScrollLeft(false)
      } else {
        setCanScrollLeft(true)
      }
      if (
        Math.ceil(orderContainer.scrollLeft) + orderContainer.offsetWidth >=
        orderContainer.scrollWidth
      ) {
        setCanScrollRight(false)
      } else {
        setCanScrollRight(true)
      }
    } else {
      setCanScrollRight(false)
      setCanScrollLeft(false)
    }
  }, [])

  /**
   * Get all Actives and Completed Carts and Transactions
   */
  const refreshOrders = useCallback(async () => {
    setLoading(true)
    const countr = await AppInstances.getCountrSdk()

    const carts = await resource.current.getOrders(
      'carts',
      countr,
      allStoresAssigned,
      loadOrdersPast
    )

    const transactions = await resource.current.getOrders(
      'transactions',
      countr,
      allStoresAssigned,
      loadOrdersPast
    )

    const ticketsList = carts.actives.concat(transactions.actives)

    setShowingCarts(ticketsList.toSorted(CartUtils.compareOrderTime))
    setCompletedCarts(carts.completes.concat(transactions.completes))
    setScrollButtons()
    setLoading(false)
  }, [
    setLoading,
    allStoresAssigned,
    loadOrdersPast,
    setShowingCarts,
    setCompletedCarts,
    setScrollButtons
  ])

  // Refresh order after categories changes
  useEffect(() => {
    refreshOrders()
  }, [kitchenCategories, refreshOrders])

  const initEmployees = useCallback(async () => {
    const countr = await AppInstances.getCountrSdk()
    countr.employees.read({ sort: '-updated_at' }).then(employees => {
      if (employees?.length) {
        setEmployees(employees)
      }
    })
  }, [setEmployees])

  // Move it to use the one "paginate" that we added to Countr Utils
  const initCategories = useCallback(async () => {
    const countr = await AppInstances.getCountrSdk()
    const countCategories = await countr.categories.count()
    const loadedCategories = []
    let skip = 0

    while (loadedCategories.length < countCategories) {
      const res = await countr.categories.read({
        skip,
        limit: 50
      })
      skip += 50

      loadedCategories.push(...res)
    }

    setCategories(loadedCategories)
  }, [setCategories])

  // !This is wrong and will break with big accounts, refactor
  // !and move it to Utils as well
  const initDevices = useCallback(async () => {
    const countr = await AppInstances.getCountrSdk()
    countr.devices.read({ sort: '-updated_at' }).then(devices => {
      if (devices?.length) {
        setDevices(devices.filter(device => device._id !== props.device._id))
      }
    })
  }, [props.device._id, setDevices])

  useEffect(() => {
    console.log('Initial Render Effect Recovery Daily...')
    initCategories()
    initDevices()
    initEmployees()

    refreshOrders()

    return () => {
      setSocketHasFailed(true)
    }
  }, [initCategories, initDevices, initEmployees, refreshOrders])

  const sendSoundNotification = async () => {
    const targetDevice = localStorage.getItem('localDesktop')
    if (!targetDevice) {
      return
    }
    const countr = await AppInstances.getCountrSdk()
    const body = {
      message: '@playSound',
      status: 'info'
    }
    await countr.devices.readOne.notify(targetDevice, body)
  }

  const sendPrintDelivery = async cart => {
    try {
      if (!(localStorage.getItem('localDesktop') && cart)) return
      const countr = await AppInstances.getCountrSdk()

      await countr[
        `${cart.__t.charAt(0).toLowerCase()}${cart.__t.slice(1)}s`
      ].print(cart._id, {
        device: localStorage.getItem('localDesktop'),
        deliveryReceipt: true
      })
    } catch (ex) {
      console.log(ex)
    }
  }

  useEffect(() => {
    if (playTicketSound) {
      // Fire sound to device set up to listen for this
      sendSoundNotification()
      setTimeout(() => {
        setPlayTicketSound(false)
      }, 2000)
    }
  }, [playTicketSound, setPlayTicketSound])

  useEffect(() => {
    if (printDelivery?._id) {
      // Fire print command to device set up to listen for this
      if (props.settings.print_delivery?.value) sendPrintDelivery(printDelivery)
      setPrintDelivery(null)
    }
  }, [printDelivery, props.settings.print_delivery?.value, setPrintDelivery])

  /**
   * Document it properly
   */
  const { lastJsonMessage } = useWebSocket(socketUrl, {
    retryOnError: true,
    share: true,
    reconnectAttempts: 30,
    reconnectInterval: 3000,
    queryParams: {
      user: props.app.user._id,
      store: props.device.store,
      device: props.device._id,
      listeningStores: allStoresAssigned?.length
        ? allStoresAssigned.map(store => store._id).join()
        : props.device.store
    },

    //what does the close event look like when caused by the 2 hour limit? Is there a
    // different error code that you could use? I would look into returning false in
    // this case and adding the following option:
    shouldReconnect: () => {
      // console.log('shouldReconnect - ', _closeEvent)
      // if (_closeEvent.code === 1006) {
      //   return false
      // }

      return true
    },
    onOpen: () => {
      // console.log(`socketHasFailed ${socketHasFailed}`)
      if (socketHasFailed) {
        console.log(`socketHasFailed is ${socketHasFailed}, refreshing orders`)
        refreshOrders()
      }
      console.log(`Open Socket`)
    },
    onClose: () => {
      setSocketHasFailed(true)
      console.log(`On Close Socket`)
    },
    onReconnectStop: data => console.log(`On Socket Reconnect Stop - ${data}`),
    onError: () => {
      setSocketHasFailed(true)
    },
    onMessage: () => console.log(`On Socket Message Received`)
  })

  /**
   * Use Effect for API Gateway Web Sockets
   * Receiving events for Transactions and Carts
   * If Deliverect or external_order we transform Cart into Transactions
   * but first update it with the new data
   */
  useEffect(() => {
    if (lastJsonMessage?.message) {
      const { message } = lastJsonMessage

      switch (message.__t) {
        case 'Transaction':
          if (lastJsonMessage.options?.actions === 'convertToTransaction') {
            setCartToTransaction(message)
          } else {
            updateCart(message)
          }
          break
        case 'Cart':
          updateCart(message)
          break
      }
    }
    setScrollButtons()
  }, [lastJsonMessage, setCartToTransaction, setScrollButtons, updateCart])

  const handleSortByStatus = useCallback(statuses => {
    const status = statuses.reduce((prev, curr) => {
      return curr?.amount > prev?.amount ? curr : prev
    })

    let result = 0
    switch (true) {
      case status?.state === 'new':
      case status?.state === 'printed':
        result = 1
        break
      case status?.state === 'ready':
        result = 2
        break
      case status?.state === 'completed':
        result = 3
        break
    }

    return result
  }, [])

  const handleSortArrayByStatus = useCallback(
    (a, b) => {
      const aNumber = handleSortByStatus(a.status)
      const bNumber = handleSortByStatus(b.status)
      return aNumber > bNumber ? -1 : aNumber < bNumber ? 1 : 0
    },
    [handleSortByStatus]
  )

  /**
   * Sort cart items based on settings
   * @param {CartItems} items
   */
  const sortCartItems = useCallback(
    items => {
      const { show_categories_separator, sort_items_by_action } = props.settings

      // Check if sort by action and show categories is selected
      if (sort_items_by_action?.value && show_categories_separator?.value) {
        const categorizedProducts = []
        let currentCategory = []
        let sortedItems = []

        // Loop through the cart items and store the separated items in a 2 x 2 matrix
        items.forEach((item, index) => {
          // If index is 0 just assign currentCategory as an array with it and return
          if (index === 0) {
            currentCategory = [item]
            return
          }

          // Current items first category _id
          const currentItemCategory = item.product.categories[0]._id
          // Previous item's first category _id
          const prevItemCategory = items[index - 1].product.categories[0]._id
          // In case the categories are the same add the item in to the current category array
          if (currentItemCategory === prevItemCategory) {
            currentCategory.push(item)

            // If it is the last item push the array in to the matrix
            if (index === items.length - 1) {
              categorizedProducts.push(currentCategory)
            }
          } else {
            // If the items have different categories push the old ones it to the matrix array
            // and set currentCategory as an array with only the new item
            categorizedProducts.push(currentCategory)
            currentCategory = [item]
            // If it is the last item push the array in to the matrix
            if (index === items.length - 1) {
              categorizedProducts.push(currentCategory)
            }
          }
        })

        categorizedProducts.forEach(categorizedArray => {
          // Sort each array inside the matrix matrix
          const sorted = categorizedArray.sort(handleSortArrayByStatus)
          // Add all the items in to one array
          sortedItems = [...sortedItems, ...sorted]
        })
        // Return the sorted items
        return sortedItems
      }

      if (sort_items_by_action?.value && !show_categories_separator?.value) {
        return items.sort(handleSortArrayByStatus)
      }

      // If the settings are not active just return the items
      return items
    },
    [handleSortArrayByStatus, props.settings]
  )

  /**
   * Calculate the Cart List splitted using the categories set
   */
  const splitCarts = useCallback(
    (cart, i) => {
      if (cart.status === 'completed') {
        return
      }

      let categoryProductsFormatted = []

      const settingsKitchenCategories =
        props.device.settings?.web_settings?.kitchen_categories ?? []
      cart.items = sortCartItems(cart.items)

      let showTicket = false
      if (!settingsKitchenCategories.length) {
        categoryProductsFormatted = cart.items
        showTicket = true
      }

      for (const element of cart.items) {
        const item = element
        if (item.amount <= 0) {
          continue
        }
        item.status = item.status.filter(el => el.state !== 'removed')
        if (settingsKitchenCategories.length) {
          const catIsSet = item.product.categories.findIndex(
            category =>
              settingsKitchenCategories?.includes(category._id) ||
              (item.product.report_category?._id &&
                settingsKitchenCategories?.includes(
                  item.product.report_category._id
                ))
          )

          if (~catIsSet) {
            categoryProductsFormatted.push({ ...item })
            if (
              item.status.find(
                el =>
                  ['pending', 'printed', 'ready', 'preparing'].indexOf(
                    el.state
                  ) >= 0 && el.amount > 0
              )
            )
              showTicket = true
          }
        }
      }

      //Don't show ticket if no items for this category
      if (
        settingsKitchenCategories.length &&
        !categoryProductsFormatted.length
      ) {
        return
      }

      //Don't show ticket if no items for this KDS in the right state
      if (!showTicket) {
        return
      }

      return (
        <Cart
          cart={cart}
          device={props.device}
          key={`${i}_${cart._id || cart.id}`}
          settings={props.settings}
          itemPerCategory={categoryProductsFormatted}
          currentEmbeddedDevice={props.app.currentEmbeddedDevice}
        />
      )
    },
    [
      props.app.currentEmbeddedDevice,
      props.device,
      props.settings,
      sortCartItems
    ]
  )

  const handleStatusToNumber = status => {
    let result = 0
    switch (true) {
      case status === 'preparing':
        result = 1
        break
      case status === 'ready':
        result = 2
        break
      case status === 'completed':
        result = 3
        break
    }

    return result
  }

  const handleShortList = useCallback((a, b) => {
    const aNumber = handleStatusToNumber(a.status)
    const bNumber = handleStatusToNumber(b.status)
    return aNumber > bNumber ? -1 : aNumber < bNumber ? 1 : 0
  }, [])

  const renderItems = useCallback(() => {
    const render = []
    const listSorted = props.carts.showingList.sort(CartUtils.compareOrderTime)

    if (listSorted.length) {
      if (props.settings.move_processing_ticket_first?.value) {
        listSorted.sort(handleShortList)
      }

      for (let i = 0; i < listSorted.length; i++) {
        const cart = listSorted[i]
        const renderCart = splitCarts(cart, i)
        if (renderCart) {
          render.push(renderCart)
        }
      }
    }

    return render
  }, [
    handleShortList,
    props.carts.showingList,
    props.settings.move_processing_ticket_first?.value,
    splitCarts
  ])

  const scrollRightStart = useCallback(() => {
    const orderContainer = document.getElementById('grid-wrapper-main')
    const scrollRightIntervalLoc = setInterval(function () {
      if (
        Math.ceil(orderContainer.scrollLeft) + orderContainer.offsetWidth >=
        orderContainer.scrollWidth
      ) {
        clearInterval(scrollRightIntervalLoc)
      } else {
        orderContainer.scrollLeft += 20
      }
    }, 20)
    setScrollRightInterval(scrollRightIntervalLoc)
  }, [])

  const scrollRightEnd = useCallback(() => {
    if (scrollRightInterval) {
      clearInterval(scrollRightInterval)
    }

    setScrollButtons()
  }, [scrollRightInterval, setScrollButtons])

  const scrollLeftStart = useCallback(() => {
    const orderContainer = document.getElementById('grid-wrapper-main')
    const scrollLeftIntervalLoc = setInterval(function () {
      if (orderContainer.scrollLeft === 0) {
        clearInterval(scrollLeftIntervalLoc)
      } else {
        orderContainer.scrollLeft -= 20
      }
    }, 20)
    setScrollLeftInterval(scrollLeftIntervalLoc)
  }, [])

  const scrollLeftEnd = useCallback(() => {
    if (scrollLeftInterval) {
      clearInterval(scrollLeftInterval)
    }
    setScrollButtons()
  }, [scrollLeftInterval, setScrollButtons])

  const ordersContainerScrollEvent = useCallback(() => {
    setScrollButtons()
  }, [setScrollButtons])

  return (
    <div
      id={`main`}
      className={renderItems().length ? 'withorders' : 'withoutorders'}>
      {props.carts.playTicketSound && (
        <div style={{ height: 0, width: 0 }}>
          <ReactAudioPlayer src={TicketSound} autoPlay controls />
        </div>
      )}
      {renderItems().length ? (
        <div id="grid-grouped-wrapper">
          <div
            onScroll={ordersContainerScrollEvent}
            id={
              props.list_horizontal.value
                ? 'grid-wrapper-main'
                : 'grid-wrapper-main-horizontal'
            }>
            {renderItems()}
          </div>
          {props.settings.enable_groups.value && (
            <div id="grouped-wrapper">
              <Grouped />
            </div>
          )}
        </div>
      ) : (
        <div className="no-orders">
          <p>{translate('no_open_orders')}</p>
          <i onClick={() => refreshOrders()} className="material-icons">
            gradient
          </i>
          <p>{translate('click_refresh')}</p>
        </div>
      )}
      <span
        onMouseDown={scrollRightStart}
        onMouseUp={scrollRightEnd}
        className={`${
          canScrollRight ? 'visible' : 'hidden'
        } scroll-arrow material-icons`}>
        &#xe5e1;
      </span>
      <span
        onMouseDown={scrollLeftStart}
        onMouseUp={scrollLeftEnd}
        className={`${
          canScrollLeft ? 'visible' : 'hidden'
        } scroll-arrow-left material-icons`}>
        &#xe5e0;
      </span>
    </div>
  )
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainPage)
)
