import { memo, useEffect, useState, useRef, useMemo } from 'react'
import { connect } from 'react-redux'

import { AppInstances } from '../utils/CountrSdk'
import CartUtils from '../utils/CartUtils'

import { withRouter } from '../wrappers/routeWrappers'
import { setCurrentGroups } from '../store/actions/app'
import Group from './Groups/Group'

import './../styles/Grouped.scss'

const mapStateToProps = state => {
  return {
    app: state.app,
    carts: state.carts,
    device: state.device.device,
    categories: state.categories
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCurrentGroups: groups => dispatch(setCurrentGroups(groups))
  }
}

const Grouped = memo(props => {
  const [coloredGroups, setColoredGroups] = useState([])

  const colorRef = useRef({})

  const settingsKitchenCategories = useMemo(
    () => props.device?.settings?.web_settings?.kitchen_categories || [],
    [props.device?.settings?.web_settings?.kitchen_categories]
  )

  const { showingList } = props.carts

  useEffect(() => {
    const preparingTogetherGroups = []
    const setCurrentGroups = props.setCurrentGroups

    if (showingList?.length) {
      AppInstances.getCountrSdk().then(countr => {
        showingList.forEach(cart => {
          const parseAggregatedItems = cart.items.filter(item => {
            const catIsSet = item.product.categories.findIndex(
              category =>
                settingsKitchenCategories.includes(category._id) ||
                (item.product.report_category?._id &&
                  settingsKitchenCategories?.includes(
                    item.product.report_category._id
                  ))
            )

            const _status = item.status.filter(st => {
              if (
                st.amount > 0 &&
                (st.state === 'pending' ||
                  st.state === 'printed' ||
                  st.state === 'preparing' ||
                  st.state === 'ready')
              ) {
                return st
              }
            })

            if (_status.length) {
              if (!settingsKitchenCategories.length || ~catIsSet) {
                return item
              }
            }
          })

          for (const element of parseAggregatedItems) {
            const item = element
            const formattedProductId = countr.retrieveCartEntryId(
              item.product,
              true
            )
            if (!preparingTogetherGroups[formattedProductId]) {
              preparingTogetherGroups[formattedProductId] = []
            }

            preparingTogetherGroups[formattedProductId].push({
              cartId: cart._id,
              ...item,
              created_at: +new Date()
            })
          }
        })

        if (!Object.keys(preparingTogetherGroups).length) {
          setCurrentGroups({})
        } else {
          setCurrentGroups(preparingTogetherGroups)
        }

        calculateGroups(preparingTogetherGroups)
      })
    }
  }, [showingList, settingsKitchenCategories, props.setCurrentGroups])

  /**
   * Generate Groups with all data needed to list
   * @param  {} groups
   */
  async function calculateGroups(groups) {
    const groupsObjects = []

    const countr = await AppInstances.getCountrSdk()

    for (const i in groups) {
      if (Object.hasOwn(groups, i)) {
        const group = groups[i]

        const notes = []
        const _addons = {}

        for (const element of group) {
          const item = element
          const note = item.note ? item.note.trim() : null
          if (note?.length) {
            notes.push({
              note,
              qty: item.amount
            })
          }
          //
          if (item.product?.current_addons?.length) {
            item.product.current_addons.forEach(addon => {
              if (!_addons[addon._id]) {
                _addons[addon._id] = []
              }

              _addons[addon._id].push(addon)
            })
          }
        }

        const product = group[0].product

        if (!product.options?.auto_created) {
          groupsObjects.push({
            notes,
            addons: _addons,
            qty: group.reduce((acc, crr) => (acc += crr.amount), 0),
            name: CartUtils.getItemName(product),
            color: product.color,
            image: product.image,
            media: product.media,
            id: countr.retrieveCartEntryId(product, true),
            orders: group.map(info => {
              return {
                id: info.cartId,
                order_qty: info.amount
              }
            })
          })
        }
      }
    }

    if (groupsObjects.length) {
      // Set the group to the local state for rendering list
      setColoredGroups(groupsObjects)
    } else {
      // Or empty as no groups
      setColoredGroups([])
    }
  }

  return (
    <div className="grouped" id="grouped">
      {coloredGroups
        .toSorted((a, b) => a.name.localeCompare(b.name))
        .map(group => {
          return (
            <Group
              key={`group_${group.id}`}
              group={group}
              colorReferences={colorRef}
            />
          )
        })}
    </div>
  )
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Grouped))
