import { memo, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'

import { withRouter } from '../wrappers/routeWrappers'

import { movePostponedToList } from '../store/actions/carts'

import Clock from './Shared/Clock'
import OrderCounter from './Shared/OrderCounter'

import countrLogo from '../assets/countr_logo_white.png'
import './../styles/Header.scss'

const mapStateToProps = state => {
  return {
    carts: state.carts,
    app: state.app,
    settings: state.settings,
    store: state.store.store
  }
}

const mapDispatchToProps = dispatch => {
  return {
    movePostponedToList: order => dispatch(movePostponedToList(order))
  }
}

const openNav = () => {
  document.getElementById('countr-menu').style.left = '0px'
  document.getElementById('sidenav-backdrop').style.display = 'block'
}

const shouldShowOrderCounter = () => {
  return window.location.pathname === '/main' ? true : false
}

const Header = memo(
  function Header(props) {
    // const [toggleGroup, setToggleGroup] = useState(false)

    const groupedEnabled = useSelector(state => state.app.groupedEnabled)

    // const dispatch = useDispatch()
    // const toggleGroupedEnabled = toggleGroup =>
    //   dispatch({ type: 'SET_GROUPED_DEVICE', payload: toggleGroup })

    useEffect(() => {
      const groupMenu = document.getElementById('grouped-wrapper')
      if (groupMenu) {
        // groupMenu.style.display =
        //   groupedEnabled && props.carts.showingList.length ? 'block' : 'none' //GROUP VISIBILITY
        groupMenu.style.display = 'block'
      }
    }, [groupedEnabled, props.carts.showingList.length])

    useEffect(() => {
      const groupMenu = document.getElementById('grouped-wrapper')
      if (groupMenu) {
        // groupMenu.style.display = groupedEnabled ? 'block' : 'none'  //GROUP VISIBILITY
        groupMenu.style.display = 'block'
      }
    }, [groupedEnabled])

    // const shouldToggleGroup = () => {
    //   toggleGroupedEnabled(!toggleGroup)
    //   setToggleGroup(!toggleGroup)
    // }

    let storeLogo = countrLogo
    if (props.store.store_logo && props.store.store_logo.length) {
      storeLogo = props.store.store_logo
    }

    return (
      <header className="fixed-header">
        <div className="filter-icons-header">
          {!shouldShowOrderCounter() ? (
            <div className="logo">
              <img
                onClick={openNav}
                alt="icon-logo"
                height="100"
                src={storeLogo}
              />
            </div>
          ) : (
            <div className="settings-icon">
              <i onClick={openNav} className="material-icons">
                settings_applications
              </i>
            </div>
          )}

          {shouldShowOrderCounter() && <OrderCounter props={props} />}
          <Clock />

          {/* {props.settings.enable_groups.value && //GROUP VISIBILITY
            !!props.carts.showingList.length && (
              <div className="group-orders-btn" onClick={shouldToggleGroup}>
                <span
                  className={`icon-group ${
                    toggleGroup ? 'active' : ''
                  }`}></span>
              </div>
            )} */}
        </div>
      </header>
    )
  },
  (prevProps, nextProps) =>
    prevProps.carts.showingList.length !== nextProps.carts.showingList.length &&
    prevProps.carts.filter !== nextProps.carts.filter
)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
