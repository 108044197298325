import { memo } from 'react'
import { useSelector } from 'react-redux'

import Loader from './components/Loader'
import Header from './components/Header'
import Routes from './Routes/index'
import ToastMessage from './components/ToastMessage'
import Menu from './components/Menu'

const App = memo(function App() {
  const app = useSelector(state => state.app)
  const storeId = useSelector(state => state.store.id)
  const isAuth = !!app.user && !!Object.keys(app.user).length

  return (
    <div className="app">
      {app.isLoading && <Loader />}
      {!!isAuth && <Menu />}
      {!!isAuth && !!storeId && <Header title={process.env.REACT_APP_NAME} />}
      <Routes />
      {app.showToast && <ToastMessage />}
    </div>
  )
})

export default App
