/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
const esLang = {
  student: 'alumno',
  id_check_alert: 'Cliente: {{customer}}. Verifique las siguientes identificaciones de los clientes: {{id}}.',
  id_check: 'Verificación de identificación',
  cart: 'Cesta',
  item: 'Artículo',
  items: 'Artículos',
  subtotal: 'Subtotal',
  discount: 'Descuento',
  total: 'Total',
  pay: 'Pagar',
  card: 'Tarjeta',
  cash: 'Efectivo (metálico)',
  invoice: 'Factura',
  giftcard: 'Tarjeta de regalo',
  countr_giftcard: 'Countr Tarjeta de regalo',
  seqr: 'SEQR',
  bitpay: 'Bitpay',
  payment_method: 'Métodos de pago',
  cash_received: 'Cantidad recibida',
  or_enter_amount: 'Anote la cantidad',
  payment_succeeded: 'Pago con éxito',
  change_due: 'Cambio debido',
  total_paid: 'Total pagado',
  send_receipt: 'Enviar recibo',
  send_invoice: 'Enviar factura',
  send: 'Enviar',
  delete: 'Borrar',
  new_sale: 'Nueva venta',
  custom_price: 'Precio personalizado',
  item_description: 'Descripción del artículo',
  products: 'Productos',
  transactions: 'Transacciones',
  reports: 'Informe',
  report: 'Informes',
  settings: 'Ajustes',
  sign_out: 'Desconectarse',
  receipt: 'Recibo',
  refund: 'Reembolso',
  home: 'Pagina de inicio',
  clear: 'Borrar / Vacía',
  add: 'Añadir',
  sign_up: 'Inscribirse',
  sign_up_email: 'Inscribirse con correo electrónico',
  sign_in: 'Ingresar',
  company_name: 'Nombre de empresa',
  merchant_name: 'Persona de contacto',
  email_address: 'Correo electrónico',
  phone_number: 'Número de teléfono válido',
  password: 'Contraseña',
  confirm_password: 'Confirmar contraseña',
  forgot_password_question: '¿Olvidó su contraseña?',
  next: 'Siguiente',
  demo: 'Demostración',
  skip: 'Omitir',
  skip_registration: 'Omitir el registro',
  take_a_tour: 'Hacer un recorrido',
  items_returned: 'Artículos devueltos',
  wrong_billing: 'Facturación incorrecta',
  order_cancelled: 'Orden cancelada',
  other: 'Otros',
  enter_valid_amount: 'Introducir una cantidad válida',
  invalid_merchant_name: 'Persona de contacto no válido',
  invalid_business_name: 'Nombre de empresa no válido',
  invalid_email_address: 'Dirección de correo electrónico no válida',
  invalid_phone_number: 'Numero de teléfono invalido',
  invalid_password: 'Contraseña invalida - debe tener al menos 8 caracteres',
  password_mismatch: 'Contraseñas no coinciden',
  coming_soon: 'Próximamente...',
  to_use_signup: 'Por favor, regístrese para usar esta función',
  login_error: 'Error de inicio de sesión',
  signup_error: 'Error de registro',
  transaction_history_empty: 'Historial de transacciones',
  categories: 'Categorías',
  all_products: 'Todos los productos',
  search_product: 'Buscar el/un producto',
  reissue_receipt: 'Vuelva a emitir el/un recibo',
  print_receipt: 'Imprimir el/un recibo',
  or_send_receipt: 'O enviar el/un recibo',
  save: 'Guardar',
  saved: 'Guardado',
  search: 'Buscar',
  custom_item: 'Artículo personalizado',
  submit: 'Enviar',
  submitted: 'Enviado',
  note: 'Nota',
  message: 'Mensaje',
  amount: 'Cantidad',
  number: 'Número',
  taxes: 'Impuestos',
  tax: 'Impuesto',
  enter_valid_number: 'Por favor, ingrese un número valido',
  enter_valid_text: 'Por favor, ingrese el texto valido',
  enter_valid_phone: 'Por favor, ingrese un numero telefónico valido',
  enter_valid_email: 'Por favor, ingrese una dirección de correo electrónico válida',
  feature_not_in_demo: 'Esta función no está disponible en modo de demostración',
  provide_refund_reason: 'Debe seleccionar una razón de la reembolso',
  provide_valid_refund_amount: 'Debe proporcionar una cantidad del reembolsos válida',
  refund_exceed_refundable: 'Cantidad del reembolso supera el importe máximo reembolso en este recibo',
  refund_saved: 'Reembolso guardado',
  or_customize_discount: 'O descuento personalizado',
  enter_percentage: 'Introduzca el porcentaje',
  manual_card_payment: 'Pago de tarjeta manual',
  did_card_payment_succeed: '¿Logró el pago?',
  manual_card_refund: 'Reembolso de tarjeta manual',
  did_card_refund_succeed: '¿Logró el reembolso?',
  giftcard_refund: 'Reembolso de tarjeta regalo',
  did_giftcard_refund_succeed: '¿Nueva tarjeta de regalo emitida?',
  yes: 'Sí',
  no: 'No',
  general: 'General',
  printer: 'Impresora',
  barcode_scanner: 'Escáner de código de barras',
  payment: 'Pago',
  profile: 'Perfil',
  currency: 'Moneda',
  printing_receipt: 'Imprimir el/un recibo...',
  enter_amount: 'Anote la cantidad',
  expected_change: 'Cambio esperado',
  still_remaining: 'Aún quedan',
  issue_invoice: 'Emitir la factura',
  URL_pricing: 'http://www.countrhq.com/#pricing',
  address: 'Dirección',
  address_continued: 'Dirección (Opcional)',
  city: 'Ciudad',
  zip: 'Código postal',
  country: 'País',
  country_iso: 'País en formato ISO 3166-2 (p. Ej., "Nl", "de", "gb")',
  invalid_address: 'Dirección inválida / vacía',
  invalid_city: 'Ciudad inválida / vacía',
  invalid_country: 'País inválido / vacío',
  invalid_ecommerce: 'Ninguna plataforma de comercio electrónico elegido',
  signup_error_contact_support: 'Error de registro, por favor póngase en contacto con support@countrhq.com',
  open_orders: 'Órdenes abiertas',
  order: 'Orden',
  enter_weight_g: 'Introducir el peso (g)',
  search_scanner: 'Buscar el escáner Socket CHS',
  order_settings: 'Ajustes de orden',
  order_amount: 'Número de órdenes abiertas disponibles',
  receipts: 'Recibos',
  print_receipts: 'Automáticamente imprimir recibos',
  open_cash_drawer: 'Automáticamente abrir cajón de dinero',
  backup: 'Copia de seguridad',
  email_backup: 'Copia de seguridad de correo electrónico',
  server_sync: 'Sincronización del servidor',
  external_payment: 'Otros terminal de la tarjeta',
  search_printer: 'Buscar la impresora',
  sms_successfully_sent: 'SMS enviado con éxito',
  error_sending_sms: 'Error al enviar el SMS, por favor intente de nuevo más tarde',
  email_successfully_sent: 'Correo electrónico enviado con éxito',
  error_sending_email: 'Error al enviar el correo electrónico, por favor intente de nuevo más tarde',
  transaction: 'Transacción',
  logging_in: 'Inicio de sesión...',
  downloading_products: 'Descarga de productos...',
  add_to_cart: 'Añadir a la cesta',
  invalid_price: 'Precio inválido',
  invalid_discount: 'Descuento inválido',
  invalid_new_price: 'Nuevo precio inválido - debe ser menor que precio original',
  invalid_amount: 'Cantidad inválido',
  edit_price: 'Modificar el precio',
  discount_perc: 'Descuento',
  quantity: 'Cantidad',
  remove: 'Eliminar',
  sales_info: 'Información de ventas',
  summary: 'Resumen',
  refunded_information: 'Información de reembolso',
  total_sale: 'Ventas totales',
  total_amount_refunded: 'Total reembolsado',
  remaining: 'Restante',
  sold_by: 'Vendido por',
  source_receipt_number: 'Número de recibo fuente',
  issued_date: 'Fecha de emisión',
  issued_time: 'Tiempo de emisión',
  refunded_by: 'Devuelto por',
  reason: 'Razón',
  receipt_number: 'Número de recibo',
  refunded_date: 'Fecha de reembolso',
  refunded_time: 'Tiempo de reembolso',
  first_name: 'Nombre',
  last_name: 'Apellido',
  shop_category: 'Categoría de tienda',
  choose_country: 'Elija un país...',
  you_will_receive_email: 'Enhorabuena, su registro es completa',
  wait_email: 'Ahora recibirá un correo electrónico con más instrucciones sobre cómo conectar Countr a su plataforma de comercio electrónico',
  open_dashboard: 'Ahora puede gestionar su cuenta y crear productos en su dashboard de Countr personal',
  by_signing_up: 'Al suscribirse acepto los',
  terms_and_conditions: 'Términos y Condiciones',
  and: 'y',
  privacy_policy: 'Política de Privacidad',
  refund_items: 'Reembolso de los artículos',
  forgot_password: '¿Olvidaste la contraseña?',
  register_device: 'Registrar un dispositivo',
  device_not_registered: 'Este dispositivo no está registrado',
  want_to_add_to_account: '¿Registrarse ahora?',
  if_ios_previously_registered: 'Si ha reinstalado recientemente la aplicación, tendrá que registrar su tableta otra vez',
  new_device_info: 'En la siguiente pantalla se puede vincular su tableta a una de sus tiendas',
  not_now: 'Ahora no',
  proceed: 'Continúe',
  more_info_click: '¿Más información? Haz clic aquí',
  your_detail: 'Sus detalles',
  vat_no: 'Número de Valor Agregado',
  billing_details: 'Detalles de facturación',
  add_store: 'Añadir tienda',
  or_add_to_existing_store: 'O añadir a tienda existente',
  add_new_store: 'Añadir nueva tienda',
  store_information: 'Información de la tienda',
  store_name: 'Nombre de la tienda',
  go_to_web_dashboard: 'Ir al dashboard',
  error_create_account: 'Correo de electrónico ya registrado.',
  error_creation_error: 'Ocurrió un error durante el registro.',
  error_wrong_phone_number: 'Por favor, ingrese un numero telefónico válido.',
  error_try_again: 'Problema de conexión. Por favor, inténtalo de nuevo.',
  error_from_the_server: 'Incapaz de establecer una conexión con el servidor.',
  error_update_cc_info: 'No puede actualizar la información de tarjeta de crédito.',
  error_add_device: 'No puede agregar el dispositivo a la tienda actual.',
  error_add_store: 'No puede agregar nueva tienda.',
  are_not_valid: 'no son válidas',
  is_not_valid: 'no es válido',
  are_empty: 'están vacías',
  is_empty: 'esta vacío',
  are_not_selected: 'no se seleccionan',
  is_not_selected: 'no se ha seleccionado',
  bill: 'Factura',
  URL_dashboard: 'https://backoffice.countrhq.com/',
  URL_support: 'mailto:support@countrhq.com',
  URL_forgot_password: 'https://backoffice.countrhq.com/#/auth/forgot',
  close: 'Cerrar',
  no_product_found: 'No productos encontrados',
  please_go_to_dashboard: 'Para añadir productos, visite https://backoffice.countrhq.com',
  select_interface: 'Seleccionar interfaz',
  refund_all: 'Devolver la totalidad',
  purchased_items: 'Artículos comprados',
  refundable: 'Reembolsable',
  to_be_refunded: 'Para ser reembolsado',
  out_of: 'Fuera de',
  enter_a_printer_name: 'Introduzca un nombre de impresora',
  cart_is_empty: 'El carrito esta vacío',
  searching_printers: 'Búsqueda de impresoras...',
  no_printers_found: 'No hay impresoras encontrados.',
  total_due: 'Total debido',
  payment_not_successful: 'El pago no exitosa.',
  error_processing_payment: 'Error al procesar el pago.',
  purchase: 'Compra',
  transaction_aborted: 'Transacción abortada',
  authorization_error: 'Error de la autorización',
  authorization_ok: 'Autorización aceptada',
  transaction_cancelled: 'Transacción cancelada',
  clearance_error: 'Habilitación de seguridad error por el procesador',
  clearance_ok: 'Habilitación de seguridad confirmó bien por el procesador',
  transaction_declined: 'Transacción rehusada',
  transaction_complete: 'Transacción completa',
  initiating: 'Iniciación',
  application_selected: 'Esperando la entrada de terminal de pago',
  scheduled_for_clearance: 'Calendario para offline habilitación de seguridad por el procesador',
  terminal_error: 'Error de terminal',
  unknown_status: 'Estado desconocido',
  waiting_for_clearance: 'Esperando por confirmación de la habilitación de seguridad offline por el procesador',
  amount_exceeded: 'Cantidad máxima superó',
  authorization_timeout: 'Un tiempo de espera de autorización',
  batch_processing_failed: 'El procesamiento por lotes fracasó',
  cancelled: 'Cancelado',
  card_blocked: 'La tarjeta está bloqueada / tarjeta bloqueada',
  card_invalid: 'La tarjeta no es válida / inválida tarjeta',
  card_declined: 'La tarjeta es rechazada/ tarjeta declinó',
  transaction_failed: 'Transacción fallida',
  insufficient_funds: 'Fondos insuficientes',
  terminal_not_paired: 'Terminal no emparejado',
  pin_incorrect_last_retry: 'PIN incorrecto - el último intento',
  pin_incorrect_retry: 'PIN incorrecto - vuelva a intentarlo',
  printer_busy: 'Impresora ocupada',
  printer_error: 'Error de impresora',
  printer_not_present: 'Impresora no presente / ausente impresora',
  printer_out_of_paper: 'Impresora sin papel',
  success: 'Éxito',
  system_error: 'Error del sistema',
  transaction_not_found: 'Transacción no encontrado',
  unknown_device: 'Dispositivo desconocido',
  apply_discount: 'Aplicar descuento',
  seqr_id: 'SEQR Identificación del comerciante',
  seqr_password: 'SEQR Contraseña del comerciante',
  bitpay_api_token: 'Bitpay token de la API',
  registration_successful: 'Terminal está registrado con éxito',
  unregistration_successful: 'Terminal de éxito dado de baja',
  payment_init_not_successful: 'El pago no inicializado - compruebe la conexión de red.',
  always_show_addons: 'Mostrar siempre complementos',
  show_product_image: 'Mostrar imagen del producto',
  settings_connected_printers: 'Impresoras conectadas',
  settings_add_new_printer: 'Añadir nueva impresora',
  wifi_lan: 'WiFi or LAN',
  bluetooth: 'Bluetooth',
  usb: 'USB',
  paired_barcode_scanners: 'Escáner de código de barras conectados',
  account: 'Cuenta',
  refresh_products: 'Productos actualizar',
  server_synchronisation: 'La sincronización del servidor',
  sales: 'Venta',
  today: 'Hoy',
  refunds: 'Reembolsos',
  product: 'Producto',
  product_by_category: 'Productos por categoría',
  see_categories_details: 'Ver detalles categorías',
  open_orders_finish_payment_or_empty: 'Hay órdenes abiertas.',
  name: 'Nombre',
  loading_products: 'Cargando productos',
  you_should_select_at_least_one_item: 'Debe seleccionar al menos un elemento',
  select_card_provider: 'Seleccionar un proveedor de la tarjeta',
  user_cancelled: 'Pago cancelado por el cliente',
  card_payment: 'Pago con tarjeta',
  manage_products: 'Manejo de productos',
  edit_mode: 'Modo de edición',
  lock: 'Bloquear',
  loyalty: 'Lealtad',
  account_card: 'Tarjeta de la cuenta',
  account_lookup: 'Búsqueda de cuenta',
  Activate: 'Activar',
  add_anonymous_customer: 'Añadir al cliente anónimo',
  add_customer: 'Añadir al cliente',
  add_customer_to_cart: 'Seleccione al cliente',
  add_new_account: 'Añadir nueva cuenta',
  add_new_customer: 'Añadir nuevo cliente',
  add_note_hint: 'Añadir una nota',
  add_tax_to_price: 'Los precios no incluyen el impuesto',
  add_tip: 'Añadir propina',
  add_to_store: 'Añadir una tienda',
  addons: 'Complementos',
  admin_pin: 'PIN del administrador',
  admin_pin_saved: 'Nuevo PIN del administrador guardado',
  admin_pin_should_be: 'PIN del administrador debe estar entre 4-6 dígitos',
  aid: 'AID',
  aidCard: 'aidCard',
  allow_cart_discounts: 'Permitir descuentos sobre el nivel de los carros de la compra',
  allow_custom_cart_discounts: 'Permitir descuentos personalizados a nivel de los carros de la compra',
  allow_custom_items: 'Permitir los artículos de encargo',
  allow_product_discounts: 'Permitir descuentos sobre el nivel de los productos',
  allow_custom_product_discounts: 'Permitir descuentos personalizados a nivel de los productos',
  amount_hint: '999',
  app_version: 'Versión del Countr',
  applicationLabel: 'applicationLabel',
  applicationName: 'applicationName',
  applicationPreferredName: 'applicationPreferredName',
  appName: 'appName',
  ask_tips: 'Sugerir cantidad de propina',
  authorizationCode: 'authorizationCode',
  available_points: 'Puntos disponibles',
  available_rewards: 'Recompensas disponibles',
  back: 'Espalda',
  barcode_scanner_disconnected: 'Su código de barras está conectado pero podría estar apagado. Tratar de encenderlo.',
  barcode_scanner_not_found: 'No encontrado escáner',
  barcode_scanner_not_found_desc: 'Conectar un escáner de código de barras nuevo - activar Bluetooth en la configuración del dispositivo y la búsqueda de un escáner más adelante',
  bunq: 'bunq',
  bunq_admin_approval: 'Un administrador de bunq revisará y aprobará la caja registradora antes de que pueda ser utilizado.',
  bunq_instructions: '1) Abre la aplicación bunq e introduzca el PIN (si es necesario)\n2) Elija \'QR\' / \'Scan QR code\' de la pantalla principal\n3) Escanear el código en pantalla\n4) Añadir una propina si lo desea\n5) que confirme el pago',
  can_only_refund_entire_amount: 'Sólo se puede reembolsar el importe total a través de {{payment_method}}',
  cancel: 'Cancelar',
  cancel_payment: 'Cancelar el pago',
  cannot_pay_later_more_than_remaining_value: 'No se puede pagar más que el valor restante ({{amount}}) con método ‘Pay Later’.',
  cannot_refund_transaction_with_partial_postponed_payments: 'Lo sentimos, esta transacción contiene uno o más pagos parciales aplazados, paga la transacción primera o reembolso todo',
  cant_find_products: 'No se puede encontrar todos los productos en la aplicación? Utilice el cuadro de búsqueda arriba o un escáner de código de barras para encontrarlos.',
  card_scheme: 'Sistema de tarjetas',
  cardBrand: 'cardBrand',
  cardExpiryMonth: 'Mes de expiración de la tarjeta MM',
  cardExpiryYear: 'Año de expiración de la tarjeta AA',
  cardType: 'cardType',
  cart_is_frozen_finish_payment: 'El carrito está congelado. Finalizar el pago antes de realizar cualquier acción.',
  cash_difference: 'Diferencia',
  cash_drawer: 'Cajón de dinero',
  cash_in: 'Entrada de efectivo',
  cash_in_out: 'Entrada de efectivo / Salida de efectivo',
  cash_out: 'Salida de efectivo',
  change: 'Cambio',
  change_currency: 'Cambiar la moneda',
  change_given: 'Cambio dado',
  change_open_orders_amount: 'Cambiar la cantidad de pedidos abiertos',
  check_location_settings: 'Compruebe la configuración de ubicación',
  check_network: 'Compruebe la conexión a la red',
  choose_category: 'Elija una categoría de empresa ...',
  choose_mobile_provider: 'Existen múltiples proveedores activadas - por favor elige uno',
  clear_settings: 'Borrar los ajustes',
  clear_settings_successful: 'Borrado los ajustes',
  clear_settings_warning: 'La desactivación de estas configuraciones se restablecerá la información de conexión. Haga clic en Confirmar para continuar.',
  client_ticket: 'Ticket cliente',
  close_shift: 'Cerrar la caja registradora',
  company_address: 'Dirección de Negocios',
  company_registration_no: 'Número de registro de la compañía',
  completed_payments: 'Pagos completados',
  confirm: 'Confirmar',
  confirm_cash_payment: 'Confirmar el monto de pago en efectivo',
  confirm_new_currency: 'Confirmar el cambio de divisa',
  confirm_open_orders_amount_change: 'Confirmar el cambio de la cantidad de pedidos pendientes?',
  confirm_payment: 'Confirmar pago',
  confirm_with_change: 'Confirmar con el cambio',
  confirm_with_tip: 'Confirmar con propina',
  connect_barcode: 'Conecte el escáner de código de barras (Socket CHS)',
  connect_to_webshop: '¿Quieres conectar con su tienda online?',
  connected_printer: 'impresoras conectadas',
  contact_information: 'Impresoras conectadas',
  contact_us: 'support@countrhq.com',
  create_product: 'Crear productos',
  currently_trial_mode_transaction_wiped_visit_dashboard: 'El punto de venta está en Modo de Prueba. Sus transacciones serán eliminados cada cuatro horas.',
  currently_trial_mode_visit_dashboard: 'El punto de venta está en Modo de Prueba',
  customer: 'Cliente',
  customer_card: 'Tarjeta de cliente',
  customer_info: 'Información del cliente',
  customer_instructions: 'Instrucciones del cliente',
  customer_lookup: 'Mira al cliente',
  customer_lookup_error: 'Error del servidor a buscar al cliente',
  customer_name: 'Cliente',
  customer_not_valid: 'Cliente no válido',
  customer_receipt: 'Recibo del cliente',
  customer_since: 'Cliente desde',
  Date: 'Dato',
  deregister_terminal: 'De-registro de terminales',
  description_mandatory: 'Una descripción es obligatorio para las transacciones entrada de efectivo y salida de efectivo',
  device_locked: 'Dispositivo está bloqueado',
  device_locked_enter_pin: 'Este dispositivo está bloqueado. \nPor favor, introduzca su PIN de administrador para desbloquearlo',
  did_cheque_refund_succeed: 'Cheque devuelto al cliente?',
  did_mobile_payment_succeed: 'Tuvo éxito pagos móviles?',
  did_mobile_refund_succeed: 'Tuvo éxito restitución móvil?',
  direct_connection: 'Directamente conectado',
  disabled_product: 'Producto discapacitados',
  discount_currency: 'Descuento ({{currency}})',
  do_you_want_remove_payment: '¿Quieres eliminar el pago?',
  download_adyen_app: 'Descargar Adyen App',
  download_izettle_app: 'Descargar iZettle App',
  download_payleven_app: 'Descargar Payleven App',
  download_sumup_app: 'Descargar Sumup App',
  download_yodo_app: 'Descargar aplicación Yodo Rocket',
  downloading_categories: 'Descarga de productos….',
  downloading_taxes: 'Descarga de los impuestos…',
  downloading_transactions: 'Descargar transacciones',
  ecommerce_none: 'Voy a crear productos a mí mismo',
  ecrId: 'ecrId',
  eft_transaction_code: 'EFTTxnID',
  email_invoice: 'Facturas por correo electrónico',
  employee_name: 'Empleado',
  employee_pin: 'PIN empleado',
  empty_cart: 'Carro vacío',
  enable_staff_pin: 'Permitir al personal de entrada de PIN',
  enter_a_valid_search_value: 'Introduzca un valor de búsqueda válida',
  enter_api_key: 'Ingrese su clave de API',
  enter_base_url: 'Introducir URL (incluyendo "http: //")',
  enter_merchant_auth_code_payconiq: 'Introduzca su header Payconiq Autorización',
  enter_merchant_iban_payconiq: 'Introduzca su domicilio IBAN-Payconiq',
  enter_merchant_id_payconiq: 'Introduzca su identificación del comerciante de Payconiq',
  enter_order_name: 'Introduzca el nombre del pedido',
  enter_reseller_id: 'Introduzca su ID de distribuidor',
  enter_user_number: 'Introduzca su número de usuario',
  enter_user_password: 'Introduzca su contraseña de usuario',
  error_adding_device: 'Se ha producido un error durante el registro del dispositivo.',
  error_refreshing_merchant: 'Error al recuperar información de la cuenta.',
  error_retrieve_categories: 'Error al recuperar categorías. \nPor favor, compruebe su conexión a Internet y vuelve a intentarlo',
  error_retrieve_products: 'Error al recuperar productos. \nPor favor, compruebe su conexión a Internet y vuelve a intentarlo',
  error_retrieve_taxes: 'Error al recuperar los impuestos. \nPor favor, compruebe su conexión a Internet y vuelve a intentarlo',
  error_while_retrieving_results: 'Error al obtener resultados',
  errorCode: 'errorCode',
  errorMessage: 'errorMessage',
  ersReference: 'ersReference',
  excess_amount: 'Cantidad excesiva',
  expected_amount: 'Cantidad esperada',
  expected_change_gratuity: 'Cambio o propina esperada',
  external_url: 'URL externa',
  failed_cancel: 'No se pudo cancelar la transacción',
  feature_only_android: 'Esta función sólo está disponible para Android',
  find_terminal: 'Búsqueda de terminales',
  for_security_enter_password: 'Por razones de seguridad, por favor, vuelva a introducir la contraseña',
  for_this_device: 'Para este dispositivo sólo',
  for_this_store: 'Para este tienda sólo',
  four_inch: '4 pulgadas',
  generate_keys: 'Generar claves',
  generating_keypair: 'Generar un par de claves ... \nEsto puede tardar varios minutos',
  generating_reports: 'Generación de informes ...',
  generic_usb: 'USB genérico',
  get_your_free_pos: 'Obtener su punto de venta en www.countrhq.com',
  gift_code: 'Código de regalo',
  gratuity_amount: 'La cantidad de propina',
  gratuity_percentage: 'Porcentaje de propina',
  group_items_in_cart: 'Grupo mismos artículos en el carro junto',
  shared_secret: 'Secreto Compartido - solamente si el terminal entrar en la lista anterior',
  impossible_refresh_you_have_transactions: 'Imposible volver a cargar.\nTienes {{transaction_number}} transacciones no se sincronizan con el servidor.\nAsegúrese de que tiene una conexión a Internet, espera a que se sincronizan y vuelva a intentarlo en unos momentos.',
  impossible_signout_you_have_transactions: 'Imposible cerrar la sesión.\nTienes {{transaction_number}} transacciones no se sincronizan con el servidor.\nAsegúrese de que tiene una conexión a Internet, espera a que se sincronizan y vuelva a intentarlo en unos momentos.',
  insert_printer_name: 'Por favor, introduzca un nombre para la impresora (3 caracteres o más).',
  inserted_login_not_valid: 'Acceso incorrecto',
  inserted_value_not_valid: 'valor insertado no es válida',
  installmentAmount: 'installmentAmount',
  invalid_credit_card_number: 'Número de tarjeta de crédito debe ser de 16 dígitos',
  invoiceReference: 'invoiceReference',
  invoiceStatus: 'invoiceStatus',
  ip_address: 'Dirección IP',
  is_business: 'Es un negocio?',
  is_correct_amount_tendered: '{{amount}} es la cantidad correcta?',
  isDone: 'No hay más que cargar …',
  isError: 'Toque para volver a intentarlo …',
  isTap: 'Toque para cargar más ..',
  join_thousands_of_merchants: 'Miles de comerciantes han unido a nosotros! Hoy es su turno. Registrate gratis',
  language: 'Idioma',
  last_visit: 'Última visita',
  leave_tour: 'Deje gira ...',
  leaving_tour: 'Dejando gira ...',
  loyal_customer: 'Cliente leal',
  mac_address: 'Dirección MAC',
  mailing_list_permission: 'Añadir a la lista de correo',
  manual_mobile_payment: 'Pago movil',
  manual_mobile_refund: 'Restitución móvil',
  max_amount_exceeded: 'Cantidad excede el máximo permitido para este método de pago',
  menu_item_12char: 'Nombre de elemento de menú no puede contener más de 12 caracteres',
  menu_item_name: 'Nombre de elemento de menú (máx. 12 caracteres)',
  merchant_receipt: 'Recibo del comerciante',
  merchantDisplayName: 'merchantDisplayName',
  merchantId: 'merchantID',
  mid: 'MID',
  mobile_number: 'Número de teléfono móvil',
  mobile_payment: 'Móvil',
  mobile_payments: 'Pagos móviles',
  new_price: 'Nuevo precio ({{currency}})',
  no_available_addons_for_product: 'No hay complementos disponibles para el producto seleccionado.',
  no_connected_printer: 'No hay una impresora conectada',
  no_connected_terminal: 'El terminal no está conectado',
  no_customer_found: 'No se encontró al cliente',
  no_customer_selected: 'NA',
  no_printer_configured: 'No hay ninguna impresora configurada',
  no_printer_found: 'No se encontró la impresora \nHaga clic aquí si tiene una impresora conectada por USB estrella',
  no_product_in_cart: 'Ningún producto en el carrito',
  no_terminals_found: 'No se encontraron terminales',
  no_url_specified: 'Error, no URL especificada.',
  none: 'Ninguna',
  note_mandatory_cash_difference: 'Una nota es obligatoria si existe una diferencia en las cantidades esperadas y reales',
  note_optional: 'Nota (opcional)',
  numberOfInstallments: 'numberOfInstallments',
  numpad: 'Teclado numérico',
  offline: 'Desconectado',
  ok: 'Bueno',
  one_or_more_carts_are_frozen_finish_payment: 'Uno o más carros están congelados. Finalizar el pago antes de realizar cualquier acción.',
  online: 'En línea',
  open_market: '¿Quieres abrir la App location?',
  open_register_before_transaction: 'Debe abrir la caja registradora antes de poder hacer cualquier transacción',
  open_shift: 'Abrir la caja registradora',
  order_printer: 'Orden la impresora',
  orderId: 'orderID',
  orderRef: 'orderRef',
  other_text: 'Otro texto',
  paid: 'Pagado',
  paid_so_far: 'Pagado hasta ahora',
  paid_with_method: 'Pagado con {{method}}',
  pan: 'PAN',
  panLastDigits: 'PAN últimos dígitos',
  panMasked: 'Pan enmascarado',
  PANSeq: 'PANSeq',
  partial_payment: 'Pago parcial',
  partial_payment_not_allowed_for: 'Los pagos parciales no están permitidas para',
  partial_payment_paid_successfully: 'El pago parcial se ha pagado con éxito',
  pay_later: 'Pago Posterior',
  pay_later_allows_only_refund_all: 'Con el pago posterior, sólo se puede devolver la totalidad. Para los reembolsos parciales favor completar el pago.',
  pay_later_refund: 'Cancelar Pago Posterior',
  pay_on_account: 'Pagar la cuenta',
  payconiq: 'Payconiq',
  paying_now: 'Pago ahora',
  payment_cancelled: 'Pago cancelado',
  payment_error: 'Pago error',
  payment_info: 'Información de pago',
  payment_insert_card: 'Insertar la tarjeta',
  payment_method_not_configured: '{{payment_method}} no está configurado correctamente en este dispositivo',
  payment_number: 'Número de pago',
  payment_processor_options: 'Opciones del procesador de pago',
  payment_provider: 'Proveedor de pago',
  payment_success: 'Pago exitoso',
  payment_terms_in_net_days: 'Los términos de pago (días netos)',
  personal_information: 'Información personal',
  pickup_rewards: 'Recoger las recompensas',
  pin: 'PIN',
  please_insert_a_valid_amount: 'Por favor, introduzca una cantidad válida',
  posEntryMode: 'posEntryMode',
  prefName: 'prefName',
  print: 'Imprimir',
  print_also_product_details: '¿Desea también para imprimir los detalles del producto?',
  print_error: 'Error de imprimir',
  print_product_details: 'Imprimir detalles del producto',
  print_successful: 'imprimir con éxito',
  printer_connect_desc: 'impresoras WiFi necesitan ser conectados a la misma red que este dispositivo. \nImpresoras Bluetooth deben estar vinculados a través de la configuración del sistema antes de estar disponible aquí',
  printer_removed: 'Impresora retira',
  printer_test: 'Prueba de la impresora',
  printing_report: 'Generación y reporte de impresión …',
  proceed_with_next_payment: 'Proceder con el próximo pago',
  processorName: 'processorName',
  product_is_disable_continue_anyway: 'El producto que ha seleccionado está desactivado. ¿Quieres añadir a la cesta de todos modos?',
  product_sync: 'Sincronización del producto',
  profile_average: 'Promedio de compras',
  protected_sections: 'Las secciones protegidas',
  receipt_printer: 'Impresora de recibos',
  redeem_reward: 'Canje de premios',
  referenceNumber: 'referenceNumber',
  refresh_customers: 'Actualizar lista de clientes',
  refresh_merchant: 'Actualizar información de la cuenta',
  refresh_transactions: 'Actualizar transacciones',
  refreshing_merchant: 'Actualizar información de la cuenta…',
  refund_number: 'Número de reembolso',
  refunds_not_available_for_provider: 'Los reembolsos no disponibles para el proveedor: {{payment_provider}}',
  register_terminal: 'Registrar el terminal',
  reissue: 'Reedición',
  remaining_payment: 'Pago restante',
  remove_customer_from_cart: 'Sacar de la cesta',
  remove_payment: 'Retire el pago',
  remove_printer: 'Retire la impresora',
  removing_products: 'Productos refrescantes',
  reopen_shift: 'Reabrir la caja registradora',
  replace_customer: 'Reemplazar al cliente',
  report_by_shift: 'Habilitar el diario de apertura / cierre',
  report_printer: 'Informe de la impresora',
  requested_amount: 'Monto requerido',
  reward: 'recompensas',
  round_cash_transactions: 'Monto de la transacción en efectivo redondeo al más cercano 0.05',
  save_printer: 'Guardar impresora',
  scan: 'Escanear',
  scan_barcode_instruction_one: 'Escanear el código de barras para poner escáner Socket Bluetooth en modo de emparejamiento',
  scan_barcode_instruction_two: 'En la configuración del dispositivo, asegúrese de que Bluetooth está activado y luego emparejar el escáner Socket CHS',
  scan_code_not_exist: 'Código de escaneado no se reconoce',
  scan_qr_code: 'Escanear el código QR',
  scanner: 'Escáner',
  scanner_connected: 'Escáner conectado',
  scanner_disconnected: 'Escáner desconectado',
  scanner_pair_completed: 'Emparejamiento del escáner completado',
  search_for_customer: 'Búsqueda de atención al cliente',
  search_report: 'Buscar en los informes',
  search_transaction: 'Búsqueda de transacciones',
  searching_terminals: 'La búsqueda de terminales',
  security_and_permissions: 'Seguridad y permisos',
  select_one_payment_method: 'Al menos un método de pago debe estar habilitado',
  select_payment_method: 'Por favor, seleccione AYUDA tarjeta de la lista de abajo',
  select_terminal: 'Seleccionar un terminal',
  send_email: 'Enviar correo electrónico',
  send_to_another_customer: 'Enviar a otro cliente',
  send_to_current_customer: 'Enviar a cliente actual',
  send_to_customer: 'Enviar a cliente',
  sending_receipt: 'El envío de la recepción …',
  server_error: 'Error de Server',
  session: 'Sesión',
  set_new_price: 'Nuevo precio',
  setting_saved: 'Ajuste guardado',
  settings_connected_terminals: 'Terminal conectado',
  settings_printer_available_printers: 'Disponible impresora',
  settlementDate: 'settlementDate',
  setup_admin_pin_in_settings: 'Establecer su PIN admin en Configuración, en la pestaña Cuenta.',
  shift_closed: 'La caja registradora se ha cerrado',
  shift_opened: 'La caja registradora se ha abierto',
  shifts: 'Turnos',
  shopper_reference: 'ShopperReference',
  show_customer: 'Mostrar información del cliente en el recibo',
  show_employee: 'Mostrar información del empleado en el recibo',
  show_customer_payconiq: 'Por favor seleccione una ficha facturado al cliente o escanear el código de barras del cliente / NFC',
  show_new_product_tile: 'Mostrar "crear productos" baldosas',
  show_password: 'Mostrar mi contraseña',
  sign_out_header: '¿Estás seguro?',
  sign_out_warning: 'Si sales, se restablecerá todas las configuraciones de aplicaciones, incluyendo los proveedores de pago e impresoras. Por favor seleccione Cancelar, establecer un PIN de administrador y utilizar la función de bloqueo si no desea borrar la configuración. Seleccione Confirmar para proceder con la muestra a cabo.',
  sign_up_to_print: 'Regístrese para obtener una cuenta de prueba gratuita para imprimir un recibo. No se requiere tarjeta de crédito, cancelar en cualquier momento.',
  sign_up_to_send_email: 'Regístrese para obtener una cuenta de prueba gratuita para enviar un recibo por correo electrónico. No se requiere tarjeta de crédito, cancelar en cualquier momento.',
  signing_out: 'Cerrando sesión...',
  signup_for_mailing_list: 'Pregunte a los clientes para suscribirse a la lista de correo',
  sorry_report_not_available: 'Lo sentimos, este informe no está disponible, por favor vaya al panel de control para poder verla.',
  start_using_pos: 'Empieza a vender con Countr',
  state: 'Estado',
  status: 'Estado',
  store_powered_by: 'Esta tienda está alimentado por',
  street: 'Calle',
  suggested_amount: 'Cantidad sugerida',
  suggested_tip: 'Propina sugerida',
  support: 'Apoyo',
  telephone: 'Telefono',
  terminal_port: 'Puerto configurado para el terminal',
  terminal_selected: 'Terminal salvado',
  terminalId: 'terminalId',
  test: 'Esto es una prueba',
  three_inch: '3 pulgadas',
  tid: 'TID',
  Time: 'Tiempo',
  tip: 'Propina',
  tip_added: 'Propina añadido',
  to_pay: 'Pagar',
  total_amount: 'Cantidad total',
  transaction_code: 'Código de transacción',
  transaction_date: 'FechaDeTransacción',
  transaction_finalized: 'Finalizada la transacción',
  transaction_pending: 'Espera de la transacción',
  transaction_started: 'Inicio de transacción',
  transaction_succeeded: 'Transacción tuvo éxito',
  transaction_time: 'El tiempo de transacción',
  transaction_timeout: 'Espera de transacción',
  transactionSeq: 'transactionSeq',
  transactionType: 'transactionType',
  try_it_now: 'Inténtalo ahora',
  tsi: 'TSI',
  tvr: 'TVR',
  two_inch: '2 pulgadas',
  uncategorized: 'Ninguna Categoria',
  valid_phone_number: 'Número de teléfono válido',
  validating_fields: 'La validación de los campos de mecanografía',
  validation_in_progress: 'Validación en curso',
  verification_code: 'Esperando a un cliente',
  verification_method: 'Método de verificación',
  waiting_for_customer: 'Código de verificación',
  want_to_sync_products: '¿Le gustaría sincronizar sus productos?',
  yodopay: 'YodoPaY',
  you_must_select_a_store: 'Debe seleccionar una tienda',
  add_name_device: 'Añade un nombre a tu dispositivo (obligatorio)',
  card_number: 'Número de tarjeta',
  change_language: 'Cambiar el idioma',
  choose_currency: 'Elegir moneda principal',
  clear_cart_after_finish: 'Restablecer el nombre del carrito/mesa después del pago',
  confirm_new_language: '¿Confirmar nuevo idioma?\nTendrá que cerrar y reiniciar completamente la aplicación para aplicar este cambio',
  customer_signature: 'Firma del cliente',
  day: 'Día',
  device_name_mandatory: 'Nombre del dispositivo es obligatorio',
  downloading_refunds: 'Descarga de reembolsos ...',
  email_report: 'Enviar este informe por correo electrónico',
  email_successfully_sent_test: 'El correo electrónico de prueba enviado a su cuenta',
  enter_table_name: 'Introduzca el nombre de la tabla',
  error: 'Error',
  error_retrieve_refunds: 'Error al recuperar los reembolsos',
  error_retrieve_transactions: 'Error al recuperar las transacciones',
  for_this_device_see_dashboard: 'Sólo para este dispositivo - consulte Dashboard para obtener más informes',
  hide_new_sale: 'Ocultar pantalla \'Nueva venta\' después del pago',
  last_transaction_result: 'Resultado de la transacción más reciente',
  merge_warning: 'Esto fusionará el contenido de estos carros/tablas',
  month: 'Mes',
  post_code: 'Código postal',
  print_order: 'Mostrar enviar a pedido impresora',
  products_added_removed_refresh: 'Los productos fueron agregados o quitados - ¿actualizar lista?',
  quarter: 'Trimestre',
  refresh: 'Actualizar',
  refreshing_products: 'Actualización de productos…',
  removing_categories: 'Eliminación de categorías antiguas ...',
  removing_taxes: 'Eliminación de impuestos antiguos ...',
  select_interface_mandatory: 'Seleccionar una interfaz es obligatorio',
  sms_successfully_sent_test: 'Se envió un SMS de prueba a su cuenta',
  splitOrderPrintByCategory: 'Divida la impresión de pedidos por categoría',
  stored_digitally: 'Almacenado digitalmente',
  stripe_id: 'StripeID',
  sync_support_info: 'Sincronizar la información de soporte',
  syncing_support_info: 'Sincronizando la información de soporte ...',
  table: 'Mesa',
  table_is_empty: 'La mesa está vacía',
  tables_or_carts: 'Mostrar carros como mesas',
  uncertain_card_payment_success: 'Incierto si el pago con tarjeta fue un éxito',
  unknown_category: 'Categoría desconocida',
  unsynced_transactions_report_incorrect: 'Tiene {{transaction_number}} transacciones no sincronizadas con el servidor - los informes no reflejarán con exactitud la situación más reciente',
  update_terminal_firmware: 'Actualizar el firmware del terminal',
  week: 'Semana',
  year: 'Año',
  email_queued: 'Email en curso',
  order_received: 'Pedido nuevo / actualizado recibido',
  go_to_orders: 'Ver pedidos',
  open_carts: 'Carros abiertos',
  auto_print_order: 'Imprima pedidos sin imprimir pagados',
  auto_print_order_cart: 'Imprimir pedidos sin imprimir en el carrito / cambio de mesa',
  external: 'Otro',
  no_printer_found_tcp: 'No se encontró ninguna impresora \nHaga clic aquí para ingresar manualmente la información de la impresora',
  downloading_carts: 'Descargando carritos ...',
  pay_later_hybrid: 'Paga después',
  error_retrieve_carts: 'Error al recuperar carros',
  no_ip_address: 'Sin direccion',
  building_number: 'Número de edificio',
  confirm_manual_payment: 'Confirmar pago manual',
  shipping_address: 'Dirección de Envío',
  birth_date: 'Fecha de nacimiento',
  for_this_store_see_dashboard: 'Solo para esta tienda: consulte el Panel de control para obtener más informes',
  customers: 'Clientes',
  tickets_in: 'Entradas en',
  tickets_out: 'Entradas fuera',
  tickets_in_out: 'Entradas / salidas',
  unsynced_transaction_cant_refund: 'Transacción aún no sincronizada con el servidor; no es posible realizar un reembolso',
  show_stock_level: 'Muestra el nivel de existencias actual (si está disponible)',
  available: 'en stock',
  stock_not_available: 'stock no rastreado',
  order_another_store: 'Ordene desde otra ubicación',
  order_products: 'Habilitar el pedido de productos desde otras ubicaciones',
  trial_expired: 'Su período de prueba ha caducado. La funcionalidad POS se ha deshabilitado.',
  left_menu: 'Barra de navegación izquierda',
  right_menu: 'Barra de navegación derecha',
  direct_pay_if_one_method: 'Inicio automático del pago si se establece 1 método',
  processing_vouchers: 'Procesando fidelidad de carrito y vales ...',
  display_2_rows_of_categories: 'Mostrar 2 filas de categorías',
  unknown_barcodes_as_vouchers: 'Trate los códigos de barras no reconocidos como cupones',
  validate_signature: '¿Firma del cliente válida?',
  group_items_in_receipt_and_cart: 'Agrupe los mismos artículos en el carrito y el recibo',
  ask_customer_subscribe_email: 'Solicite al cliente que proporcione una dirección de correo electrónico',
  hide_categories: 'Ocultar mosaicos de categorías',
  hide_all_products_category: 'Mosaico de categoría Ocultar todos los productos',
  provisional_receipt: '*** PROVISIONAL ***',
  reprint: '*** REIMPRESIÓN ***',
  block_reprints: 'No permitir la reimpresión de artículos',
  reports_at_store_level: 'Mostrar informes de la tienda, no del dispositivo',
  max_addon_qty_exceeded: 'Máximo de {{maxQty}} complementos permitidos para este grupo',
  min_addon_qty_unmet: 'Se requiere un mínimo de {{minQty}} complementos de {{groupName}}',
  press_button_to_wake_terminal: 'Presione el botón verde para activar el terminal PIN',
  payments: 'Pagos',
  average_sale_duration_mins: 'Duración media de la venta (minutos)',
  save_order_move_next: 'Guardar pedido, continuar a la siguiente tabla vacía',
  max_orders_reached: 'Se alcanzó el número máximo de órdenes abiertas',
  show_open_orders: 'Mostrar los pedidos abiertos primero en la lista de pedidos',
  tender_created: 'Transacción iniciada',
  invalid_request: 'Solicitud no válida',
  already_processed: 'Transacción ya procesada',
  invalid_terminal: 'Terminal de pago no válido',
  network_error: 'Error de red',
  bad_credentials: 'Credenciales no válidas',
  server_issue: 'No puedo contactar al servidor',
  pin_entered: 'PIN ingresado',
  initial: 'Inicializando',
  card_inserted: 'Tarjeta insertada',
  card_swiped: 'Tarjeta deslizada',
  wait_app_selection: 'Esperando selección de aplicación',
  wait_amount_adjustent: 'Esperando ajuste de cantidad',
  ask_gratuity: 'Pide propina',
  gratuity_entered: 'Propina ingresada',
  ask_dcc: 'Pregunte por DCC',
  dcc_accepted: 'DCC aceptado',
  dcc_rejected: 'DCC rechazado',
  processing_tender: 'Licitación de procesamiento',
  wait_pin: 'Esperando PIN',
  provide_card_details: 'Proporcione los detalles de la tarjeta',
  card_details_provided: 'Detalles de la tarjeta proporcionados',
  receipt_printed: 'Recibo impreso',
  referral: 'Remisión',
  referral_checked: 'Remisión comprobada',
  check_signature: 'Verificar firma',
  signature_checked: 'Firma marcada',
  additional_data_available: 'Datos adicionales disponibles',
  transaction_acknowledged: 'Transacción reconocida',
  device_already_registered: 'Dispositivo ya registrado',
  add_in_progress: 'Ya agregando dispositivo',
  app_not_registered: 'Aplicación no registrada',
  unknown_error: 'Error desconocido',
  timeout: 'Desconectado',
  verify_failed: 'No se pudo verificar el dispositivo',
  identify_failed: 'No se pudo identificar el dispositivo',
  registration_error: 'Error de registro',
  sync_issue: 'No se pudo sincronizar el dispositivo',
  show_select_customer: 'Mostrar selector de clientes',
  store_name_mandatory: 'El nombre de la tienda es obligatorio',
  choose_store_dashboard_for_more: 'Elija la tienda: se pueden agregar más a través del panel de administración',
  recovering_incomplete_payment: 'Recuperación del pago incompleto: esto puede demorar hasta 30 segundos',
  allow_negative_sale: 'Permitir monto de venta negativo',
  move_current_table_to: 'Mover tabla actual ({{table_name}}) A',
  store: 'Tienda',
  print_specific_categories: 'Imprimir categorías específicas',
  customer_display_attached: 'Pantalla de cliente adjunta',
  sale: 'Rebaja',
  reps_and_waste_button_in_cart: 'Mostrar repeticiones y botón de desperdicio en el carrito',
  small_product_tiles: 'Reducir el tamaño de los mosaicos de productos',
  large_product_tiles: 'Aumentar el tamaño de los mosaicos de productos',
  show_product_price_on_tile: 'Precio del producto en mosaico',
  printer_roles: 'Funciones de la impresora',
  paper_size: 'Tamaño de papel',
  customer_display: 'Pantalla del cliente',
  enable_realtime_updates: 'Habilitar actualizaciones en tiempo real desde el servidor',
  show_open_orders_first: 'Mostrar los pedidos abiertos primero en la lista de pedidos',
  blank_lines_order_ticket: 'Líneas en blanco en la parte superior del ticket de pedido',
  blank_lines_bottom_order_ticket: 'Líneas en blanco en la parte inferior del ticket de pedido',
  scan_card: 'Escanear tarjeta',
  scan_nfc_or_barcode: 'Escanear tarjeta o código NFC',
  show_product_keypad: 'Mostrar teclado en el carrito',
  extra_payment_methods: 'Métodos de pago adicionales',
  failed_recovery: 'No se pudo recuperar el pago',
  blank_line_between_order_items: 'Línea en blanco entre los artículos del ticket de pedido',
  look_up_customers_using_barcodes: 'Agregar cliente al carrito usando escáner',
  error_loading_configuration: 'Error al cargar la configuración',
  error_verifying_payment_terminal: 'Error al verificar el terminal PIN',
  error_identifying_payment_terminal: 'Error al identificar el terminal PIN',
  error_saving_configuration: 'Error al guardar la configuración',
  conflicting_server_modes: 'El terminal pin y el servidor tienen modos conflictivos (PRUEBA vs PRODUCCIÓN)',
  nothing_to_print: 'No hay elementos para imprimir',
  large_item_name: 'Aumentar el tamaño del artículo impreso',
  print_ean_sku: 'Imprimir EAN y SKU en el recibo',
  select_category_on_startup: 'Seleccionar categoría una vez al inicio',
  split_tickets_item: 'Impresión dividida de pedidos por artículo',
  split_tickets_amount: 'Impresión dividida de pedidos por cantidad',
  split_print: 'Impresión dividida',
  refreshing_customers: 'Refrescando a los clientes ...',
  downloading_customers: 'Descargando clientes ...',
  error_retrieve_customers: 'Error al recuperar clientes. \nCompruebe su conexión a Internet y vuelva a intentarlo',
  server_error_please_retry: 'Error del Servidor. Por favor, intenta de nuevo',
  vibrate_on_click: 'Respuesta de vibración al hacer clic en el elemento',
  keep_screen_on: 'No permita que la pantalla duerma',
  refund_same_method: 'Obligar a que los reembolsos se realicen con el método de pago original',
  cannot_refund_more_than_paid: 'Solo se puede reembolsar un máximo de {{max_amount}} a través de {{payment_method}}',
  tablet_date_incorrect: 'La fecha del dispositivo se estableció incorrectamente: posible riesgo de seguridad',
  extra_non_revenue_info: 'Solicite información adicional para artículos que no generan ingresos',
  enter_extra_info: 'Ingrese información adicional del artículo',
  giftcard_or_reference_no: 'Tarjeta regalo o número de referencia',
  inserted_code_not_valid: 'Código incorrecto',
  validate_user: 'Validación de usuario ({{user_address}})',
  enter_validation_code: 'Ingrese el código de validación que recibió por SMS o correo electrónico',
  tax_rate: 'Tasa de impuesto',
  pretax: 'Red',
  deposit: 'Depositar',
  can_not_refund_with_different_provider: 'No se pueden reembolsar las transacciones de {{txn_payment_method}} con {{selected_payment_method}}',
  external_scanner_connected: 'Escáner externo conectado',
  cancelling: 'Cancelado',
  sumup: 'SumUp',
  ccv: 'CCV',
  payplaza: 'PayPlaza',
  worldline: 'Worldline',
  adyen: 'Adyen',
  rabosmartpin: 'Rabo SmartPin',
  exchange: 'Intercambiar',
  ask_exchange: 'Permitir intercambios de productos',
  exchange_error: 'Error de cambio',
  exchange_items: 'Artículos de intercambio',
  exchangeable: 'Cambiable',
  to_be_exchanged: 'Para ser intercambiado',
  exchange_all: 'Intercambiar todo',
  negative_sale_amount: 'La cantidad a pagar es menor que 0',
  download_rabosmartpin_app: 'Descarga la aplicación Rabo SmartPin',
  transaction_disabled: 'Pagos de terceros no permitidos en este contrato',
  transaction_in_progress: 'Otro pago ya está en curso',
  refund_offline: 'Los reembolsos no son posibles en modo restringido',
  refund_not_allowed: 'No se permiten reembolsos para el usuario que inició sesión',
  cash_forbidden: 'Pagos en efectivo no permitidos en este contrato',
  notify_new_online_order: 'Mostrar notificación cuando se reciba un nuevo pedido en línea',
  print_new_online_order: 'Imprima automáticamente nuevos pedidos en línea cuando los reciba',
  delivery_information: 'información de entrega',
  voucher_already_processed: 'Cupón ya procesado',
  no_applicable_items_in_cart: 'No se encontraron artículos aplicables en el carrito',
  discount_applied: 'Descuento aplicado',
  invalid_voucher: 'Recibo invalido',
  allow_voucher_scan: 'Verifique los cupones usando un escáner de código de barras',
  enable_drop_shipping: 'Habilitar el envío directo',
  ask_no_covers_or_takeaway: 'Preguntar siempre la configuración de la mesa',
  table_configuration: 'Tipo de mesa',
  num_covers: 'Número de invitados',
  takeaway: 'Para llevar',
  guests: 'Invitados',
  show_floorplan_after_sale: 'Mostrar plano de planta después de cada venta',
  lock_after_x_minutes: 'Bloquear después de x minutos de inactividad',
  show_deposit_items: 'Mostrar elementos de depósito',
  pickup: 'Recoger',
  delivery: 'Entrega',
  transaction_fee: 'Tarifa de transacción',
  print_description: 'Imprimir descripción del artículo en el recibo',
  only_cash_for_negative_amounts: 'Solo se puede seleccionar Pago en efectivo o con tarjeta manual cuando el monto es inferior a 0',
  phone_number_example: 'Teléfono: p. Ej. 31611111111',
  whatsapp_not_available_on_this_device: 'WhatsApp no está instalado en este dispositivo',
  receipt_not_yet_synchronised: 'Sincronización de transacciones con el servidor; inténtelo de nuevo en unos momentos',
  notify_online_orders_with_sound: 'Reproducir sonido cuando se reciben pedidos en línea',
  restrict_online_orders_by_floor: 'Acepte solo pedidos en línea para ciertos pisos',
  remove_product: 'Quitar producto',
  admin_access_needed: 'Esta función solo está disponible para usuarios administradores',
  customer_or_employee_name: 'Nombre del cliente o empleado',
  show_cart_on_left: 'Mostrar carrito en el lado izquierdo',
  groupOrderPrintByCategory: 'Impresión grupal de pedidos por categoría',
  handpoint_shared_secret: 'SharedSecret',
  create_bundle: 'Crear paquete',
  select_items_to_bundle: 'Seleccionar artículos para agrupar',
  bundle_price: 'Precio del paquete',
  create_bundle_items: 'Crea un paquete de productos en el carrito',
  bundle: 'Manojo',
  loss: 'Loss',
  stolen: 'Stolen',
  own_use: 'Own Use',
  giveaway: 'Giveaway',
  breakage: 'Breakage',
  student_check: 'verificación de identificación de estudiante',
  overview: 'Overview',
  average: 'Promedio',
  busiest_hours: 'Horas más ocupadas',
  rotation_time_in_secs: 'Tiempo de rotación de la imagen en segundos',
  show: 'Mostrar',
  uploaded: 'subido',
  videos: 'Vídeo',
  photos: 'Fotos',
  display_video_or_picture: 'Muestra videos, fotos o archivos pdf',
  store_logo: 'Logotipo de la tienda',
  add_selected_addon: 'Agregar / quitar complementos',
  add_selected_to_stores: 'Agregar / Eliminar a tiendas',
  add_selected_categories: 'Agregar / quitar categorías seleccionadas',
  select_categories: 'Seleccionar categorías',
  add_selected_addon_group: 'Agregar / quitar grupo de complementos',
  company: 'Empresa',
  assortment: 'Surtido',
  sales_history: 'Historial de ventas',
  employees: 'Empleados',
  extensions: 'Extensiones',
  desktop: 'Escritorio',
  helpdesk: 'Mesa de ayuda',
  specific: 'Específico',
  no_data_for_this_period: 'No se encontraron datos para este período.',
  gross_revenue: 'Ingresos brutos',
  avg_per_transaction: 'Promedio por transacción',
  from: 'desde',
  select_period: 'Seleccionar periodo',
  date_start: 'Fecha de inicio',
  date_end: 'Fecha final',
  select_store: 'Seleccionar tienda',
  select_stores: 'Tiendas selectas',
  all_stores: 'Todas las tiendas',
  all_employees: 'Todos los empleados',
  all_devices: 'Todos los dispositivos',
  no_transactions_in_this_period: 'No hay transacciones en este periodo..',
  transactions_in_this_period: 'Transacciones en este periodo.',
  report_graph: 'Informe Gráfico',
  no_reports_found_for_this_period: 'No se encontraron informes para este período',
  top_sales: 'Mayores ventas',
  top_sellers: 'Los mas vendidos',
  bottom_sellers: 'Vendedores inferiores',
  no_top_sales_for_this_period: 'No hay ventas superiores para este período',
  best_selling_products_for_this_period: 'Productos más vendidos para el período',
  save_email: 'Salvar email',
  new_password: 'Nueva contraseña',
  verify_password: 'Confirmar contraseña',
  save_password: 'Salvar contraseña',
  contact: 'Contacto',
  name_first: 'Nombre',
  name_last: 'Apellido',
  zip_code: 'Código postal',
  region: 'Region',
  billing: 'Facturación',
  optional: 'Opcional',
  business_name: 'Nombre del Negocio',
  vat: 'Número de Valor Agregado',
  btw: 'Vat',
  save_profile_info: 'Guardar información de perfil',
  integrations: 'Integraciones',
  connect_to_your_existing: 'Conéctate a tu existente',
  invoices: 'Facturas',
  code: 'Código',
  isPaid: 'está Pagado',
  invoice_paid: 'Factura pagada',
  actions: 'Acciónes',
  stores: 'Tiendas',
  updated: 'Actualizado',
  updated_at: 'Actualizado en',
  create: 'Crear',
  info: 'información',
  currencies: 'Monedas',
  save_edit_details: 'Guardar y editar detalles',
  assigned_employees_of_this_stores: 'Empleados asignados de esta tienda:',
  not_assigned_employees: 'Empleados no asignados:',
  add_payment: 'Agregar pago',
  payment_name: 'Nombre de pago',
  printer_name: 'Nombre de la impresora',
  edit_storess_main_details: 'Editar los principales detalles de las tiendas.',
  edition: 'edición',
  stores_address_details: 'Detalles de la dirección de la tienda.',
  stores_contact_details: 'Datos de contacto de la tienda.',
  assign_employees_for_your_stores: 'Asigna empleados para tu tienda.',
  create_custom_payment_methods_for_your_store: 'Crea métodos de pago personalizados para tu tienda.',
  devices: 'Dispositivos',
  device: 'Dispositivo',
  hardware_info: 'Información de hardware',
  uuid: 'UUID',
  manufacturer: 'Fabricante',
  os_name: 'OS Nombre',
  os_version: 'OS Versión',
  pos_version: 'Versión del Countr',
  receipt_layout: 'Diseño de recibo',
  table_layout: 'Diseño de tabla',
  default_receipt: 'Recibo por defecto',
  email_receipt: 'Recibo de correo electrónico',
  change_image: 'Cambiar imagen',
  Tuesday: 'Martes',
  june: 'Junio',
  your_store_address: 'La dirección de tu tienda',
  edit: 'Edición',
  your_text_here: 'Tu texto aqui',
  cheap: 'Barato',
  expensive: 'Costoso',
  client_receipt: 'Recibo del cliente',
  transaction_agreement: 'Acuerdo de transacción',
  your_footer_message_here: 'Su mensaje de pie de página aquí',
  image: 'Imagen',
  price: 'Precio',
  edit_products_main_details: 'Editar detalles principales del producto.',
  details: 'Detalles',
  visible: 'Visible',
  in_pos: 'in POS',
  cost: 'costo',
  cost_price: 'precio de coste',
  delivery_price: 'Precio de entrega',
  upload_progress: 'Progreso de carga',
  change_products_image: 'Cambiar la imagen del producto',
  variants: 'Variantes',
  variant: 'Variante',
  units: 'Unidades',
  alert: 'Alerta',
  additional: 'Adicional',
  ledger: 'Libro mayor',
  subledger: 'Libro auxiliar',
  addon: 'complemento',
  addon_groups: 'Addon Groups',
  addon_group: 'Addon Group',
  add_delete_and_edit_products_variants: 'Añadir, borrar y editar las variantes del producto.',
  assing_product_to_store: 'Asigne un producto a una tienda específica (si no se selecciona ninguna tienda, el producto aparecerá en cada tienda).',
  products_addtional_fields: 'Campos adicionales del producto.',
  manage_addons_group: 'Grupo de complementos',
  sold_by_weight: 'Vendido por peso',
  dynamic_price: 'Precio dinámico',
  dynamic: 'Precio dinámico',
  min_age: 'Edad mínima',
  packaging: 'Embalaje',
  contents: 'contenido',
  form: 'formar',
  products_packaging_and_suppliers_details: 'Embalaje del producto y detalles de proveedores.',
  stock: 'Stock',
  management: 'Administración',
  count: 'contar',
  filter_name: 'Escriba para filtrar la columna de nombre...',
  find_products_category: 'Encuentra productos por categoria',
  find_products_addons: 'Encontrar productos por complementos.',
  in_stock: 'En stock',
  order_below: 'Orden Abajo',
  order_quantity: 'Ordene la cantidad',
  advance: 'Avanzar',
  view_history: 'Ver historial',
  click_here_to_select_source: 'Haga clic aquí para seleccionar la fuente',
  record_missing_item: 'Registrar el artículo que falta',
  lost: 'Perdido',
  current_stock: 'Stock actual',
  current_report: 'Informe actual',
  view_stock_history: 'Ver historial de stock',
  color: 'Color',
  duplicate_item: 'Elemento duplicado',
  want_duplicate_item: '¿Quieres duplicar este artículo?',
  delete_item: 'Eliminar elemento',
  want_delete_item: '¿Quieres eliminar este artículo?',
  categories_visibility_changed: 'Categorías de visibilidad cambiadas',
  change_visibility: 'Cambiar visibilidad',
  category: 'Categoría',
  parent: 'Parent',
  select: 'Seleccionar',
  rate: 'Tarifa',
  delete_selected_items: 'Eliminar elementos seleccionados',
  aplly_diff_rates: '¿Quieres aplicar una tasa impositiva diferente para diferentes países?',
  countries_added: 'Países agregados',
  groups: 'Grupos',
  group: 'Grupo',
  standard: 'Defecto',
  default_text: 'Texto predeterminado',
  business_rules: 'Reglas del negocio',
  business_rule: 'De reglas de negocio',
  title: 'Título',
  is_active: 'está Activo',
  startdate: 'Fecha de inicio',
  enddate: 'Fecha final',
  rules: 'Reglas',
  rule: 'Regla',
  edit_business_rules_main_details: 'Editar detalles principales de la regla de negocio.',
  active: 'Activo',
  time_start: 'Hora de inicio',
  time_end: 'Hora de finalización',
  select_specific_days: 'Seleccione día(s) específico',
  conditions: 'Condiciones',
  condition: 'Condición',
  change_business_rules_conditions: 'Cambiar las condiciones de la regla de negocio.',
  change_business_rules_outcome: 'Cambiar el resultado de la regla de negocio.',
  minimum_amount: 'Monto minimo',
  minimum: 'Minimo',
  maximum: 'Máximo',
  maximum_amount: 'Monto maximo',
  ignore_for: 'Ignorar por',
  outcome: 'Outcome',
  voucher_outcome: 'voucher outcome',
  new_price_rule: 'Nuevo precio para regla de negocio.',
  min_pack_value: 'Valor de paquete mínimo',
  max_pack_value: 'Valor de paquete maximo',
  enable: 'Habilitar reglas',
  disable: 'Deshabilitar reglas',
  all: 'todos/todas',
  update_time: 'Actualizar hora de inicio/finalización',
  update_date: 'Fecha de inicio/finalización de la actualización',
  catalog_order: 'Orden del catalogo',
  drag_and_drop_items_to_reorder: 'Arrastrar y soltar elementos para reordenar',
  see_reports: 'Puedes ver tus informes en el panel seleccionando',
  generate_report: 'Generar informe',
  or: 'O',
  send_report: 'Enviar este informe por correo electrónico',
  send_report_button: 'Enviar reporte',
  send_to: 'Enviar a',
  recipient: 'recipiente',
  available_reports: 'Informes disponibles para este período de tiempo',
  select_currency_text: 'Por favor seleccione una moneda o una tienda específica.',
  tables: 'Mesas',
  revenue: 'Ingresos',
  see_revenue: 'Revise sus ingresos brutos, costos, impuestos y ganancias',
  net_revenue: 'Ingresos netos',
  profit: 'Lucro',
  see_transaction: 'Consulte su método de transacción, el número y la cantidad total',
  see_payments: 'Consulte su método de pago, el número y la cantidad total',
  see_refunds: 'Consulte su método de reembolso, el número y la cantidad total',
  see_taxes: 'Vea sus impuestos, el número y la cantidad total',
  brands: 'Marcas',
  channels: 'Canales',
  channel: 'Canal',
  see_brands: 'Vea sus productos por marca, el número y la cantidad total',
  see_channels: 'Vea sus productos por canal, el número y la cantidad total',
  see_categories: 'Vea sus categorías, el número y la cantidad total',
  click_details: 'Haz clic aquí para ver detalles',
  report_select_other_period: 'Por favor, seleccione otro período de tiempo para ver el informe disponible',
  created: 'Creado',
  discounts: 'Descuentos',
  add_discount: 'Añadir descuento',
  discount_percent: 'Porcentaje de descuento',
  register_opertations: 'Registro de Operaciones',
  operations: 'Operaciones',
  first: 'Primero',
  last: 'Ultimo',
  phone: 'Telefono',
  permission_to_contact: 'Permiso para contactar',
  identifiers_type: 'Tipo de identificadores de clientes',
  identifiers_value: 'Valor de identificadores',
  shipping: 'Envío',
  diff_billing: 'si es diferente a la facturación',
  rights: 'Derechos',
  employee: 'Empleado',
  token: 'Token',
  uninstall_ext: 'Desinstalar extensión',
  active_ext: 'Esta extensión está activa.',
  subscribe: 'Suscribir',
  remove_ext_msg: '¿Estás seguro de que quieres eliminar esta extensión?',
  no_thanks: 'No, gracias',
  subs_to_ext: 'Suscribirse a las extensiones',
  period: 'Período',
  payment_details: 'Detalles del pago',
  card_name: 'Nombre en la tarjeta:',
  paymnet_method: 'Métodos de pago:',
  code_country: 'Código de país:',
  diff_way_pay_msg: 'O elige una forma diferente de pagar',
  phone_support: 'Soporte telefónico',
  support_msg: 'Póngase en contacto con el servicio de atención al cliente para obtener ayuda:',
  email_support: 'Soporte de correo electrónico',
  support_email: 'Soporte de correo electrónico:',
  info_email: 'Info e-mail:',
  sales_email: 'E-mail de ventas:',
  page: 'página',
  dark_theme: 'Tema oscuro',
  report_spec_time: 'Generar informe con un tiempo específico.',
  vat_btw_no: 'IVA / BTW No.',
  store_unable_delete: 'No se puede eliminar la tienda porque un dispositivo está vinculado. Por favor, retire el dispositivo antes de eliminar la tienda.',
  important: 'Importante:',
  store_device_delete_msg: 'Asegúrese de que se haya desconectado de este dispositivo antes de eliminarlo.',
  email_test_receipt: 'Enviar un recibo de prueba por correo electrónico',
  merchant: 'Merchant',
  per_item: 'por artículo',
  img_width_msg: 'El ancho de archivo máximo permitido es de 250px.',
  err_img_msg: 'Error: el ancho de la imagen no debe exceder 250px',
  rules_actions: 'Reglas de Acciones',
  enable_all: 'Habilitar todas las reglas de negocio',
  want_enable_all: '¿Quieres habilitar todas las reglas de negocio?',
  disable_all: 'Deshabilitar todas las reglas de negocio.',
  want_disable_all: '¿Desea desactivar todas las reglas de negocio?',
  product_cat_error: 'Por favor elija una categoría',
  field_not_empty: 'El campo no debe estar vacío.',
  drop_files: 'Suelta tu archivo aquí para subir la imagen.',
  save_variant_before_stock: 'Guarde la nueva variante antes de editar stock.',
  no_employees: 'No tienes empleados. Puedes crear nuevos empleados',
  here: 'aqui',
  no_stock: 'No hay productos de stock en esta tienda.',
  product_exists: 'Este producto existe solo en',
  add_units_stock: 'Añadiendo unidades al stock.',
  remove_units_stock: 'Eliminando unidades de stock',
  saving: 'Esta ahorrando',
  transfer_units: 'Añadir la cantidad de unidades a transferir',
  remove_units_store: 'Agregue la cantidad de unidades para eliminar de esta tienda',
  notification: 'notificación',
  set_new_start_end_time: 'Establecer nueva hora de inicio/finalización',
  set_new_start_end_date: 'Establecer nueva fecha de inicio / finalización',
  by_email: 'por correo electronico',
  charts_graphs: 'Charts/Graphs',
  compare_reports: 'Comparar reporte',
  new_report: 'Nuevo reporte',
  basic: 'Basic',
  no_acount_to_send_report: 'No puede enviar este informe porque su cuenta es',
  upgrade_account_msg: 'Necesitas actualizar tu cuenta!',
  gen_report_msg: 'Puedes generar tu informe y ver los resultados en el panel.',
  disabled_report: 'Reportes deshabilitados mientras el pago (s) está pendiente',
  please_check: 'Por favor, compruebe su',
  email_wrong_format: 'El formato del correo electrónico no es correcto.',
  bar_chart: 'Bar Chart',
  method: 'Método',
  total_taxed_amount: 'Importe total gravado',
  total_price_product: 'Precio total de los productos.',
  enter_value: 'Debes introducir un valor',
  not_valid_mail: 'No es un correo electrónico válido',
  enter_email: 'Introduce tu correo electrónico',
  unit_price: 'Precio unitario',
  extras: 'Extras',
  monthly_billed: 'facturado mensualmente',
  removing_ext: 'Esta extensión será eliminada en el próximo ciclo de facturación.',
  local_ip: 'Local IP',
  printer_ip_ad: 'Dirección IP de la impresora',
  printer_categories: 'Categorías de impresoras',
  printers: 'Impresoras',
  payment_processor: 'Procesador de pagos',
  question: 'Tienes una pregunta ?',
  check_here: 'Revisa aqui nuestro',
  knowledge_center: 'Centro de conocimiento.',
  support_articles_msg: 'Tenemos varios artículos de soporte que pueden ayudarlo a comenzar, crear productos, conectar su tienda web y más...',
  support_ques: 'Si aún no ha encontrado la respuesta que desea, puede obtener',
  in_touch: 'en contacto con nosotros',
  select_floorplan: 'Seleccionar plano',
  floors: 'Pisos',
  add_floorplan: 'Añadir plano',
  delete_floorplan: 'Eliminar plano',
  floorplan: 'Planta baja',
  add_tables: 'Agregar tablas',
  sign_up_msg: 'Miles de comerciantes han unido a nosotros! Hoy es su turno. Registrate gratis.',
  user_required: 'Se requiere nombre de usuario.',
  name_required: 'Se requiere el nombre.',
  company_name_required: 'el nombre de la empresa es obligatorio.',
  phone_required: 'Se requiere numero de telefono.',
  pass_required: 'se requiere contraseña.',
  already_account: '¿Ya tienes una cuenta?',
  accept_sign_up: 'Al suscribirse acepto los',
  terms_conditions: 'Términos y Condiciones',
  policy: 'Política de Privacidad',
  licence_agree: 'License Agreement',
  your_name: 'Tu nombre',
  company_web: 'Sitio web de la empresa (no obligatorio)',
  forgot_pass: '¿Olvidó su contraseña?',
  login: 'Ingresar',
  no_account_link: '¿No tienes una cuenta?',
  email_required: 'Correo electronico es requerido.',
  send_link: 'Enviar un enlace',
  download_file: 'Descargar archivo',
  account_number: 'Número de cuenta',
  me_rules: 'Reglas',
  by_volume: 'Productos de mayor venta (por volumen) este período',
  number_sold: 'Número vendido',
  by_volume_bottom: 'Productos de venta inferior (por volumen) este período',
  create_compare_report: 'Crear un nuevo informe para comparar',
  last_per: 'Último',
  no_per_data: 'não tem relatórios disponíveis',
  create_new_rep: 'Crear nuevo informe',
  reset_pass_text: 'Ingrese su correo electrónico para restablecer su contraseña',
  enter_bus_info: 'Por favor ingrese la información de su negocio',
  organization: 'Nombre de la Organización',
  mandatory_fields: 'Todos los campos son obligatorios !!!',
  invalid_eu: 'Número de IVA no válido para el país de la UE',
  trial_expires: 'Tu juicio expira en',
  select_package: 'Seleccionar paquete',
  excluse_vat: 'exclusivo de VAT',
  instead: 'en lugar de',
  save_payment: 'Guarde este método de pago para futuras transacciones',
  procced_paymet: 'Proceder al pago',
  selected_package: 'Paquete seleccionado',
  selected_ext: 'Extensiones seleccionadas',
  old_dashboard: 'Volver al Tablero de Instrumentos Heredado',
  subscription_msg: '¡Felicidades, estás a punto de salir a vivir con tu tienda! Sus transacciones se guardarán para siempre y todas las marcas de prueba desaparecerán. Antes de ponerte en funcionamiento, asegúrate de elegir las extensiones que deseas seguir usando.',
  transfer_from: 'Unidades de transferencia desde',
  customer_purchase: 'Compra del cliente',
  total_spend: 'Gasto total',
  last_customers_transactions: 'Últimas transacciones del cliente',
  email_recipient: 'Email recipiente',
  show_revenue: 'Mostrar ingresos',
  show_sales: 'Mostrar ventas',
  visits: 'Visitas totales',
  select_action: 'Seleccione la acción',
  show_receipt: 'Mostrar recibo',
  set_values_for: 'Establecer valores para',
  update: 'Actualizar',
  select_addons_group: 'Seleccionar grupo de complementos',
  select_addon: 'Seleccionar complemento',
  all_addon_groups_are_in_use: 'La lista está vacía, todos los grupos de complementos están en uso',
  store_hours: 'Horas Laborales',
  opening_hours: 'Horario de apertura',
  no_opening_hours: 'Sin horario de apertura',
  set_opening_hours: 'Establecer horarios de apertura',
  delivery_hours: 'Horas de entrega',
  no_delivery_hours: 'No hay horas de entrega',
  set_delivery_hours: 'Establecer horarios de entrega',
  ordering_hours: 'Horas de pedido',
  no_ordering_hours: 'No hay horas de pedido',
  set_ordering_hours: 'Establecer horas de pedido',
  takeaway_hours: 'Horas de comida para llevar',
  no_takeaway_hours: 'No hay horas de comida para llevar',
  set_takeaway_hours: 'Establecer horas de comida para llevar',
  delivery_zones: 'Zonas de entrega',
  no_delivery_zones: 'No hay zonas de entrega.',
  set_delivery_zones: 'Establecer zonas de entrega',
  Monday: 'Lunes',
  Thursday: 'Jueves',
  Friday: 'Viernes',
  Saturday: 'Sábado',
  Sunday: 'Domingo',
  set_opening_store: 'Establecer horarios de apertura de la tienda',
  set_delivery_store: 'Establecer horarios de entrega para la tienda',
  set_ordering_store: 'Establecer horarios de pedido para la tienda',
  set_takeaway_store: 'Establecer horarios de comida para llevar para la tienda',
  set_zones_store: 'Establecer zonas de entrega para la tienda',
  no_data_added: 'Sin datos agregados',
  add_hours: 'Agregar horas',
  add_zones: 'Agregar zonas de entrega',
  order_sub: 'Ordenar subcategorías',
  new_item: 'nueva',
  tips: 'propina',
  see_busiest_hours: 'Ver las horas más ocupadas según el período que seleccionó',
  see_employee_transactions: 'Ver transacciones por empleado para el período seleccionado',
  see_employee_tips: 'Ver propina por empleado para el período seleccionado',
  see_employee_refunds: 'Ver reembolsos por empleado para el período seleccionado',
  see_customer_transactions: 'Ver transacciones por tipo de cliente para el período seleccionado',
  see_customer_refunds: 'Ver reembolsos por tipo de cliente para el período seleccionado',
  new_customer: 'Nuevo cliente',
  returning_customer: 'Soy Cliente',
  no_customer: 'Sin datos de clientes',
  reps_waste: 'Reps and waste',
  see_reps_waste: 'See reps and waste by reason',
  see_employee_reps_waste: 'See reps and waste by employee',
  employee_report_graph: 'Ver las ventas de los empleados por tiempo',
  not_sold: 'No vendido',
  non_selling_products_this_period: 'Productos no vendidos este período (Productos con 0 ventas)',
  compare_stores: 'Comparar tiendas',
  average_amount_spent: 'Cantidad promedio gastada',
  average_amount_refunded: 'Importe medio reembolsado',
  bundle_parts: 'Paquete de piezas',
  create_giftcard: 'Crear tarjeta regalo',
  giftcards: 'Tarjetas de regalo',
  select_graphs_type: 'Seleccionar tipo de gráfico',
  see_discount_percentage: 'Ver transacciones con porcentaje de descuento',
  see_discount_by_product_percentage: 'Ver descuento otorgado a productos (los descuentos numéricos se calculan como porcentaje)',
  discount_percentage: 'Descuentos (porcentaje)',
  discount_numeric: 'Descuento (numérico)',
  see_discount_numeric: 'Ver transacciones con descuento numérico',
  number_of_discounts: 'y la cantidad de veces que se produjo este descuento en el producto o en el carrito',
  delivery_cost: 'Coste de envío',
  total_cash_after_tips_and_change: 'Efectivo total después de propinas y cambio',
  assigned_stores: 'Tiendas asignadas',
  chat_iframe: 'Chat',
  mobile_phone: 'Teléfono móvil',
  notifications: 'Notificaciones',
  annualy_billed: 'facturado anualmente',
  duplicate: 'Duplicado',
  select_allergens: 'Seleccionar alérgenos',
  assigned_groups: 'Grupos asignados',
  Wednesday: 'Miercoles',
  deviceid_mandatory: 'Nombre del dispositivo es obligatorio',
  send_order: 'Enviar orden',
  no_description: 'Este producto no tiene descripción. Puede agregar uno presionando el botón "Cambiar" o visitar su tablero.',
  change_description: 'Introduzca la descripción del producto.',
  main_cart_is_empty: 'El carrito principal está vacío',
  apply_to_all_carts: 'Aplicar a todos los carritos',
  per_value_payment: '(Pago por valor)',
  select_a_seat: 'Seleccione un asiento',
  save_table: 'Guardar',
  save_cart: 'Guardar',
  select_items: 'Seleccionar artículos',
  partial_payment_not_allowed_by_value: 'Los pagos parciales no están permitidas',
  customer_groups: 'Grupos de clients',
  sales_insights: 'Perspectivas de ventas',
  product_insights: 'Perspectivas del producto',
  store_insights: 'Información de la tienda',
  allow_ticket_scan: 'Verifique los entradas usando un escáner de código de barras',
  no_pin_employee: 'Empleado/a sin PIN',
  allow_ticket_scan_desc: 'Use el escáner de código de barras para buscar boletos.',
  admin_only_custom_item: 'Habilitar elemento personalizado solo para administrador',
  admin_only_custom_item_desc: 'Solo los usuarios con derechos de administrador pueden crear un elemento personalizado. También debe activar el PIN de empleado.',
  mandatory_employee_pin: 'Activación obligatoria del PIN de empleado.',
  admin_only_refunds: 'Solo los administradores/as pueden realizar reembolsos.',
  admin_only_refunds_desc: 'Solo los administradores pueden realizar reembolsos. También debe activar el PIN de empleado.',
  round_cash_transactions_desc: 'Redondee automáticamente los pagos en efectivo al 0.05 más cercano',
  unlimited_usage: 'Uso ilimitado',
  infinite: 'Ilimitado',
  disable_custom_discounts: 'Deshabilitar descuentos personalizados',
  disable_custom_discounts_desc: 'Deshabilite los descuentos personalizados pero permita los predefinidos.',
  guest: 'Invitado',
  select_method: 'Seleccionar método',
  manual_insert: 'Inserto manual',
  enter_code: 'Introduzca el código',
  existing_by_value_payment: '* No se puede combinar pago por valor y pago por asiento y / o artículo',
  order_sent: 'Pedido Enviado',
  select_multiple_seats: 'Seleccionar varios asientos',
  change_seat_or_guest_num: 'Cambiar asiento o número de invitado',
  change_guest_num: 'Cambiar el número de invitado',
  giftcard_low_value: '* El saldo de la tarjeta de regalo es menor que el valor de los artículos seleccionados. En su lugar, intente pagar por valor.',
  order_by_seat_desc: 'Habilite el pedido por función de asiento.',
  received_amount: 'Cantidad recibida',
  order_products_action: 'Pedir productos',
  ordered_products: 'Productos pedidos',
  select_table: 'Seleccionar mesa',
  ordered: 'Ordenado',
  scan_or_type: 'Escanear o escribir',
  type_code: 'Código de tipo',
  number_extension: 'Extensión de número',
  move_processing_ticket_first: 'Mueva los tickets de procesamiento primero',
  disable_adblocker: 'Por favor deshabilite su AdBlocker y actualice la página',
  remove_partial_payment_by_item: '¡Pago parcial por artículo detectado! ¡Elimínelo para continuar con esta acción!',
  add_nutrients: 'Agrega nutrientes',
  edit_devicess_main_details: 'Edite los detalles principales de los dispositivos.',
  create_custom_payment_methods_for_your_device: 'Cree métodos de pago personalizados para su devi.',
  show_open_carts_from_device: 'Mostrar pedidos abiertos desde el dispositivo',
  create_discounts_for_your_stores: 'Crea descuentos para tu ubicación.',
  create_reps_waste_for_your_stores: 'Cree representantes y desperdicios para su ubicación.',
  show_cat_bar: 'Mostrar barra de categorías',
  bulk_update: 'Elementos de actualización masiva',
  show_category_product_info: 'Mostrar información de categoría en el producto',
  ready_items: 'artículos listos',
  disabled: 'Discapacitado',
  ready_orders: 'pedidos listos',
  surcharge: 'Lista de cambios',
  changes_list: 'Lista de cambios',
  action_type: 'Tipo de acción',
  previous_change: 'Cambio anterior',
  new_changes: 'Nuevos cambios',
  removed_fields: 'Campos eliminados',
  updated_fields: 'Campos actualizados',
  full_history: 'Historia completa',
  changes_history: 'Historial de cambios',
  product_history: 'historial del producto',
  category_history: 'Historial de categorías',
  device_history: 'historial del dispositivo',
  enable_mandatory_customer_address: 'Dirección del cliente obligatoria',
  please_fill_all_fields: 'Complete todos los campos obligatorios: número de edificio, calle, ciudad, estado/provincia, código postal, país, nombre y apellido, dirección de correo electrónico o número de teléfono',
  select_file: 'Seleccione Archivo',
  drop_file: 'Soltar archivo',
  create_media: 'Crear medios para',
  upload_image: 'Cargar imagen',
  upload_file: 'Subir archivo',
  add_custom_img: 'Agrega tu imagen personalizada',
  select_media: 'Seleccionar tipo de medio',
  external_url_iframe: 'Iframe de URL externo',
  select_ticket_print: 'Seleccionar tipo para imprimir',
  external_service_provider: 'Proveedor de servicios externo',
  barcode_input: 'Entrada de código de barras',
  stock_load_msg: 'Consultar todos los productos por stock. Esto puede tomar algo de tiempo. ¡Espere por favor!',
  no_available_stores: 'No hay tiendas disponibles.',
  buy_combination_get_deal_new_price: 'Combinar artículos por un precio de contrato',
  discount_specific_products_categories: 'Descuento en productos o categorías específicas',
  discount_all_prodcucts: 'Descuento en todos los productos',
  buy_x_items_get_cheapest_discount: 'Compra x artículos, obtén el más barato con un descuento.',
  spend_certain_amount_get_discount: 'Gasta una cierta cantidad, obtén un descuento',
  customer_group_item_discount: 'Descuento en grupos de clientes específicos',
  item_surcharge: 'Recargo en productos, categorías o grupos de clientes específicos',
  cart_surcharge: 'Recargo en todo el pedido',
  import: 'Importar',
  export: 'Exportar',
  simple: 'Sencillo',
  advanced: 'Avanzado',
  delete_import: 'Eliminar importación de productos',
  upload_csv: 'Sube tu archivo CSV',
  open_file: 'Abra el archivo usando',
  save_file: 'Guarde el archivo como .csv',
  value_remaining: 'Valor restante:',
  shared_shift: 'Compartir turno',
  shared_shift_desc: 'Cambiar de empleado no requerirá reabrir el registro',
  print_table_name: 'Imprimir nombre de la tabla en el recibo',
  print_note: 'Imprimir nota en el recibo',
  sample_check_msg_top: 'Espere por favor. Debería haber un chequeo.',
  sample_check_msg_bottom: 'Un empleado vendrá lo antes posible.',
  full_basket_check: 'Comprobación de cesta llena',
  missing_items_amount: 'Cantidad de artículos faltantes',
  extra_items_scanned: 'Cantidad de artículos adicionales',
  finish_check: 'Terminar cheque',
  original_items: 'Artículos originales',
  checked_items: 'artículos marcados',
  merging: 'Fusionando en progreso',
  select_table_from_list: 'Seleccione una tabla de la lista para fusionar todas las demás tablas seleccionadas',
  cant_merge_same: 'No se puede fusionar la misma tabla, seleccione otra tabla para fusionar',
  remove_from_table: 'quitar de la mesa',
  eat_in: 'Coma aqui',
  locked_pos: 'POS está bloqueado',
  fiscal_store_employee: 'Las tiendas fiscales deben tener un empleado de registro para operar un POS',
  invalid_employee: 'empleado inválido',
  update_employee_ssn: 'Por favor actualice el número de seguro social del empleado',
  social_security_number: 'Número de seguro social',
  fiscal_one_employee: 'Debe tener al menos un empleado registrado en una tienda fiscal',
  fiscal_employee_ssn: 'Los empleados registrados en tiendas fiscales deben tener un número de seguro social válido',
  employee_unable_delete: 'No se puede eliminar al empleado porque está vinculado en una tienda fiscal. Saque primero al empleado de la tienda.',
  vat_receipt: 'Este es un recibo de IVA',
  not_vat_receipt: 'Esto no es un recibo de IVA',
  deposit_error: 'Error de depósito',
  deposit_error_message: 'No puede tener un depósito negativo adjunto a un producto con un precio positivo. Solo los productos con precio 0 o menos pueden tener depósito negativo.',
  assign_printers_to_your_location: 'Asigne impresoras a su ubicación',
  save_new_printer: 'Guardar nueva impresora',
  update_printer: 'Actualizar impresora',
  location_no_saved_printers: 'No hay impresoras guardadas para esta ubicación',
  sort_items_by_action: 'Ordenar artículos por acción (preparando, nuevo, listo)',
  print_x_report: 'Imprimir informe X',
  shox_x_report: 'Mostrar informe X',
  print_z_report: 'Imprimir informe Z',
  show_z_report: 'Mostrar informe Z',
  unfiscalised_transactions_report_incorrect: 'Tiene {{transaction_number}} transacciones no sincronizadas - los informes no reflejarán con exactitud la situación más reciente',
  right: 'Bien',
  left: 'Izquierda',
  top: 'Arriba',
  bottom: 'Abajo',
  refund_print: 'Reembolso',
  cannot_combine_positive_and_negative: 'No puede tener precios positivos y negativos en el mismo recibo',
  list_horizontal: 'Mostrar pedidos horizontalmente',
  live_orders: 'pedidos en vivo'
}

export default esLang
