export default function GroupAddons({ addons, classes }) {
  // console.log('🚀 ~ GroupAddons ~ addons:', addons)
  return (
    <ul className={classes.addonsContainer}>
      {Object.keys(addons).map((i, j) => {
        return (
          <li
            key={`${addons[i][0].name}_${j}_addon`}
            className={`${classes.color} ${classes.textBoldSmall} ${classes.addonContainer}`}>
            <p className={`${classes.textBoldSmall} ${classes.addonQuantity}`}>
              {addons[i][0].amount} x
            </p>{' '}
            {addons[i][0].name}
          </li>
        )
      })}
    </ul>
  )
}
