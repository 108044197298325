import { memo, useEffect } from 'react'
import { connect } from 'react-redux'

// import EmployeesDropdown from './EmployeesDropdown'
import CartUtils from '../../utils/CartUtils'
import { AppInstances } from '../../utils/CountrSdk'

import StatusButtons from '../Shared/StatusButtons'

import './../../styles/Tools.scss'

const mapStateToProps = state => {
  return {
    app: state.app,
    employees: state.store.employees,
    device: state.device.device,
    store: state.store.store
  }
}

const Tools = memo(props => {
  //SELECT EMPLOYEE
  // const [selectedEmployee, setEmployee] = useState({})
  // const [productsEmployee, setProductsEmployee] = useState({})

  useEffect(() => {
    console.log('dddd: ', props.item)
  }, [props])
  const updateTransactionByIndex = async status => {
    console.log('asddsaasd')
    const countr = await AppInstances.getCountrSdk()
    // const emp = props.employees.find(e => e._id === selectedEmployee) //SELECT EMPLOYEE
    const employee = {}

    //SELECT EMPLOYEE
    // if (emp) {
    //   employee._id = emp._id
    //   employee.name = emp.name
    // }

    CartUtils.computeNextStatus(
      props.item,
      employee,
      status,
      props.prevState,
      props.currentIndex
    )

    props.cart.device = props.device._id

    props.cart.status = countr.calculateStatus(props.cart.items)
    props.updateTransaction(props.cart, 'item', status)

    if (
      (props.store?.options?.printDevice ||
        localStorage.getItem('localDesktop')) &&
      status === 'ready'
    ) {
      const type = `${String(props.cart.__t).toLowerCase()}s`
      const getUniqueId = countr.retrieveCartEntryId(props.item.product, true)
      countr[type].print(props.cart._id, {
        device:
          localStorage.getItem('localDesktop') ||
          props.store?.options?.printDevice,
        status: status,
        item: getUniqueId
      })
    }
  }
  //SELECT EMPLOYEE
  // const handleProductsEmployee = useCallback(async () => {
  //   const countr = await AppInstances.getCountrSdk()
  //   const entryId = countr.retrieveCartEntryId(props.item.product, true)
  //   if (props.cart.items?.length) {
  //     const item = props.cart.items.find(
  //       i => countr.retrieveCartEntryId(i.product, true) === entryId
  //     )
  //     if (item) {
  //       const employee =
  //         item.status[0]?.employees?.[item.status[0].employees.length - 1] ||
  //         null
  //       setProductsEmployee(employee)
  //     }
  //   }
  // }, [props.cart.items, props.item.product])

  // useEffect(() => {
  //   handleProductsEmployee()
  // }, [handleProductsEmployee])

  return (
    <div className="tools">
      <div className="tools-header">
        <span>{props.item.product.name}</span>
      </div>

      <div
        className={`width_100 divider__${
          props.item.status.filter(item => item.amount != 0)[0].state
        } margin-bottom-10`}></div>
      <div className="btn-list">
        {/* {!!props.employees?.length && ( //SELECT EMPLOYEE
          <EmployeesDropdown
            employees
            setEmployee={setEmployee}
            cartId={props.cart._id}
            selectedEmployee={productsEmployee}
          />
        )} */}
        <div className="tool-status-grid">
          {<StatusButtons updateStatus={updateTransactionByIndex} />}
        </div>
      </div>
    </div>
  )
})

export default connect(mapStateToProps, null)(Tools)
