import { memo } from 'react'
import { connect, useSelector } from 'react-redux'
import { translate } from 'react-internationalization'

import CartUtils from '../../utils/CartUtils'
import { AppInstances } from '../../utils/CountrSdk'

import StatusButtons from '../Shared/StatusButtons'

import './../../styles/Tools.scss'

const mapStateToProps = state => {
  return {
    employees: state.store.employees,
    carts: state.carts.list,
    device: state.device.device,
    store: state.store.store,
    hide_completed_items: state.settings.hide_completed_items.value
  }
}

const GeneralTools = memo(props => {
  const { cart, updateTransaction } = props

  const settingsKitchenCategories =
    props.device?.settings?.web_settings?.kitchen_categories || []

  const hideCompletedSetting = useSelector(() => {
    return props.device.settings?.hide_completed_items?.value || false
  })

  const updateStatus = async status => {
    const employee = {}

    props.cart.items = props.cart.items.map(item => {
      CartUtils.moveGeneralCartStatus(
        item,
        status,
        employee,
        hideCompletedSetting,
        settingsKitchenCategories
      )

      return item
    })

    // Change the Device for the updated Cart
    props.cart.device = props.device._id
    props.cart.status = status

    updateTransaction(props.cart, 'general')

    if (
      (props.store?.options?.printDevice ||
        localStorage.getItem('localDesktop')) &&
      status === 'ready'
    ) {
      const countr = await AppInstances.getCountrSdk()
      const type = `${String(props.cart.__t).toLowerCase()}s`
      countr[type].print(props.cart._id, {
        device:
          localStorage.getItem('localDesktop') ||
          props.store?.options?.printDevice,
        status: status,
        item: 'all'
      })
    }
  }

  return (
    <div className="tools">
      <div className="padding-all-8 header-container">
        <div>
          <p className="receipt_number">
            #
            {String(cart.extras.deviceCartName).indexOf('KASSABON:') >= 0
              ? String(cart.extras.deviceCartName).slice(-3)
              : String(cart.receipt_number)}
          </p>
        </div>
        <div>
          <p>
            {`${CartUtils.getDateOptions(CartUtils.getPropertyTimeValue(cart), {
              day: 'numeric',
              month: 'numeric'
            })}
               ${CartUtils.getTimeOptions(
                 CartUtils.getPropertyTimeValue(cart),
                 { hour: '2-digit', minute: '2-digit' }
               )}`}
          </p>
          <p className={`status_container background__${cart.status}`}>
            {cart.status?.length ? translate(cart.status) : 'new'}
          </p>
        </div>
      </div>
      <div
        className={`width_100 divider__${cart.status} margin-bottom-10`}></div>
      <div className="btn-list">
        <div className="tool-status-grid">
          <StatusButtons updateStatus={updateStatus} />
        </div>
      </div>
    </div>
  )
})

export default connect(mapStateToProps, null)(GeneralTools)
